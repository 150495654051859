import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class PollService {

  constructor(private http: HttpClient) { }

  saveVote(data: { poll_id: string; voter: string; candidates: never[]; pollType: string; }) {
    return this.http.post(environment.apiEndpoint + 'votes', data);
  }
}
