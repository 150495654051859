import { HttpClient, HttpHeaders } from '@angular/common/http';  
import { Injectable } from '@angular/core';  
import { Router } from '@angular/router';  
import { BehaviorSubject, Observable, throwError, of } from 'rxjs';  
import { map, catchError, finalize, filter, take } from 'rxjs/operators';  
import { environment } from '../environments/environment';  
import { User } from './interfaces/user';  
  
@Injectable()  
export class AuthService {  
  public readonly TOKEN_REFRESH_MARGIN = 60; // seconds before expiration to refresh  
  private refreshTokenInProgress = false;  
  private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);  
  
  constructor(private http: HttpClient, private router: Router) {}  
  
  public refreshToken(): Observable<string> {  
    if (this.refreshTokenInProgress) {  
        return this.refreshTokenSubject.asObservable().pipe(  
            filter((token: string | null) => token != null),  
            take(1)  
        );  
    } else {  
        this.refreshTokenInProgress = true;  
        this.refreshTokenSubject.next(null);  
  
        const headers = new HttpHeaders({  
            'Content-Type': 'application/json'  
        });  
        const refreshToken = this.getSession()?.refreshToken;  
        const body = { 'refresh_token': refreshToken };  
  
        console.log('Refreshing token with body:', body);  
  
        return this.http.post<any>(`${environment.apiEndpoint}refresh`, body, { headers }).pipe(  
            map((response: any) => {  
                console.log('Refresh token response:', response);  
  
                if (response && response.access_token) {  
                    const currentUser = this.getSession();  
                    console.log('Current user:', currentUser);  
                    if (currentUser) {  
                        currentUser.token = response.access_token;  
                        if (response.refresh_token) {  // Atualiza o refresh token, caso seja fornecido um novo  
                            currentUser.refreshToken = response.refresh_token;  
                        }  
                        this.setSession(currentUser);  
                        this.refreshTokenSubject.next(response.access_token);  
                        return response.access_token;  
                    } else {  
                        throw new Error('No current user session found');  
                    }  
                } else {  
                    throw new Error('Access token not found in response');  
                }  
            }),  
            catchError(error => {  
                console.error('Error refreshing token:', error);  
                this.logout();  
                return throwError(error);  
            }),  
            finalize(() => {  
                this.refreshTokenInProgress = false;  
            })  
        );  
    }  
}  

  
  
  public setSession(user: User): void {  
    sessionStorage.setItem('currentUser', JSON.stringify(user));  
  }  
  
  public getSession(): User {  
    return JSON.parse(sessionStorage.getItem('currentUser'));  
  }  
  
  public getToken(): string {  
    const user = this.getSession();  
    if (user) {  
      if (user.token === 'dummy_access_token') {  
        console.warn('Using dummy access token for development/testing purposes.');  
      }  
      return user.token;  
    }  
    return null;  
  }  
  
  public getUserInfo(): User {  
    return this.getSession();  
  }  
  
  public isAuthenticated(): boolean {  
    return this.getSession() != null;  
  }  
  
  public login(code: string): Observable<boolean> {  
    const httpOptions = {  
        headers: new HttpHeaders({  
            'Content-Type': 'application/json'  
        })  
    };  
    const url = environment.apiEndpoint + 'token';  
    const body = { code: code };  
  
    return this.http.post<any>(url, body, httpOptions).pipe(  
        map((response: any) => {  
            console.log('Login response:', response);  
            if (response && response.access_token && response.refresh_token) {  
                const currentUser: User = {  
                    token: response.access_token,  
                    refreshToken: response.refresh_token,  
                    family_name: '',  
                    given_name: response.name,  
                    role: response.roles,  
                    guid: response.guid  
                };  
                this.setSession(currentUser);  
                return true;  
            } else {  
                return false;  
            }  
        }),  
        catchError(error => {  
            console.error('Login failed', error);  
            return throwError(error);  
        })  
    );  
  }  
  
  public redirectToAuthentication(): void {  
    this.http.get<any>(environment.apiEndpoint + 'token').subscribe(res => {  
      window.location.href = res.url;  
    });  
  }  
  
  public logout(): void {  
    sessionStorage.clear();  
    window.location.href = environment.sign_out_url;  
  }  
  
  public parseJwtExpirationTime(token: string): number {  
    try {  
      console.log('Parsing token:', token);  
  
      const parts = token.split('.');  
      if (parts.length !== 3) {  
        console.error('Invalid token format: expected 3 parts, got', parts.length);  
        throw new Error('Invalid token format');  
      }  
  
      const base64Url = parts[1];  
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');  
      console.log('Decoded payload:', atob(base64));  
      const jwtPayload = JSON.parse(atob(base64));  
  
      if (!jwtPayload.exp) {  
        console.error('Token expiration time not found in payload:', jwtPayload);  
        throw new Error('Token expiration time not found');  
      }  
  
      return jwtPayload.exp;  
    } catch (error) {  
      console.error('Failed to parse JWT expiration time:', error);  
      throw error;  
    }  
  }  
}  
