import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { DateUtil } from '../utils/date.util';
import { CodError } from '../utils/date.enum';
import { MsgError } from '../utils/date.enum';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import * as moment from 'moment';
import { AuthService } from '../auth.service';
import { User } from '../interfaces/user';
import { Router } from '@angular/router';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { AttachmentService } from '../services/attachment.service';
import * as _ from 'lodash';
import swal from 'sweetalert2';
import { trigger, state, style, animate, transition } from '@angular/animations';
import * as fileSaver from 'file-saver-es';
import { PollSaveType } from '../utils/poll-save-type.enum';

declare var $: any;

@Component({
  selector: 'app-edit-poll-generic',
  templateUrl: './edit-poll-generic.component.html',
  styleUrls: ['./edit-poll-generic.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(1000)),
    ]),
  ]
})

export class EditPollGenericComponent implements OnInit {

  //#region Variables
  begin_date_model = { year: 2017, month: 8, day: 8 };
  end_date_model = { year: 2017, month: 8, day: 8 };
  model = { year: 2017, month: 8, day: 8 };
  public poll_detail: any =
    {
      "name": "",
      "saveType": "",
      "id": "",
      "description": "",
      "created_by_pwc_guid": "",
      "subtitle": "",
      "title": "",
      "begin_date": "",
      "end_date": "",
      "begin_date_model_1": { year: 2017, month: 8, day: 8 },
      "end_date_model_1": { year: 2017, month: 8, day: 8 },
      "pollType": ""
    };
  minDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
  dayTemplate = { year: 2020, month: 8, day: 1 };
  public candidates: Object;
  selectedCandidates: any[] = [];
  selectedVoters: any[] = [];
  countSelectedVoters = 0;
  voters: any;
  pollTitle: string;
  pollType: string;
  choosePollType = true;
  editPollForm: FormGroup;
  reportForm: FormGroup;
  partnerForm: FormGroup;
  submitted = false;
  candidadeLos: any;
  todayDate = new Date();
  partner = false;
  tob = false;
  report = false;
  user: User;
  loader = false;
  error = false;
  success = false;
  requiredFields = false;
  dataInvalida = false;
  dataInvalidaEnd = false;
  startDate = '2020/01/01';
  filePath: any[] = [];
  fileName: any[] = [];
  filePathReport: string[] = [];
  filePathReportName: string[] = [];
  selectedFile: File;
  selectedPhoto: File;
  photoPath: any[] = [];
  null: any[] = [];
  link: any[]= [];
  fName: any[]= [];
  errors: any = {};
  loadingVoter = false;
  loadingAttachment = false;
  countVoters = 0;
  voterError = false;
  saving = false;
  startRequired = false;
  endRequired = false;
  startEarlierEnd = false;
  oneHour = false;
  msgErr: string;
  groupError = false;
  selectedGroupItems: any[] = [];
  selectedGroups: any[] = [];
  groupSettings = {};
  pollDataGroups: any;
  countSelectedGroups = 0;
  countShowVoterTable = 0;
  countShowCandidateTable = 0;
  selectedFiles: FileList | null = null;
  approver_message: string;
  approved: PollSaveType = PollSaveType.Approved;  
  //#endregion Variables

  constructor(private router: Router, private dataService: DataService, private dateUtil: DateUtil, private route: ActivatedRoute,
    private location: Location, private formBuilder: FormBuilder, private auth: AuthService, private calendar: NgbCalendar,
    private attachmentService: AttachmentService) {
  }

  // convenience getter for easy access to form fields
  get f() {
    if ((this.reportForm.controls.begin_date.value == 'Sat Jan 01 2000 00:00:00 GMT-0200 (Brasilia Summer Time)')
      || (this.reportForm.controls.begin_date.value == null)) {
      this.reportForm.controls['begin_date'].setValue(null);
      this.dataInvalida = true;
    } else {
      this.dataInvalida = false;
    }

    if ((this.reportForm.controls.end_date.value == 'Sat Jan 01 2000 00:00:00 GMT-0200 (Brasilia Summer Time)')
      || (this.reportForm.controls.end_date.value == null)) {
      this.reportForm.controls['end_date'].setValue(null);
      this.dataInvalidaEnd = true;
    } else {
      this.dataInvalidaEnd = false;
    }

    return this.reportForm.controls;
  }

  confirmSavedPoll() {
    this.loader = false;
    this.success = true;
    setTimeout(() => {
      this.router.navigate(['/admin']);
    }
      , 2000);
  }

  pollErrorMessage() {
    this.loader = false;
    this.error = true;
    setTimeout(() => {
      this.router.navigate(['/admin']);
    }
      , 2000);
  }

  //#region Action Buttons
  back() {
    window.history.back();
  }

  cancel() {
    swal.fire({
      title: 'Are you sure?',
      text: "All information will be lost.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dc6900',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, I am sure.'
    }).then((result: any) => {
      if (result.value) {
        this.router.navigate(['/admin']);
      }
    });

    // if (confirm('Do you really want to cancel the poll creation? All information will be lost.')) {
    //     setTimeout(() => {
    //         this.router.navigate(['/admin']);
    //     });
  }
  
  onSubmit(saveType: Event) {
    this.saving = true;
    this.selectedVoters = _.map(this.voters, 'pwc_guid');
    let saveTypeId: number = Number((event.target as Element).id);

    this.reportForm.controls['email'].clearValidators();
    this.reportForm.controls['email'].updateValueAndValidity();

    this.reportForm.controls['selectedGroups'].clearValidators();
    this.reportForm.controls['selectedGroups'].updateValueAndValidity();

    if (saveTypeId === PollSaveType.Draft)
      this.clearValidators();
    else
    {
      if(!this.validateDates()) return;
      if(!this.validateVoters()) return;
      if(!this.validateGroups()) return;
      if(saveTypeId === PollSaveType.Approved) this.approver_message = PollSaveType[this.approved];      
    }

    this.reportForm.controls['saveType'].setValue(saveTypeId);
    this.reportForm.controls['selectedVoters'].setValue(this.selectedVoters);
    this.reportForm.controls['pollType'].setValue(this.poll_detail.pollType);
    this.reportForm.controls['id'].setValue(this.poll_detail.id);
    this.reportForm.value.file = this.filePathReport;
    this.reportForm.controls['files'].setValue(this.filePathReport);
    this.reportForm.controls['current_user'].setValue(this.poll_detail.created_by_pwc_guid);
    this.reportForm.controls['selectedGroups'].setValue(this.selectedGroups);
    this.submitted = true;
    this.reportForm.controls['approver_message'].setValue(this.approver_message);

    // stop here if form is invalid
    if (this.reportForm.invalid) {
      // this.submitted = false;
      // this.requiredFields = true;
      window.scroll(0, 0);
      this.saving = false;
      return false;
    }

    if (this.reportForm.value.begin_date != '' && this.reportForm.value.begin_date != undefined) {
      this.reportForm.value.begin_date = this.dateUtil.transformToDate(this.reportForm.value.begin_date, this.reportForm.value.begin_time)

      if (this.reportForm.value.begin_date == 'Invalid Date') {
        this.reportForm.value.begin_date = null;
      } else {
        this.reportForm.value.begin_date.toUTCString();
        this.reportForm.controls['begin_date'].setValue(this.reportForm.value.begin_date);
      }
    } else {
      this.reportForm.value.end_date = null;
      this.reportForm.controls['begin_date'].setValue(null);
    }

    if (this.reportForm.value.end_date != '' && this.reportForm.value.end_date != undefined) {
      this.reportForm.value.end_date = this.dateUtil.transformToDate(this.reportForm.value.end_date, this.reportForm.value.end_time)
      
      if (this.reportForm.value.end_date == 'Invalid Date') {
        this.reportForm.value.end_date = null;
      } else {
        this.reportForm.value.end_date.toUTCString();
        this.reportForm.controls['end_date'].setValue(this.reportForm.value.end_date);
      }
    } else {
      this.reportForm.value.end_date = null;
      this.reportForm.controls['end_date'].setValue(null);
    }

    this.loader = true;
    this.dataService.updatePoll(this.reportForm.value)
      .pipe(first())
      .subscribe(
        data => swal.fire({ title: 'Success!', text: 'The poll has been saved.', icon: 'success', confirmButtonColor: '#dc6900' })
          .then(() => {
            this.router.navigate(['/admin']);
          }),
        error => swal.fire({
          title: 'Oops...',
          html: '<p>We are sorry, an error has occurred. Please contact the system administrators.</p>' +
            '<br><div class="alert alert-danger" role="alert"><p> Error: </p><br>' + error.error.message + '<br><br>'
            + error.message + '</div>',
          icon: 'error', confirmButtonColor: '#dc6900'
        })
          .then(() => {
            this.router.navigate(['/admin']);
          }));
  }
  //#endregion Action Buttons


  //#region Attachments
  getDownload(fileName: any[], link: any[]) {
    this.loader = true
    for(let i = 0; i< fileName.length;i++){
      this.loader = true
      this.dataService.getDownload(fileName[i], link[i]).subscribe((data: any) => {
        let blob:any = new Blob([data], { type: 'text/json; charset=utf-8' });
        const url = window.URL.createObjectURL(blob);
        fileSaver.saveAs(blob, fileName[i])
        if(i == fileName.length - 1) this.loader = false
        },
        error => {
          console.log("erro", error.toString());
          this.loader = false
        });
    }
  }

  onFileChanged(event: any) {
    this.selectedFiles = event.target.files;
  }

  onUploadReport() {
    if(this.selectedFiles && this.selectedFiles.length > 0){
      const uploadPromises = [];
      for(let i = 0; i < this.selectedFiles.length; i++){
        const file = this.selectedFiles[i];
        const uploadData = new FormData();
        uploadData.append('myFile', file, file.name);
        uploadData.append('fileType', 'BUSINESSCASE')
        
        uploadPromises.push(
          this.attachmentService.sendAttachment(uploadData).toPromise()
            .then((data: any) => {
              this.filePathReport.push(data.toString());
              this.filePathReportName.push(file.name);
            })
            .catch((error: any) => {              
                swal.fire({
                  title: 'Oops...',
                  html: '<p>We are sorry, an error has occurred. Please contact the system administrators.</p>' +
                    '<br><div class="alert alert-danger" role="alert"><p> Error: </p><br>' + error.error.message + '<br><br>'
                    + error.message + '</div>',
                  icon: 'error', 
                  confirmButtonColor: '#dc6900'
                });
            })
        );
      }
      
      this.loader = true;
      Promise.all(uploadPromises).then(() => {
        this.selectedFiles = null;
        this.loader = false;
      });

    }
  }
  //#endregion Attachments


  //#region Clear Fields
  clearValidators(){
    this.reportForm.controls['name'].clearValidators();
    this.reportForm.controls['name'].updateValueAndValidity();

    this.reportForm.controls['description'].clearValidators();
    this.reportForm.controls['description'].updateValueAndValidity();

    this.reportForm.controls['begin_date'].clearValidators();
    this.reportForm.controls['begin_date'].updateValueAndValidity();

    this.reportForm.controls['begin_time'].clearValidators();
    this.reportForm.controls['begin_time'].updateValueAndValidity();

    this.reportForm.controls['end_date'].clearValidators();
    this.reportForm.controls['end_date'].updateValueAndValidity();

    this.reportForm.controls['end_time'].clearValidators();
    this.reportForm.controls['end_time'].updateValueAndValidity();

    this.reportForm.controls['selectedVoters'].clearValidators();
    this.reportForm.controls['selectedVoters'].updateValueAndValidity();
  }
  //#endregion Clear Fields


  //#region Groups
  onGroupDeSelectAll(items: any) {
    this.countSelectedGroups = 0;
  }

  onGroupItemDeSelect(item: any) {
    this.countSelectedGroups--;
  }

  onGroupItemSelect(item: any) {
    this.countSelectedGroups++;
    this.groupError = false;
  }

  onGroupSelectAll(items: any) {
    this.countSelectedGroups = items.length;
    this.groupError = false;
  }

  selectGroup() {
    this.dataService.getGroups().subscribe(data => {
      this.pollDataGroups = data;
    });

    this.groupSettings = {
      singleSelection: false,
      itemsShowLimit: 5,
      enableSearchFilter: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      classes: "myclass custom-class-example",
      idField: 'id',
      textField: 'groupname',
      labelKey: 'groupname',
    };
  }  
  //#endregion Groups


  //#region Load
  getDetail() {
    const id = this.route.snapshot.paramMap.get('id');
    this.dataService.getPoll(id).subscribe(data => {
      this.poll_detail = data;
      this.poll_detail.begin_time = moment(this.poll_detail.begin_date).format('HH');
      if (this.poll_detail.begin_time == 'Invalid date')
        this.poll_detail.begin_time = '03';      

      this.poll_detail.end_time = moment(this.poll_detail.end_date).format('HH');
      if (this.poll_detail.end_time == 'Invalid date')
        this.poll_detail.end_time = '03';      

      this.poll_detail.begin_date = moment(this.poll_detail.begin_date).format('YYYY-MM-DD');
      this.poll_detail.end_date = moment(this.poll_detail.end_date).format('YYYY-MM-DD');      
            
      for(let i = 0; i< this.poll_detail.attachments.length;i++){
        this.link[i] = this.poll_detail.attachments[i].link
        this.fName[i] = this.poll_detail.attachments[i].name;
        console.log(this.fName[i])
      }   
      
      for(let attachment of this.poll_detail.attachments)
        this.filePathReportName.push(attachment['name'])      

      this.countVoters = this.poll_detail.voters.length;
      this.voters = _.map(this.poll_detail.voters, function (item) {
        return {
          id: item.id, name: item.name, email: item.email, active: item.active, created_at: item.created_at,
          password: item.password, profile: item.profile,
          pwc_guid: item.guid, pwc_number: item.pwc_number, updated_at: item.updated_at
        };
      });

      $('#tabelaVoters').DataTable().clear().draw();
      $.each(this.voters,
        function (key: any, item: any) {
          $("#tabelaVoters").DataTable().row.add([
            item.name
          ]).draw();
        });

      this.countShowVoterTable++;
      this.reportForm.controls['name'].setValue(this.poll_detail.name);
      this.reportForm.controls['description'].setValue(this.poll_detail.description);
      
      if (this.poll_detail.begin_date != null && this.poll_detail.begin_date != 'Invalid date')
        this.reportForm.controls['begin_date'].setValue(this.dateUtil.convertDateTime(this.poll_detail.begin_date.concat("T", this.poll_detail.begin_time, ":00:00"), false));
      else
        this.reportForm.controls['begin_date'].setValue(null);      

      if (this.poll_detail.end_date != null && this.poll_detail.end_date != 'Invalid date')
        this.reportForm.controls['end_date'].setValue(this.dateUtil.convertDateTime(this.poll_detail.end_date.concat("T", this.poll_detail.end_time, ":00:00"), false));
      else
        this.reportForm.controls['end_date'].setValue(null);
      
      this.reportForm.controls['selectedVoters'].setValue(this.poll_detail.voters);
      this.reportForm.controls['pollType'].setValue(this.poll_detail.pollType);
      this.reportForm.controls['email'].setValue(this.poll_detail.email);
      this.reportForm.controls['begin_time'].setValue(this.poll_detail.begin_date == 'Invalid date'? 0 :
                                                      this.dateUtil.convertDateTime(this.poll_detail.begin_date.concat("T", this.poll_detail.begin_time, ":00:00"), true));
      this.reportForm.controls['end_time'].setValue(this.poll_detail.end_date == 'Invalid date'? 0 :
                                                    this.dateUtil.convertDateTime(this.poll_detail.end_date.concat("T", this.poll_detail.end_time, ":00:00"), true));
    });
  }
  //#endregion Load


  //#region OnInit
  public ngOnInit() {
    this.user = this.auth.getUserInfo();
    this.getDetail();
    this.validateForm();
    this.selectGroup();

    //MULTISELECT
    $(document).ready(() => {
      // make code pretty
      // hack for iPhone 7.0.3 multiselects bug
      if (navigator.userAgent.match(/iPhone/i)) {
        $('select[multiple]').each(() => {
          var select = $(this).on({
            "focusout": function () {
              var values = select.val() || [];
              setTimeout(function () {
                select.val(values.length ? values : ['']).change();
              }, 1000);
            }
          });
          var firstOption = '<option value="" disabled="disabled"';
          firstOption += (select.val() || []).length > 0 ? '' : ' selected="selected"';
          firstOption += '>Select ' + (select.attr('title') || 'Options') + '';
          firstOption += '</option>';
          select.prepend(firstOption);
        });
      }

      // DUALIST REPORT
      $('#multiselectReport').multiselect({
      });

      $('.countSelectedVotersReport').html(0 + ' selected');
      $('#multiselectReport_rightAll').on('click', (e: any, opt: any) => {
        this.countVoters++;
        this.countVoters = $('#multiselectReport_to option').length;
        $('.countSelectedVotersReport').html(this.countVoters + ' selected');
      });

      $('#multiselectReport_rightSelected').on('click', (e: any, opt: any) => {
        this.countVoters++;
        this.countVoters = $('#multiselectReport_to option').length;
        $('.countSelectedVotersReport').html(this.countVoters + ' selected');
      });

      $('#multiselectReport_leftSelected').on('click', (e: any, opt: any) => {
        this.countVoters--;
        this.countVoters = $('#multiselectReport_to option').length;
        $('.countSelectedVotersReport').html(this.countVoters + ' selected');
      });

      $('#multiselectReport_leftAll').on('click', (e: any, opt: any) => {
        this.countVoters--;
        this.countVoters = $('#multiselectReport_to option').length;
        $('.countSelectedVotersReport').html(this.countVoters + ' selected');
      });
    });
  }
  //#endregion OnInit


  //#region Validations
  validateDates(){
    this.msgErr = '';
    this.startRequired = false;
    this.endRequired = false;
    this.startEarlierEnd = false;
    this.oneHour = false;
    let model = {
      beginDate: this.reportForm.value.begin_date, endDate: this.reportForm.value.end_date
      , beginTime: this.reportForm.value.begin_time, endTime: this.reportForm.value.end_time, codError: 0, msgError: ""
    };
  
    if (!this.dateUtil.validateDateTime(model)) {
      this.startRequired = model.codError == CodError.StartRequired ? true : false;
      this.endRequired = model.codError == CodError.EndRequired ? true : false;
      this.startEarlierEnd = model.codError == CodError.StartEarlierEnd ? true : false;
      this.oneHour = model.codError == CodError.OneHour ? true : false;
      if (this.startRequired) {
        this.msgErr = MsgError.StartRequired;
      }
      else if (this.endRequired) {
        this.msgErr = MsgError.EndRequired;
      }
      else if (this.startEarlierEnd) {
        this.msgErr = MsgError.StartEarlierEnd;
      }
      else if (this.oneHour) {
        this.msgErr = MsgError.OneHour;
      }

      this.saving = false;
      return false;
    }

    return true;
  }
  
  validateForm(){
    this.reportForm = this.formBuilder.group({
      id: [],
      name: ['', Validators.required],
      description: ['', Validators.required],
      begin_date: ['', Validators.required],
      end_date: ['', Validators.required],
      begin_time: ['0', Validators.required],
      end_time: ['0', Validators.required],
      email: [''],
      pollType: ['', Validators.required],
      saveType: ['', Validators.required],
      selectedVoters: ['', Validators.required],
      selectedGroups: ['', Validators.required],
      files: [''],
      current_user: [''],
      approver_message: ['']
    });
  }

  validateGroups(){
    if (this.selectedGroups == undefined) {
      this.groupError = true;
      this.saving = false;
      return false;
    }

    return true;
  }

  validateVoters(){
    if (this.voters.length <= 0) {
      this.voterError = true;
      this.saving = false;
      return false;
    }

    return true;
  }
  //#endregion Validations


  //#region Voters
  loadVoters() {
    if (this.countSelectedGroups == 0) {
      this.groupError = true;
      return
    }
    this.loadingVoter = true;
    this.dataService.getPartnersGroup(this.selectedGroupItems).subscribe(data => {
      this.loadingVoter = false;
      this.voters = data;
      this.voterError = false;
      this.countVoters = Object.keys(this.voters).length;

      $('#tabelaVoters').DataTable().clear().draw();

      $.each(data,
        function (key: any, item: any) {
          $("#tabelaVoters").DataTable().row.add([
            item.name
          ]).draw();
        });
      this.countShowVoterTable++;

    });
  }
  //#endregion Voters
}
