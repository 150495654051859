import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import * as moment from 'moment';
import { AuthService } from '../auth.service';
import { User } from '../interfaces/user';
import { Router } from '@angular/router';
import { NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { AttachmentService } from '../services/attachment.service';
import * as _ from 'lodash';
//import { debug } from 'util';
import swal from 'sweetalert2';
declare var $: any;


@Component({
    selector: 'app-edit-poll-partner',
    templateUrl: './edit-poll-partner.component.html',
    styleUrls: ['./edit-poll-partner.component.scss']
})


export class EditPollPartnerComponent implements OnInit {

  begin_date_model = {year: 2017, month: 8, day: 8};
  end_date_model = {year: 2017, month: 8, day: 8};
  model = {year: 2017, month: 8, day: 8};

  public poll_detail: any =
    {
      "name": "",
      "saveType": "",
      "id": "",
      "description": "",
      "created_by": "",
      "subtitle": "",
      "title": "",
      "created_date": "",
      "begin_date": {year: 2017, month: 8, day: 8},
      "end_date": {year: 2017, month: 8, day: 8},
      "pollType": ""
    };

    minDate = {year: new Date().getFullYear() , month: new Date().getMonth()+1, day: new Date().getDate()};

    dayTemplate = {year: 2020 , month: 8, day: 1};

  public candidates!: Object;

  selectedCandidates: any[] = [];
  selectedVoters: any[] = [];
  countSelectedVoters = 0;
  voters: any;
  pollTitle!: string;
  pollType!: string;
  choosePollType = true;
  editPollForm!: FormGroup;
  reportForm!: FormGroup;
  partnerForm!: FormGroup;
  tobForm!: FormGroup;
  submitted = false;
  candidadeLos: any;
  todayDate = new Date();
  partner = false;
  tob = false;
  report = false;
  user!: User;
  loader = false;
  error = false;
  success = false;
  requiredFields = false;
  dataInvalida = false;
  dataInvalidaEnd = false;
  startDate = '2020/01/01';
  // model: NgbDateStruct;
  filePath: any[] = [];
  fileName: any[] = [];
  filePathReport!: string;
  filePathReportName!: string;
  selectedFile!: File;
  selectedPhoto!: File;
  photoPath: any[] = [];
  null: any[] = [];
  countVoters!: number;

  constructor(private router: Router, private dataService: DataService,  private route: ActivatedRoute,
    private location: Location, private formBuilder: FormBuilder, private auth: AuthService, private calendar: NgbCalendar,
    private attachmentService: AttachmentService) {
  }

     // convenience getter for easy access to form fields
  get f() {

        if ((this.partnerForm.controls.begin_date.value == 'Sat Jan 01 2000 00:00:00 GMT-0200 (Brasilia Summer Time)')
              || (this.partnerForm.controls.begin_date.value == null)) {
                  this.partnerForm.controls['begin_date'].setValue(null);
                  this.dataInvalida = true;
              } else {
                  this.dataInvalida = false;
              }

      if ((this.partnerForm.controls.end_date.value == 'Sat Jan 01 2000 00:00:00 GMT-0200 (Brasilia Summer Time)')
          || (this.partnerForm.controls.end_date.value == null)) {
                  this.partnerForm.controls['end_date'].setValue(null);
                  this.dataInvalidaEnd = true;
              } else {
                  this.dataInvalidaEnd = false;
              }
      if (!this.validateDate()){
        this.dataInvalidaEnd = true;
      }
      this.partnerForm.controls.candidates =  this.poll_detail.candidates;
      return this.partnerForm.controls;
  }

  validateDate() {
    if (this.partnerForm.controls["begin_date"].value != null ||
        this.partnerForm.controls["end_date"].value != null){
      var data_1 = this.partnerForm.controls["begin_date"].value.day;
      var data_2 = this.partnerForm.controls["end_date"].value.day;
      return (data_2 > data_1 + 1)
    } else {
      return false
    }
  }

  getDetail() {
  const id = this.route.snapshot.paramMap.get('id');
  this.dataService.getPoll(id).subscribe(data => {
    this.poll_detail = data;
    this.poll_detail.begin_time = moment(this.poll_detail.begin_date).format('HH');
    this.poll_detail.end_time = moment(this.poll_detail.end_date).format('HH');
    this.poll_detail.begin_date = moment(this.poll_detail.begin_date).format('DD/MM/YYYY');
    this.poll_detail.end_date = moment(this.poll_detail.end_date).format('DD/MM/YYYY');
    this.voters = _.differenceBy(this.voters, this.poll_detail.voters, 'name');
    if (this.poll_detail.begin_date != null){
      this.partnerForm.controls['begin_date'].setValue(
        {
          year: parseInt(this.poll_detail.begin_date.split('/')[2]),
          month: parseInt(this.poll_detail.begin_date.split('/')[1]),
          day: parseInt(this.poll_detail.begin_date.split('/')[0]),
        }
      );
    }

    if (this.poll_detail.end_date != null){
      this.partnerForm.controls['end_date'].setValue(
        {
          year: parseInt(this.poll_detail.end_date.split('/')[2]),
          month: parseInt(this.poll_detail.end_date.split('/')[1]),
          day: parseInt(this.poll_detail.end_date.split('/')[0]),
        }
      );
    }
    this.partnerForm.controls['name'].setValue(this.poll_detail.name);
    this.partnerForm.controls['description'].setValue(this.poll_detail.description);
    this.partnerForm.controls['selectedVoters'].setValue(this.poll_detail.voters);
    this.partnerForm.controls['pollType'].setValue(this.poll_detail.pollType);
    this.partnerForm.controls['email'].setValue(this.poll_detail.email);
    this.partnerForm.controls['begin_time'].setValue(this.poll_detail.begin_time - 3 );
    this.partnerForm.controls['end_time'].setValue(this.poll_detail.end_time - 3 );

    for (let i of Object.keys(this.poll_detail.candidates)) {
        if (this.poll_detail.candidates.attachments != undefined) {
          if (this.poll_detail.candidates[i].attachments[0].link == null) {
              this.photoPath[Number(i)] = '../../assets/img/user-default.png'
          }
          else {
          this.photoPath[Number(i)] = this.poll_detail.candidates[i].attachments[0].link;
          this.filePath[Number(i)] = this.poll_detail.candidates[i].attachments[1].link;
          }
        } else {
          this.photoPath[Number(i)] = '../../assets/img/user-default.png'
        }
      }
      // for ( let i of Object.keys(this.poll_detail.candidates)) {
      //     // this.partnerForm.controls.candidates.setValue(this.poll_detail.candidates[i].candidatename);
      //     this.partnerForm.controls.candidates.value[i].candidatename = this.poll_detail.candidates[i].candidatename;
      //     this.partnerForm.controls.candidates.value[i].candidatedesc = this.poll_detail.candidates[i].candidatedesc;
      //     this.partnerForm.controls.candidates.value[i].candidadeLos = this.poll_detail.candidates[i].candidadeLos;
      //     this.partnerForm.controls.candidates.value[i].photo = this.poll_detail.candidates[i].attachments[0].link;
      //     this.partnerForm.controls.candidates.value[i].businesscase = this.poll_detail.candidates[i].attachments[1].link;
      // }
      // this.initCandidates();
      // const control = <FormArray>this.partnerForm.controls['candidates'];
    });
  }

  initCandidates() {
    return this.formBuilder.group({
        candidatename: [''],
        candidatedesc: [''],
        candidateLos: [''],
        businesscase: [''],
        photo: ['']
    });
  }

  confirmSavedPoll() {
    this.loader = false;
    this.success = true;
    setTimeout(() => {
      this.router.navigate(['/admin']);
    }
      , 2000);
  }

  pollErrorMessage() {
    this.loader = false;
      this.error = true;
      setTimeout(() => {
        this.router.navigate(['/admin']);
      }
      , 2000);
  }

  onSubmit(saveType: Event) {
    var a: any[] = [];
      $('#multiselect_to option')
      .map( () => { a.push($(this).val().replace(/[0-9]*:/g, "").replace(/'/g, "").trim()); });
    this.selectedVoters = a;
    let elementId: string = (event.target as Element).id;
    
    if (elementId === '0') {
      this.partnerForm.controls['name'].clearValidators();
      this.partnerForm.controls['name'].updateValueAndValidity();

      this.partnerForm.controls['description'].clearValidators();
      this.partnerForm.controls['description'].updateValueAndValidity();

      this.partnerForm.controls['begin_date'].clearValidators();
      this.partnerForm.controls['begin_date'].updateValueAndValidity();

      this.partnerForm.controls['begin_time'].clearValidators();
      this.partnerForm.controls['begin_time'].updateValueAndValidity();

      this.partnerForm.controls['end_date'].clearValidators();
      this.partnerForm.controls['end_date'].updateValueAndValidity();

      this.partnerForm.controls['end_time'].clearValidators();
      this.partnerForm.controls['end_time'].updateValueAndValidity();

      this.partnerForm.controls['selectedVoters'].clearValidators();
      this.partnerForm.controls['selectedVoters'].updateValueAndValidity();

        // this.partnerForm.controls['candidates'].clearValidators();
        // this.partnerForm.controls['candidates'].updateValueAndValidity();
    }

    this.partnerForm.controls['saveType'].setValue(elementId);
    this.partnerForm.controls['selectedVoters'].setValue(this.selectedVoters);
    this.partnerForm.controls['pollType'].setValue(this.poll_detail.pollType);
    this.partnerForm.controls['id'].setValue(this.poll_detail.id);
    if (this.partnerForm.controls["begin_date"].value != null) {
      let day_begin = this.partnerForm.value.begin_date.day;
      let month_begin = this.partnerForm.value.begin_date.month;
      let year_begin = this.partnerForm.value.begin_date.year;
      this.partnerForm.value.begin_date =  month_begin + '/' + day_begin + '/' + year_begin;
      this.partnerForm.value.begin_time = this.partnerForm.value.begin_time + ':00';
      this.partnerForm.value.begin_date = this.partnerForm.value.begin_date + ' ' + this.partnerForm.value.begin_time;

      this.partnerForm.value.begin_date = new Date(this.partnerForm.value.begin_date);
      if (this.partnerForm.value.begin_date == 'Invalid Date') {
        this.partnerForm.value.begin_date = '';
      } else {
        this.partnerForm.value.begin_date.toUTCString();
        this.partnerForm.controls['begin_date'].setValue(this.partnerForm.value.begin_date);
      }
    }

    if (this.partnerForm.controls["end_date"].value != null) {
      let day_end = this.partnerForm.value.end_date.day;
      let month_end = this.partnerForm.value.end_date.month;
      let year_end = this.partnerForm.value.end_date.year;
      this.partnerForm.value.end_date =  month_end + '/' + day_end + '/' + year_end;
      this.partnerForm.value.end_time = this.partnerForm.value.end_time + ':00';
      this.partnerForm.value.end_date = this.partnerForm.value.end_date + ' ' + this.partnerForm.value.end_time;

      this.partnerForm.value.end_date = new Date(this.partnerForm.value.end_date);
      if (this.partnerForm.value.end_date == 'Invalid Date') {
        this.partnerForm.value.end_date = '';
      } else {
      this.partnerForm.value.end_date.toUTCString();
      this.partnerForm.controls['end_date'].setValue(this.partnerForm.value.end_date);
      }
    }
    
    this.validateDate();

    this.submitted = true;
    // stop here if form is invalid
    if (this.partnerForm.invalid) {
      alert(this.partnerForm.controls['begin_date'].value)
      alert(this.partnerForm.controls['end_date'].value)
      this.requiredFields = true;
      window.scroll(0, 0);
      return;
    }

    this.loader = true;
    this.dataService.createPoll(this.partnerForm.value)
      .pipe(first())
      .subscribe(
          data =>  swal.fire({title: 'Success!', text: 'The poll has been saved.', icon: 'success', confirmButtonColor: '#dc6900'})
          .then(() => {
              this.router.navigate(['/admin']);
          }),
          error => swal.fire({title: 'Oops...',
          html: '<p>We are sorry, an error has occurred. Please contact the system administrators.</p>' +
              '<br><div class="alert alert-danger" role="alert"><p> Error: </p><br>' + error.error.message + '<br><br>'
               + error.message + '</div>',
          icon: 'error', confirmButtonColor: '#dc6900'})
          .then(() => {
              this.router.navigate(['/admin']);
          })
      );
  }

  public ngOnInit() {
    this.user = this.auth.getUserInfo();

    this.dataService.getPartners()
    .pipe(first())
    .subscribe(
      data => {
          this.voters = data;
      },
      error => {
          swal.fire({title: 'Oops...',
          html: '<p>We are sorry, an error has occurred. Please contact the system administrators.</p>' +
              '<br><div class="alert alert-danger" role="alert"><p> Error: </p><br>' + error.error.message + '<br><br>'
               + error.message + '</div>',
          icon: 'error', confirmButtonColor: '#dc6900'})
          .then(() => {
              // this.router.navigate(['/admin']);
          });
      }
    );

    this.getDetail();

    this.partnerForm = this.formBuilder.group({
      id: [],
      name: ['', Validators.required],
      description: ['', Validators.required],
      begin_date: ['', Validators.required],
      end_date: ['', Validators.required],
      begin_time: ['', Validators.required],
      end_time: ['', Validators.required],
      email: [''],
      pollType: ['', Validators.required],
      saveType: ['', Validators.required],
      candidates: this.formBuilder.array([
        this.initCandidates()
      ]),
      selectedVoters: ['', Validators.required]
      // password: ['', [Validators.required, Validators.minLength(6)]]
    });

     //MULTISELECT
    $(document).ready( () => {
        // make code pretty

        // hack for iPhone 7.0.3 multiselects bug
      if (navigator.userAgent.match(/iPhone/i)) {
        $('select[multiple]').each( () => {
          var select = $(this).on({
            "focusout": function () {
              var values = select.val() || [];
              setTimeout(function () {
                select.val(values.length ? values : ['']).change();
              }, 1000);
            }
          });
          var firstOption = '<option value="" disabled="disabled"';
          firstOption += (select.val() || []).length > 0 ? '' : ' selected="selected"';
          firstOption += '>Select ' + (select.attr('title') || 'Options') + '';
          firstOption += '</option>';
          select.prepend(firstOption);
        });
      }

      // MULTISELECT AP/PARTNER
      $('#multiselect').multiselect({
      });

      $('.countSelectedVoters').html(0 + ' selected');
      $('#multiselect_rightAll').on('click', (e: any, opt: any) => {
          this.countVoters++;
        this.countVoters = $('#multiselect_to option').length;
        $('.countSelectedVoters').html(this.countVoters + ' selected');
      });
      $('#multiselect_rightSelected').on('click', (e: any, opt: any) => {
          this.countVoters++;
        this.countVoters = $('#multiselect_to option').length;
        $('.countSelectedVoters').html(this.countVoters + ' selected');
      });
      $('#multiselect_leftSelected').on('click', (e: any, opt: any) => {
          this.countVoters--;
        this.countVoters = $('#multiselect_to option').length;
        $('.countSelectedVoters').html(this.countVoters + ' selected');
      });
      $('#multiselect_leftAll').on('click', (e: any, opt: any) => {
        this.countVoters--;
        this.countVoters = $('#multiselect_to option').length;
        $('.countSelectedVoters').html(this.countVoters + ' selected');
      });

    });
  }

  onPhotoChanged(event: { target: { files: File[]; }; }) {
    this.selectedPhoto = event.target.files[0];
    // console.log(this.selectedPhoto);
  }

  onPhotoUpload(i: string | number) {
    const uploadData = new FormData();
    uploadData.append('myFile', this.selectedPhoto, this.selectedPhoto.name);
    uploadData.append('fileType', 'PHOTO')
    this.loader = true;
    this.attachmentService.sendAttachment(uploadData)
      .subscribe(
        data => {
          this.photoPath[Number(i)] = data.toString();
          this.selectedPhoto = null as any;
        },
        error => {
                swal.fire({title: 'Oops...',
                html: '<p>We are sorry, an error has occurred. Please contact the system administrators.</p>' +
            '<br><div class="alert alert-danger" role="alert"><p> Error: </p><br>' + error.error.message + '<br><br>'
             + error.message + '</div>',
                icon: 'error', confirmButtonColor: '#dc6900'})
                .then(() => {
                    // this.router.navigate(['/admin']);
            });
        });
        this.loader = false;
  }

  onFileChanged(event: { target: { files: File[]; }; }) {
    this.selectedFile = event.target.files[0];
    // console.log(this.selectedFile);
  }

  cancel() {
    swal.fire({
          title: 'Are you sure?',
          text: "All information will be lost.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#dc6900',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, I am sure.'
        }).then((result: any) => {
        if (result.value) {
          this.router.navigate(['/admin']);
        }
    });

    // if (confirm('Do you really want to cancel the poll creation? All information will be lost.')) {
    //     setTimeout(() => {
    //         this.router.navigate(['/admin']);
    //     });
  }

  onUpload(i: string | number) {
    const uploadData = new FormData();
    uploadData.append('myFile', this.selectedFile, this.selectedFile.name);
    uploadData.append('fileType', 'BUSINESSCASE')
    this.loader = true;
    this.attachmentService.sendAttachment(uploadData)
      .subscribe(
        data => {
          this.filePath[Number(i)] = data.toString();
          this.fileName[Number(i)] = this.selectedFile.name;
          this.selectedFile = null as any;
        },
        error => {
                swal.fire({title: 'Oops...',
                html: '<p>We are sorry, an error has occurred. Please contact the system administrators.</p>' +
            '<br><div class="alert alert-danger" role="alert"><p> Error: </p><br>' + error.error.message + '<br><br>'
             + error.message + '</div>',
                icon: 'error', confirmButtonColor: '#dc6900'})
                .then(() => {
                    // this.router.navigate(['/admin']);
            });
        });
        this.loader = false;
  }

  onUploadReport() {
    const uploadData = new FormData();
    uploadData.append('myFile', this.selectedFile, this.selectedFile.name);
    uploadData.append('fileType', 'BUSINESSCASE')
    this.loader = true;
    this.attachmentService.sendAttachment(uploadData)
      .subscribe(
        data => {
        //   console.log("sucesso", data.toString());
          this.filePathReport = data.toString();
          this.filePathReportName = this.selectedFile.name;
        },
        error => {
               swal.fire({title: 'Oops...',
                html: '<p>We are sorry, an error has occurred. Please contact the system administrators.</p>' +
            '<br><div class="alert alert-danger" role="alert"><p> Error: </p><br>' + error.error.message + '<br><br>'
             + error.message + '</div>',
                icon: 'error', confirmButtonColor: '#dc6900'})
                .then(() => {
                    // this.router.navigate(['/admin']);
            });
        });
        this.loader = false;
  }

  addCandidate() {
      this.poll_detail.candidates.push(this.initCandidates());
    }

  removeCandidate(i: number) {
    const control = <FormArray>this.partnerForm.controls['candidates'];
    this.poll_detail.candidates.splice(i, 1);
    this.photoPath.splice(i, 1);
  }

  back() {
    window.history.back();
  }
}
