import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'PollstatusPipe'
})
export class PollstatusPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value === 0) {
      return 'Draft';
    } else if (value === 1) {
      return 'Pending Approval';
    } else if (value === 2) {
      return 'Approved';
    } else if (value === 3) {
      return 'Action Required';
    } else if (value === 4) {
      return 'Active';
    } else if (value === 5) {
      return 'Closed';
    } else if (value === 6) {
      return 'Accounted';
    } else {
        return 'Cancelled';
    }
  }

}
