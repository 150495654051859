import { Injectable } from '@angular/core';
import { CodError } from './date.enum';
import { MsgError } from './date.enum';
import * as moment from 'moment';

@Injectable()
export class DateUtil {
  
  convertDateTime(dateBD: any, time: boolean){
    var hour, date, dataRtn;
    if(dateBD.toString() == '')
          return 0;
    else{
      date = this.dateToCalendar(dateBD)
      hour = Number(parseInt(moment(dateBD.toString()).format('HH')))      
      dataRtn = new Date(date.year, date.month - 1, date.day, hour - 3);
      return time == true ? dataRtn.getHours() : this.dateToCalendar(moment(dataRtn).format("YYYY-MM-DD"))
    }    
  }

  dateToCalendar(date: any) {
    return {
      year: parseInt(date.split('-')[0]),
      month: parseInt(date.split('-')[1]),
      day: parseInt(date.split('-')[2]),
    }
  }
  
  validateDateTime(model: {beginDate:any, endDate:any, beginTime: number, endTime: number, codError: number, msgError: string} ) {
    model.codError = 0;
    model.msgError = "";

    if (model.beginDate != null && model.endDate != null && model.beginDate != '' && model.endDate != ''){
          var time_1 = model.beginTime;
          var time_2 = model.endTime;

          var beginDate = new Date(model.beginDate.year, model.beginDate.month - 1, model.beginDate.day);
          var endDate = new Date(model.endDate.year, model.endDate.month - 1, model.endDate.day);
          var day_1 = beginDate.getDate();
          var day_2 = endDate.getDate();
          var month_1 = beginDate.getMonth() +1;// getMonth() retorna um valor de 0-11, então adicionamos 1 para obter o mês como 1-12
          var month_2 = endDate.getMonth() +1;
          var year_1 = beginDate.getFullYear();
          var year_2 = endDate.getFullYear();

          if (isNaN(year_1)){
            model.codError = CodError.StartRequired;
            model.msgError = MsgError.StartRequired;
            return false;
          }

          if (isNaN(year_2)){
            model.codError = CodError.EndRequired;
            model.msgError = MsgError.EndRequired;
            return false;
          }

          if(year_1 > year_2) {
            model.codError = CodError.StartEarlierEnd;
            model.msgError = MsgError.StartEarlierEnd;
            return false;
          }

          if(year_1 == year_2) {
              if (month_1 > month_2){
                model.codError = CodError.StartEarlierEnd;
                model.msgError = MsgError.StartEarlierEnd;
                return false;
              }

              if(month_1 == month_2) {
                  if (day_1 > day_2){
                    model.codError = CodError.StartEarlierEnd;
                    model.msgError = MsgError.StartEarlierEnd;
                    return false;
                  }

                  if (day_1 == day_2){
                    if (time_1 + 1 <= time_2){
                      return true;
                    }

                    model.codError = CodError.OneHour;
                    model.msgError = MsgError.OneHour;
                    return false;
                  }

                  return true;
              }
          }

          return true;
    } else{
      if (model.beginDate != null && model.beginDate != ''){
        model.codError = CodError.EndRequired;
        model.msgError = MsgError.EndRequired;
      }
      else{
        model.codError = CodError.StartRequired;
        model.msgError = MsgError.StartRequired;
      }

      return false;
    }
  }

  transformToDate(calendar: any, time: any) {
    let day = calendar.day;
    let month = calendar.month;
    let year = calendar.year;
    calendar = month + '/' + day + '/' + year;
    time = time + ':00';
    return new Date(calendar + ' ' + time)
  }
}

