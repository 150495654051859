import { Pipe, PipeTransform } from '@angular/core';
import { PollType } from './utils/poll-type.enum';

@Pipe({
  name: 'PollTypePipe'
})
export class PollTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value === PollType.BOARD) {
      return 'Board';
    } else if (value === PollType.TSP) {
      return 'TSP';
    } else if (value === PollType.PARTNER) {
      return 'Partner';
    } else if (value === PollType.AP) {
      return 'AP';
    } else if (value === PollType.GENERIC) {
      return 'Others';
    } else {
      return '';
    }
  }

}
