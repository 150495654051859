import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-poll-creation',
    templateUrl: './poll-creation.component.html',
    styleUrls: ['./poll-creation.component.scss']
})

export class PollCreationComponent implements OnInit {

    constructor(private router: Router) {    }

    ngOnInit() {    }

}
