// src/app/auth/role-guard.service.ts
import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';
import { AuthService } from './auth.service';
// import decode from 'jwt-decode';
import { User } from './interfaces/user';
import { DeviceDetectorService } from 'ngx-device-detector';
//import { ENTER_CLASSNAME } from '@angular/animations/browser/src/util';

@Injectable()
export class RoleGuardService implements CanActivate {

    user: User;
    role: string;
    device: string;

  constructor(public auth: AuthService, public router: Router, private deviceService: DeviceDetectorService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config
    // on the data property
    const expectedRole = route.data.expectedRole;
    const token = localStorage.getItem('token');
    const expectedDevice = route.data.expectedDevice;

    // const isAuthorized = this.isAuthorized(expectedRole);

    // decode the token to get its payload

    // const tokenPayload = this.auth.getUserInfo();
    this.user = this.auth.getUserInfo();

    if (this.user.role == 0) {
        this.role = 'sponsor'
    } else if (this.user.role == 1 ) {
        this.role = 'admin';
    } else if (this.user.role == 2 ) {
        this.role = 'partner';
    } else if (this.user.role == 3 ){
        this.role = 'lawyer';
    } else if (this.user.role == 4){
        this.role = 'super';
    }

    if (this.deviceService.isMobile()){
      this.device = 'S';
    } else if (this.deviceService.isTablet()){
      this.device = 'T';
    } else {
      this.device = 'D';
    }

     if (
      !this.auth.isAuthenticated() || !expectedRole.includes(this.role) || !expectedDevice.includes(this.device)) {
      this.router.navigate(['access-denied']);
      return false;
    }
    return true;
  }

//   isAuthorized(allowedRoles: string[]): boolean {
//     // check if the list of allowed roles is empty, if empty, authorize the user to access the page
//     if (allowedRoles == null || allowedRoles.length === 0) {
//       return true;
//     }


//   // check if the user roles is in the list of allowed roles, return true if allowed and false if not allowed
//     return allowedRoles.includes(decodeToken['role']);
//   }
 }
