import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { PollService } from '../services/poll.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { User } from '../interfaces/user';
import { HttpClient } from '@angular/common/http';
import swal from 'sweetalert2';
import { LayoutComponent } from '../layout/layout.component';
import { BookingService } from '../services/sidebar.service';
import * as fileSaver from 'file-saver-es';
import { PollType } from '../utils/poll-type.enum';

declare var $: any;

@Component({
    providers: [LayoutComponent],
    selector: 'app-poll',
    templateUrl: './poll.component.html',
    styleUrls: ['./poll.component.scss']
})

export class PollComponent implements OnInit {

    //#region Variables
    itemList: any = [];
    selectedItems: any[] = [];
    settings = {};
    pollData: any = [
        {
            'title': '',
            'subtitle': '',
            'description': '',
            'enddate': '',
            'status': '',
            'pollType': ''
        }
    ];
    pollDataCandidates: any;
    partnerVoting: any =
        {
            poll_id: '',
            voter: '',
            candidates: [],
            answer: '',
            pollType: ''
        };
    generic = false;
    boardTsp = false;
    abc = 0;
    countSelectedCandidates = 0;
    tobVotingForm!: FormGroup;
    reportVotingForm!: FormGroup;
    votesForm!: FormGroup;
    selectedCandidates: any[] = [];
    user: User;
    partner!: boolean;
    confirmation = false;
    success = false;
    saving = false;
    id!: string;
    toggle: boolean = false;
    private dataCell: any = [];
    allowVote = true;
    link: any[] = [];
    fName: any[] = [];
    nullFile: any[] = [];
    nullPhoto: any[] = [];
    photoBytes: any[] = [];
    apartner = false;
    //#endregion Variables

    constructor(private data: DataService, private pollService: PollService, private http: HttpClient,
        private route: ActivatedRoute, private router: Router, private formBuilder: FormBuilder, private auth: AuthService,
        private lComponent: LayoutComponent, private bookingService: BookingService) {

        this.user = auth.getUserInfo();
    }

    confirmSavedPoll() {
        setTimeout(() => {
            this.saving = false;
            this.router.navigate(['/polls']);
        }
            , 5000);
    }

    //#region Action Buttons
    onSubmit(saveType: any) {  
        console.log("onSubmit called with saveType:", saveType);  
        let a = saveType;  
        let end = +new Date(this.pollData.end_date);  
        let today = +new Date();  
        let timeBetweenEndAndToday = (end - today);  
        timeBetweenEndAndToday = (timeBetweenEndAndToday / (1000 * 60 * 60 * 24));  
        Math.ceil(timeBetweenEndAndToday);  
        console.log("Poll end date:", this.pollData.end_date);  
        console.log("Current date:", new Date());  
        console.log("Time between end date and today (in days):", timeBetweenEndAndToday);  
      
        if (timeBetweenEndAndToday < 0) {  
            console.log("Poll is closed.");  
            swal.fire({  
                title: 'Poll closed',  
                text: 'This poll is already closed. You cannot vote',  
                icon: 'error',  
                confirmButtonColor: '#dc6900'  
            }).then(() => {  
                this.router.navigate(['/polls']).then(() => {  
                    window.location.reload();  
                });  
            });  
            return false;  
        }  
        swal.fire({  
            title: 'Do you confirm your vote?',  
            text: "You won't be able to revert this!",  
            icon: 'question',  
            showCancelButton: true,  
            confirmButtonText: 'Yes, I do',  
            cancelButtonText: 'No, I do not',  
            confirmButtonColor: '#dc6900',  
            reverseButtons: true  
        }).then((result) => {  
            if (result.value) {  
                console.log("Vote confirmed by user.");  
                if (this.pollData.pollType === PollType.BOARD || this.pollData.pollType === PollType.TSP) {  
                    this.selectedCandidates = $('#multiselectTob_to option').map(function () { return $(this).val(); }).get();  
                    this.tobVotingForm.controls['candidates'].setValue(this.selectedItems);  
                    this.tobVotingForm.controls['poll_id'].setValue(this.pollData.id);                  
                    this.tobVotingForm.controls['answer'].setValue('Y');                  
                    this.tobVotingForm.controls['voter'].setValue(this.user.guid);  
                    this.tobVotingForm.controls['pollType'].setValue(this.pollData.pollType);                  
      
                    if (this.tobVotingForm.invalid) {  
                        return;  
                    }  
      
                    this.saving = true;  
      
                    this.pollService.saveVote(this.tobVotingForm.value)  
                        .pipe(first())  
                        .subscribe(  
                            data => {  
                                swal.fire({  
                                    title: 'Success!',  
                                    text: 'Your vote has been saved.',  
                                    icon: 'success',  
                                    confirmButtonColor: '#dc6900'  
                                }).then(() => {  
                                    this.saving = false;  
                                    this.router.navigate(['/polls']).then(() => {  
                                        window.location.reload();  
                                    });  
                                });  
                            },  
                            error => {  
                                this.saving = false;  
                                swal.fire({  
                                    title: 'Error',  
                                    html: '<p>' + error.error.message + " (#" + error.status + ")" + '</p>',  
                                    icon: 'error',  
                                    confirmButtonColor: '#dc6900'  
                                }).then(() => {  
                                    this.router.navigate(['/polls']).then(() => {  
                                        window.location.reload();  
                                    });  
                                });  
                            });  
                } else if (this.pollData.pollType === PollType.GENERIC) {  
                    this.data.getCandidates(this.pollData.id).subscribe(data => {  
                        this.tobVotingForm.controls['candidates'].setValue(data);  
                        this.tobVotingForm.controls['poll_id'].setValue(this.pollData.id);                  
                        this.tobVotingForm.controls['answer'].setValue(saveType);  
                        this.tobVotingForm.controls['voter'].setValue(this.user.guid);  
                        this.tobVotingForm.controls['pollType'].setValue(this.pollData.pollType);  
      
                        if (this.tobVotingForm.invalid) {  
                            return;  
                        }  
      
                        this.saving = true;  
      
                        this.pollService.saveVote(this.tobVotingForm.value)  
                            .pipe(first())  
                            .subscribe(  
                                data => {  
                                    swal.fire({  
                                        title: 'Success!',  
                                        text: 'Your vote has been saved.',  
                                        icon: 'success',  
                                        confirmButtonColor: '#dc6900'  
                                    }).then(() => {  
                                        this.saving = false;  
                                        this.router.navigate(['/polls']).then(() => {  
                                            window.location.reload();  
                                        });  
                                    });  
                                },  
                                error => {  
                                    this.saving = false;  
                                    swal.fire({  
                                        title: 'Error',  
                                        html: '<p>' + error.error.message + " (#" + error.status + ")" + '</p>',  
                                        icon: 'error',  
                                        confirmButtonColor: '#dc6900'  
                                    }).then(() => {  
                                        this.router.navigate(['/polls']).then(() => {  
                                            window.location.reload();  
                                        });  
                                    });  
                                });  
                    });  
                }  
            }  
        });  
      }  

    onSubmitVotes(f: NgForm) {
        // 2- Partner e 3- AP
        let end = +new Date(this.pollData.end_date);
        let today = +new Date();
        let timeBetweenEndAndToday = (end - today);

        timeBetweenEndAndToday = (timeBetweenEndAndToday / (1000 * 60 * 60 * 24));
        Math.ceil(timeBetweenEndAndToday);

        if (timeBetweenEndAndToday < 0) {
            swal.fire({
                title: 'Poll closed',
                text: 'This poll is already closed. You can not vote', icon: 'error', confirmButtonColor: '#dc6900'
            })
                .then(() => {
                    this.router.navigate(['/polls']).then(() => {
                        window.location.reload();
                    });
                });
            return false;
        }
        swal.fire({
            title: 'Do you confirm your vote?',
            text: "You won't be able to revert this!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes, I do',
            cancelButtonText: 'No, I do not',
            confirmButtonColor: '#dc6900',
            reverseButtons: true
        }).then((result: any) => {
            if (result.value) {
                this.partnerVoting.voter = this.user.guid;
                this.partnerVoting.poll_id = this.pollData.id;
                this.partnerVoting.candidates = [f.form.value];
                let idCandidate;
                let vote;
                let cand: any[] = [];
                Object.keys(f.form.value)
                    .forEach(function eachKey(key, i) {
                        idCandidate = key;
                        vote = f.form.value[key];
                        cand.push({
                            idCandidate,
                            vote
                        });
                    });

                this.partnerVoting.answer = vote;
                this.partnerVoting.candidates = cand;
                this.partnerVoting.pollType = this.pollData.pollType;                
                this.saving = true;
                //console.log(this.partnerVoting)

                this.pollService.saveVote(this.partnerVoting).subscribe(
                    data => swal.fire({ title: 'Success!', text: 'Your vote has been saved.', icon: 'success', confirmButtonColor: '#dc6900' })
                        .then(() => {
                            this.saving = false;
                            this.router.navigate(['/polls']).then(() => {
                                window.location.reload();
                            });

                        }),
                    error => {
                        this.saving = false;
                        swal.fire({
                            title: 'Error',
                            html: '<p>' + error.error.message + " (#" + error.status + ")" + '</p>',
                            icon: 'error', confirmButtonColor: '#dc6900'
                        })
                            .then(() => {
                                this.router.navigate(['/polls']).then(() => {
                                    window.location.reload();
                                });
                            });
                    });
            }
        });
    }
    //#endregion Action Buttons


    //#region Candidate
    getDownload(fileName: any[], link: any[]) {
        this.saving = true;
        for(let i = 0; i< fileName.length;i++){
            this.saving = true
             this.data.getDownload(fileName[i], link[i]).subscribe((data: any) => {
                let blob:any = new Blob([data], { type: 'text/json; charset=utf-8' });
                const url = window.URL.createObjectURL(blob);
                fileSaver.saveAs(blob, fileName[i])
                if(i == fileName.length - 1) this.saving = false
                
            },
            error => {
                console.log("erro", error.toString());
                this.saving = false
            });
        }
    }

    getDownloadBusinessCase(fileName: string, link: string) {
        this.saving = true
        this.data.getDownload(fileName, link).subscribe((data: any) => {
            let blob: any = new Blob([data], { type: 'text/json; charset=utf-8' });
            const url = window.URL.createObjectURL(blob);
            fileSaver.saveAs(blob, fileName)
            this.saving = false;
        },
        error => {
            console.log("erro", error.toString());
            this.saving = false
            });
    }

    getDownloadPhoto(i: number) {
        if (this.pollData.candidates[i].attachments[0].link != null) {
            this.data.getDownload('photo', this.pollData.candidates[i].attachments[0].link).subscribe((data: any) => {
                let blob: any = new Blob([data], { type: 'image/gif' });
                var reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = (event: any) => {
                    this.photoBytes[i] = event.target.result;
                }
            });
        }
    }

    onDeSelectAll(items: any) {
        this.countSelectedCandidates = 0;
        this.allowVote = true;
    }

    onItemDeSelect(item: any) {
        this.countSelectedCandidates--;   
        this.allowVote = this.countSelectedCandidates <= this.pollData.vacancy && this.countSelectedCandidates != 0 ? false : true;

    }

    onItemSelect(item: any) {
        this.countSelectedCandidates++;    
        this.allowVote = this.countSelectedCandidates <= this.pollData.vacancy && this.countSelectedCandidates != 0 ? false : true;   
    }

    onSelectAll(items: any) {
        this.countSelectedCandidates = items.length;
        this.allowVote = this.countSelectedCandidates == this.pollData.vacancy ? false : true;
    }

    selectCandidate(){
        this.id = this.route.snapshot.paramMap.get('id') as string;
        this.data.getPollPartner(this.id).subscribe(data => {
            this.pollData = data;
            this.settings = {
                singleSelection: false,
                itemsShowLimit: 5,
                enableSearchFilter: false,
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                classes: "myclass custom-class-example",
                idField: 'id',
                textField: 'candidatename',
                labelKey: 'candidatename',
            };

            if (this.pollData.pollType >= PollType.PARTNER && this.pollData.pollType <= PollType.GENERIC) {
                if (this.pollData.pollType != PollType.GENERIC) {
                    for (let i of Object.keys(this.pollData.candidates)) {
                        if ((this.pollData.candidates[i].attachments[1].link == null) ||
                            (this.pollData.candidates[i].attachments[1].link == '')) {
                            this.nullFile[Number(i)] = true;
                        } else {
                            this.nullFile[Number(i)] = false;
                        }
                        if ((this.pollData.candidates[i].attachments[0].link == null) ||
                            (this.pollData.candidates[i].attachments[0].link == '')) {
                            this.nullPhoto[Number(i)] = true;
                        } else {
                            this.nullPhoto[Number(i)] = false;
                            this.getDownloadPhoto(Number(i));
                        }
                    }
                } else {
                    for(let i = 0; i< this.pollData.poll_attachments.length;i++){
                        this.link[i] = this.pollData.poll_attachments[i].link
                        this.fName[i] = this.pollData.poll_attachments[i].name;
                        console.log(this.fName[i])
                    }
                }
            }

            this.pollDataCandidates = this.pollData.candidates;
            this.dataCell[0] = data;

            this.dataCell = this.pollData.candidates.candidatename;
            if (this.pollData.pollType === PollType.GENERIC) {
                this.generic = true;

            } else if (this.pollData.pollType === PollType.BOARD || this.pollData.pollType === PollType.TSP) {
                this.boardTsp = true;
            } else {
                this.partner = true;
            }

            if (this.pollData.pollType === PollType.AP) {
                this.apartner = true;
            }
        },
        error => {
            swal.fire({
                title: 'Oops...', html: '<p>We are sorry, an error has occurred. Please contact the system administrators.</p>' +
                    '<br><div class="alert alert-danger" role="alert"><p> Error: </p><br>' + error.error.message + '<br><br>' + error.message + '</div>',
                icon: 'error', confirmButtonColor: '#dc6900'
            })
                .then(() => {
                    this.router.navigate(['/polls']);
                });
        });
    }
    //#endregion Candidate


    //#region Load
    public getDetail() {
        this.selectCandidate();        
    }
    //#endregion Load


    //#region OnInit
    ngOnInit() {
        this.bookingService.sendValue('a');
        this.bookingService.sendValue('b');
        this.getDetail();
        this.validations();
        const component = this;
    }
    //#endregion OnInit


    //#region Validations
    validations(){
        this.tobVotingForm = this.formBuilder.group({
            candidates: ['', Validators.required],
            poll_id: ['', Validators.required],
            answer: [''],
            voter: ['', Validators.required],
            pollType: [''],
        });
        this.reportVotingForm = this.formBuilder.group({
            answer: ['', Validators.required],
            poll_id: ['', Validators.required],
            candidates: ['', Validators.required],
            voter: ['', Validators.required],
            pollType: [''],
        });

        this.votesForm = this.formBuilder.group({
            votesForm: [[], Validators.required]
        });
    }
    //#endregion Validation
}