import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateTimeFormatFilter'
})
export class DateTimeFormatFilterPipe implements PipeTransform {

  transform(date: any, format: string): any {
    if (date) {
        date = moment.utc(date).format(format);

        var stillUtc = moment.utc(date).toDate();
        var local = moment(stillUtc).local().format(format);

        return local;
       }
  }

}
