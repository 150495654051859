import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DataService } from '../data.service';
import { DateUtil } from '../utils/date.util';
import { CodError } from '../utils/date.enum';
import { MsgError } from '../utils/date.enum';
import { first } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { AttachmentService } from '../services/attachment.service';
import { AuthService } from '../auth.service';
import { User } from '../interfaces/user';
import { NgbTimepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import swal from 'sweetalert2';
declare var $: any;
import * as _ from 'lodash';
import { trigger, state, style, animate, transition } from '@angular/animations';
import * as fileSaver from 'file-saver-es';
import { PollSaveType } from '../utils/poll-save-type.enum';

@Component({
  selector: 'app-edit-poll-partner',
  templateUrl: './edit-poll-partner.component.html',
  styleUrls: ['./edit-poll-partner.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(1000)),
    ]),
  ]
})

export class EditPollPartner2Component implements OnInit {

  //#region Variables
  user: User;
  validHours = Array.from({ length: 24 }, (v, k) => k + 0);
  minDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
  partnerForm: any = {
    id: '',
    name: '',
    description: '',
    begin_date: '',
    begin_time: '0',
    end_date: '',
    end_time: '0',
    email: '',
    candidates: [],
    pollType: '',
    saveType: '',
    approver_message: '',
    selectedVoters: [],
    created_by_pwc_guid: ''
  };
  newCandidate: any = {
    photo: File,
    candidatename: '',
    candidateLos: '',
    candidatedesc: '',
    businesscase: '',
    fName: '',
    photoBytes: ''
  }
  voters: any = Object;
  errors: any = {};
  submitted = false;
  loader = false;
  loadingVoter = false;
  countVoters = 0;
  countCandidate = 0;
  voterError = false;
  saving = false;
  startRequired = false;
  endRequired = false;
  startEarlierEnd = false;
  oneHour = false;
  msgErr: string;
  groupError = false;
  selectedGroupItems: any[] = [];
  selectedGroups: any[] = [];
  groupSettings = {};
  pollDataGroups: any;
  countSelectedGroups = 0;
  countShowVoterTable = 0;
  countShowCandidateTable = 0;
  tobForm: FormGroup;
  draft: PollSaveType = PollSaveType.Draft;
  pendingApproval: PollSaveType = PollSaveType.PendingApproval;
  approved: PollSaveType = PollSaveType.Approved;
  //#endregion Variables

  constructor(private router: Router, private http: HttpClient, private formBuilder: FormBuilder,
    private modalService: BsModalService, private dataService: DataService, private dateUtil: DateUtil,
    private attachmentService: AttachmentService, private auth: AuthService, config: NgbTimepickerConfig,
    private route: ActivatedRoute, private location: Location) {
    config.spinners = false;
  }

  removeInvalidClass() {
    $("input").click(function () { $(this).removeClass("is-invalid") })
    $("select").click(function () { $(this).removeClass("is-invalid") })
    $("textarea").click(function () { $(this).removeClass("is-invalid") })
  }


  //#region Action Buttons
  cancel() {
    swal.fire({
      title: 'Are you sure?',
      text: 'All information will be lost.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dc6900',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, I am sure.'
    }).then((result: any) => {
      if (result.value) {
        this.router.navigate(['/admin']);
      }
    });
  }

  onSubmit(event: any, type: any) {
    this.saving = true;
    this.submitted = true;
    this.partnerForm.selectedVoters = _.map(this.voters, 'pwc_guid');
    this.partnerForm.email = this.partnerForm.email == '' ? '-' : this.partnerForm.email;
    this.partnerForm['saveType'] = PollSaveType.Draft
    this.partnerForm.approver_message = type == PollSaveType[this.approved] ? PollSaveType[this.approved] : this.partnerForm.approver_message;

    if (type !== PollSaveType[this.draft]) {
      this.validateEmptyFields(this.partnerForm)
      if(!this.validateDates()) return;
      if(!this.validateFields()) return;

      if (type == PollSaveType[this.pendingApproval])
        this.partnerForm['saveType'] = PollSaveType.PendingApproval;
      else if (type == PollSaveType[this.approved])
        this.partnerForm['saveType'] = PollSaveType.Approved;       
    }
    
    this.partnerForm.begin_date = this.partnerForm.begin_date != '' ? this.dateUtil.transformToDate(this.partnerForm.begin_date, this.partnerForm.begin_time) : null
    this.partnerForm.end_date = this.partnerForm.end_date != '' ? this.dateUtil.transformToDate(this.partnerForm.end_date, this.partnerForm.end_time) : null
    this.partnerForm['current_user'] = this.user.guid;
    this.partnerForm.created_by_pwc_guid = this.user.guid;
    this.loader = true;
    
    this.dataService.updatePoll(this.partnerForm)
      .pipe(first())
      .subscribe(
        data => swal.fire({ title: 'Success!', text: 'The poll has been saved.', icon: 'success', confirmButtonColor: '#dc6900' })
          .then(() => {
            this.router.navigate(['/admin']);
          }),
        error => swal.fire({
          title: 'Oops...',
          html: '<p>We are sorry, an error has occurred. Please contact the system administrators.</p>' +
            '<br><div class="alert alert-danger" role="alert"><p> Error: </p><br>' + error.error.message + '<br><br>'
            + error.message + '</div>',
          icon: 'error', confirmButtonColor: '#dc6900'
        })
          .then(() => {
            this.router.navigate(['/admin']);
          }));
  }

  goBack() {
    this.location.back();
  }
  //#endregion Action Buttons


  //#region Candidates
  addCandidate() {
    this.partnerForm.candidates.push(this.newCandidate);
    this.newCandidate = {
      photo: File,
      candidatename: '',
      candidateLos: '',
      candidatedesc: '',
      businesscase: '',
      fName: ''
    };
    this.errors.candidates = false;
  }

  getDownload(fileName: string, link: string) {
    this.dataService.getDownload(fileName, link).subscribe((data: any) => {
      let blob: any = new Blob([data], { type: 'text/json; charset=utf-8' });
      const url = window.URL.createObjectURL(blob);
      fileSaver.saveAs(blob, fileName)
    },
      error => {
        console.log("erro", error.toString());
      });
  }

  getDownloadPhoto(i: number) {
    if (this.partnerForm.candidates[i].photo != null) {
      this.dataService.getDownload('photo', this.partnerForm.candidates[i].photo).subscribe((data: any) => {
        let blob: any = new Blob([data], { type: 'image/gif' });
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = (event: any) => {          
          this.partnerForm.candidates[i].photoBytes = event.target.result;          
        }
      });
    }
  }

  onFileChanged(event: any) {
    this.newCandidate.businesscase = event.target.files[0];
    this.newCandidate.fName = this.newCandidate.businesscase.name
    const uploadData = new FormData();
    uploadData.append('myFile', this.newCandidate.businesscase, this.newCandidate.businesscase.name);
    uploadData.append('fileType', 'BUSINESSCASE')
    this.loader = true;
    this.attachmentService.sendAttachment(uploadData)
      .subscribe(
        data => {
          this.newCandidate.businesscase = data.toString();
          this.loader = false;
        },
        error => {
          swal.fire({
            title: 'Oops...',
            html: '<p>We are sorry, an error has occurred. Please contact the system administrators.</p>' +
              '<br><div class="alert alert-danger" role="alert"><p> Error: </p><br>' + error.error.message + '<br><br>'
              + error.message + '</div>',
            icon: 'error', confirmButtonColor: '#dc6900'
          })
          this.loader = false;
          this.newCandidate.businesscase = ''
          this.newCandidate.fName = ''
        });
  }

  onPhotoChanged(event: any) {
    this.newCandidate.photo = event.target.files[0];
    const uploadData = new FormData();
    uploadData.append('myFile', this.newCandidate.photo, this.newCandidate.photo.name);
    uploadData.append('fileType', 'PHOTO')
    this.loader = true;
    this.attachmentService.sendAttachment(uploadData)
      .subscribe(
        data => {
          this.newCandidate.photo = data.toString();
          var reader = new FileReader();
          reader.onload = (event: any) => {
            this.newCandidate.photoBytes = event.target.result;
          }
          reader.readAsDataURL(event.target.files[0]);
          this.loader = false;
        },
        error => {
          swal.fire({
            title: 'Oops...',
            html: '<p>We are sorry, an error has occurred. Please contact the system administrators.</p>' +
              '<br><div class="alert alert-danger" role="alert"><p> Error: </p><br>' + error.error.message + '<br><br>'
              + error.message + '</div>',
            icon: 'error', confirmButtonColor: '#dc6900'
          })
          this.newCandidate.photo = File
          this.loader = false
        });
  }

  removeCandidate(i: number) {
    this.partnerForm.candidates.splice(i, 1);
  }  
  //#endregion Candidates


  //#region Clear
  clearMsgErrDate(){
    this.startRequired = false;
    this.endRequired = false;
    this.startEarlierEnd = false;
    this.oneHour = false;
    this.msgErr = '';
  }
  //#endregion Clear


  //#region Groups
  onGroupDeSelectAll(items: any) {
    this.countSelectedGroups = 0;
  }

  onGroupItemDeSelect(item: any) {
    this.countSelectedGroups--;
  }

  onGroupItemSelect(item: any) {
    this.countSelectedGroups++;
    this.groupError = false;
  }

  onGroupSelectAll(items: any) {
    this.countSelectedGroups = items.length;
    this.groupError = false;
  }

  selectGroup() {
    this.dataService.getGroups().subscribe(data => {
      this.pollDataGroups = data;
    });

    this.groupSettings = {
      singleSelection: false,
      itemsShowLimit: 5,
      enableSearchFilter: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      classes: "myclass custom-class-example",
      idField: 'id',
      textField: 'groupname',
      labelKey: 'groupname',
    };
  }
  //#endregion Groups


  //#region Load
  getPoll() {
    const id = this.route.snapshot.paramMap.get('id');
    this.partnerForm.id = id
    var candidates_array: any[] = []
    this.dataService.getPoll(id).subscribe(data => {
      this.partnerForm.name = data['name' as keyof typeof data]
      this.partnerForm.description = data['description' as keyof typeof data]
      this.partnerForm.email = data['email' as keyof typeof data]      
      this.partnerForm.candidates = data['candidates' as keyof typeof data];

      if(this.partnerForm.candidates.length > 0 && this.partnerForm.candidates[0].attachments.length > 0){        
        for (let candidate of this.partnerForm.candidates) {
          candidates_array.push(
            {
              candidatename: candidate.candidatename,
              candidatedesc: candidate.candidatedesc,
              candidateLos: candidate.candidateLos,
              photo: candidate.attachments[0].link,
              photoBytes: null,
              businesscase: candidate.attachments[1].link,
              fName: candidate.attachments[1].name
            }
          )
        }
      }
      this.partnerForm.candidates = candidates_array;

      for (let i in this.partnerForm.candidates) {
        this.getDownloadPhoto(Number(i));
      }

      this.countCandidate = this.partnerForm.candidates.length;
      this.partnerForm.selectedVoters = data['voters' as keyof typeof data];
      this.voters = data['voters' as keyof typeof data];
      this.countVoters = Object.keys(this.voters).length;
      this.voters = _.map(this.voters, function (item) {
        return {
          id: item.id, name: item.name, email: item.email, active: item.active, created_at: item.created_at,
          password: item.password, profile: item.profile,
          pwc_guid: item.guid, pwc_number: item.pwc_number, updated_at: item.updated_at
        };
      });

      $('#tabelaVoters').DataTable().clear().draw();
      $.each(this.voters,
        function (key: any, item: any) {
          $("#tabelaVoters").DataTable().row.add([
            item.name
          ]).draw();
        });
      this.countShowVoterTable++;
      this.partnerForm.begin_time = data['begin_date' as keyof typeof data] != null ? this.dateUtil.convertDateTime(data['begin_date' as keyof typeof data], true) : 0;
      if (isNaN(this.partnerForm.begin_time)) this.partnerForm.begin_time = '0';

      this.partnerForm.end_time = data['end_date' as keyof typeof data] != null ? this.dateUtil.convertDateTime(data['end_date' as keyof typeof data], true) : 0      
      if (isNaN(this.partnerForm.end_time)) this.partnerForm.end_time = '0';

      this.partnerForm.begin_date = data['begin_date' as keyof typeof data] != null ? this.dateUtil.convertDateTime(data['begin_date' as keyof typeof data], false) : ''
      this.partnerForm.end_date = data['end_date' as keyof typeof data] != null ? this.dateUtil.convertDateTime(data['end_date' as keyof typeof data], false) : ''
      this.partnerForm.saveType = data['saveType' as keyof typeof data];
      this.partnerForm.pollType = data['pollType' as keyof typeof data];
      this.partnerForm.created_by_pwc_guid = data['created_by_pwc_guid' as keyof typeof data];

      if (data['approver_message' as keyof typeof data] == null || data['approver_message' as keyof typeof data].toString() == '') {
        this.partnerForm.approver_message = ' ';
      } else {
        this.partnerForm.approver_message = data['approver_message' as keyof typeof data];
      }
    });
  }
  //#endregion Load


  //#region OnInit
  ngOnInit() {
    this.buildForms();
    this.selectGroup();   
    this.user = this.auth.getUserInfo();
    this.getPoll();
  }
  //#endregion OnInit


  //#region Validations
  buildForms() {
    this.tobForm = this.formBuilder.group({
      selectedGroups: ['', Validators.required]
    });
  }

  filterError(value: any) {
    return (value == true)
  }

  filterVolter(value: any) {
    return (value == 'undefined')
  }

  validateDates() {
    this.msgErr = '';
      let model = {
        beginDate: this.partnerForm.begin_date, endDate: this.partnerForm.end_date
        , beginTime: this.partnerForm.begin_time, endTime: this.partnerForm.end_time, codError: 0, msgError: ""
      };
    
    if (!this.dateUtil.validateDateTime(model)) {
      this.startRequired = model.codError == CodError.StartRequired ? true : false;
      this.endRequired = model.codError == CodError.EndRequired ? true : false;
      this.startEarlierEnd = model.codError == CodError.StartEarlierEnd ? true : false;
      this.oneHour = model.codError == CodError.OneHour ? true : false;

      if (this.startRequired)
        this.msgErr = MsgError.StartRequired;      
      else if (this.endRequired)
        this.msgErr = MsgError.EndRequired;
      else if (this.startEarlierEnd)
        this.msgErr = MsgError.StartEarlierEnd;
      else if (this.oneHour)
        this.msgErr = MsgError.OneHour;

      if (this.msgErr != '') {
        this.saving = false;
        return false;
      }
    }
    else{
      this.clearMsgErrDate();
      return true;
    }
  }

  validateEmptyFields(obj: any) {
    for (let value of Object.entries(obj)) {
      if (value[0] != undefined) {
        this.errors[value[0]] = (value[1].toString() == "" || value[1] == null)
      }
    }
  }

  validateFields(){
    if (Object.values(this.errors).length != 0) {        
      if (Object.values(this.errors).filter(this.filterError).length > 0) {
        this.saving = false;
        return;
      }

      if (this.partnerForm.selectedVoters.filter(this.filterVolter).length > 0) {
        this.saving = false;
        this.errors.selectedVoters = true;
        return;
      }
    }

    return true;
  }
  //#endregion Validations


  //#region Voters
  loadVoters() {
    this.selectedGroups = _.map(this.selectedGroupItems, 'id');

    if (this.countSelectedGroups == 0) {
      this.groupError = true;
      return
    }

    this.loadingVoter = true;
    this.dataService.getPartnersGroup(this.selectedGroupItems).subscribe(data => {
      this.loadingVoter = false;
      this.voters = data;
      this.voterError = false;
      this.errors.selectedVoters = false;
      this.countVoters = Object.keys(this.voters).length;
      $('#tabelaVoters').DataTable().clear().draw();

      $.each(data,
        function (key: any, item: any) {
          $("#tabelaVoters").DataTable().row.add([
            item.name
          ]).draw();
        });
      this.countShowVoterTable++;
    });
  }
  //#endregion Voters
}
