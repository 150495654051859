import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class AttachmentService {

  constructor(private http: HttpClient) { }

  sendAttachment(files: FormData) {
    return this.http.post(environment.apiEndpoint + 'attachment', files);
  }
}
