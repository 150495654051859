<h3 class="title">History</h3><br>
<div class="card">
    <div class="card-body">
        <div *ngIf="polls" class="table-responsive" >
            <h5 class="card-title poll-content-list">Poll summary</h5>
            <table datatable class="table table-bordered table-hover">
                <thead>
                    <tr >
                        <th class="table-head-type">Type</th>
                        <th class="table-head-title">Title</th>
                        <th class="table-head-date">Start Date</th>
                        <th class="table-head-date">End Date</th>
                        <th class="table-head-status">Status</th>
                    </tr>
                </thead>
                <tbody class="poll-content-list">
                    <tr *ngFor ="let poll of polls ">
                        <td><span class="badge badge-dark">
                                {{poll.pollType | PollTypePipe}}</span></td>
                        <td>{{poll.name}}</td>
                        <td>{{poll.begin_date | dateTimeFormatFilter : "MMM DD, YYYY HH:mm"}}</td>
                        <td>{{ poll.end_date | dateTimeFormatFilter : "MMM DD, YYYY HH:mm" }}</td>
                        <td><span class="badge"   [ngClass]="{
                            'badge-draft': poll.saveType==0,
                            'badge-pending': poll.saveType==1,
                            'badge-approved': poll.saveType==2,
                            'badge-action': poll.saveType==3,
                            'badge-active': poll.saveType==4,
                            'badge-closed': poll.saveType==5,
                            'badge-accounted': poll.saveType==6,
                            'badge-light': poll.saveType==7}">
                            {{poll.saveType | PollstatusPipe}}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
