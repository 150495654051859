import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'DaysRemainingPipe'
})
export class DaysRemainingPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value >= 70 ) {
      return 'Active';
    } else if (value < 70 && value >= 20) {
      return 'Ending Soon';
    } else if (value < 20) {
      return 'End today';
    } else {
      return null;
    }
  }

}
