import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { AuthService } from '../auth.service';
import { User } from '../interfaces/user';

@Component({
  selector: 'app-partner-polls',
  templateUrl: './partner-polls.component.html',
  styleUrls: ['./partner-polls.component.scss']
})
export class PartnerPollsComponent implements OnInit {

  polls: any = [
    {
      'title': '',
      'subtitle': '',
      'description': '',
      'enddate': '',
      'status': '',
      'polltype': '',
      'poll': []
    }
  ];
  daysRemaining = 0;
  user: User | undefined;
  emptyPoll = false;
  pollExists = false;
  constructor(private data: DataService, private auth: AuthService) { }



  isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
    }

  ngOnInit() {
    this.user = this.auth.getUserInfo();

    this.getPolls();
  }

  getPolls(){
    this.data.getPollsByPartner(this.user.guid).subscribe(data => {
        this.polls = data;

        if (this.polls == '') {
            this.pollExists = false;
        } else {
            this.pollExists = true;
        }

        for (let i in Object.keys(this.polls)) {
            this.polls[i].daysRemaining = this.dateCalculus(this.polls[i].poll);
            if (this.isEmpty(this.polls[i].poll)) {
                this.emptyPoll = true;
            }
          }

        });
  }

  dateCalculus(data: { begin_date: string | number | Date; end_date: string | number | Date; }) {

// alert(data)
// alert(data.begin_date)
// alert(data.end_date)

    // alert("no datecalculus");
  let start = +new Date(data.begin_date);
//   alert(new Date(data.begin_date));
//   alert(+new Date(data.begin_date));
//   alert("begin_Date "+ start);
  let end = +new Date(data.end_date);
//   alert("end date "+ end);
  let  today = +new Date();
//   alert("today "+ today);

  let timeBetweenStartAndEnd = (end - start);
//   alert(timeBetweenStartAndEnd);
  let timeBetweenEndAndToday = (end - today);
//   alert(timeBetweenEndAndToday);
  timeBetweenStartAndEnd = (timeBetweenStartAndEnd / (1000 * 60 * 60 * 24));
  timeBetweenEndAndToday = (timeBetweenEndAndToday / (1000 * 60 * 60 * 24));
//   alert(timeBetweenEndAndToday);
  Math.ceil(timeBetweenEndAndToday);
//   alert(timeBetweenEndAndToday);

//   let x =
//   alert(x);
return Math.round( timeBetweenEndAndToday / timeBetweenStartAndEnd  * 100);

  }

}
