import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth.service';
import { User } from './interfaces/user';

@Injectable({
  providedIn: 'root'
})



export class DataService {
  createReport(data: any) {
      throw new Error('Method not implemented.');
  }

  user: User;
  count: number;

  constructor(private http: HttpClient, private auth: AuthService) {

  this.user = auth.getUserInfo();

  }

  //#region Candidates
  getCandidates(pollId: any) {
    return this.http.get(environment.apiEndpoint + 'candidate', {params: {poll_id: pollId}});
  }
  //#endregion Candidates


  //#region Download
  getDownload(fileName: any, link: any): Observable<any>{
		return this.http.get(environment.apiEndpoint + 'download', {params: {fn: fileName, lk: link}, responseType: 'blob'});
  }
  //#endregion Download


  //#region Group
  getAllGroups() {
    return this.http.get(environment.apiEndpoint + 'group');
  }

  getGroups() {
    return this.http.get(environment.apiEndpoint + 'group', {params: {active: 'true'}});
  }
  //#endregion Group


  //#region Poll
  approvePoll(data: any) {
    return this.http.put(environment.apiEndpoint + 'poll/sponsor', data, {params: {current_user: this.user.guid}});
  }

  auditPoll(data: any) {
    return this.http.put(environment.apiEndpoint + 'poll/completed', data, {params: {current_user: this.user.guid}});
  }

  cancelPoll(pollId: any) {
    return this.http.put(environment.apiEndpoint + 'poll/cancel', pollId, {params: {current_user: this.user.guid}});
  }

  createPoll(data: any) {
    return this.http.post(environment.apiEndpoint + 'poll', data, {params: {current_user: this.user.guid}});
  }

  declinePoll(data: any) {
    return this.http.post(environment.apiEndpoint + 'declinePoll', data, {params: {current_user: this.user.guid}});
  }

  deletePoll(pollId: any) {
    return this.http.delete(environment.apiEndpoint + 'poll', {params: {id: pollId, current_user: this.user.guid}});
  }

  getCompletedPolls() {
    return this.http.get(environment.apiEndpoint + 'poll/result');
  }

  getPoll(pollId: any) {
    return this.http.get(environment.apiEndpoint + 'poll', {params: {id: pollId}});
  }

  getPollPartner(pollId: any) {
    return this.http.get(environment.apiEndpoint + 'poll/partner', {params: {pollId: pollId}});
  }

  getPollResults(pollId: any) {
    return this.http.get(environment.apiEndpoint + 'poll/result', {params: {id: pollId}});
  }

  getPolls() {
    return this.http.get( environment.apiEndpoint + 'poll2');
  }

  getPollsAdmin() {
    return this.http.get(environment.apiEndpoint + 'poll');
  }

  getPollsByPartner(partnerId: any) {
    return this.http.get( environment.apiEndpoint + 'poll/partner', { params: {partner_guid: partnerId}});
  }

  getPollsByPartnerSidebar(partnerId: any) {
    return this.http.get( environment.apiEndpoint + 'poll/partner/sidebar', { params: {partner_guid: partnerId}});
  }

  getPollsHistory() {
    return this.http.get(environment.apiEndpoint + 'poll', {params: {history: 'true'}});
  }

  updatePoll(data: any){
    return this.http.put(environment.apiEndpoint + 'poll', data, {params: {current_user: this.user.guid}});
  }
  //#endregion Poll

  
  //#region SaveVote
  saveVote(voteData: any) {
    return this.http.post('this.baseUrl', voteData);
  }
  //#endregion SaveVote


  //#region Voters
  getPartner(pwc_guid: any){
    return this.http.get(environment.apiEndpoint + 'user/voters', {params: {pwc_guid: pwc_guid}});
  }

  getPartners() {
    return this.http.get(environment.apiEndpoint + 'user/voters');
  }

  getPartnersGroup(groups: any) {
    return this.http.post(environment.apiEndpoint + 'user/voters', groups);
  }
  //#endregion Voters
}
