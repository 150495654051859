<style>
    .form-division-heading {
        color: #384047;
        font-size: 18px;
        margin-bottom: 30px;
    }

    .form-division-heading .form-step {
        background-color: #fff;
        border: solid 2px #cf5f5f;
        color: #cf5f5f;
        height: 30px;
        line-height: 26px;
        margin: -2px 10px 0 0px;
        position: relative;
        text-align: center;
        width: 30px;
        display: inline-block;
        border-radius: 100px;
    }

    /* Center the loader */
    #loader {
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 1;
        width: 150px;
        height: 150px;
        margin: -75px 0 0 -75px;
        border: 16px solid #f3f3f3;
        border-radius: 50%;
        border-top: 16px solid #3498db;
        width: 120px;
        height: 120px;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }

    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    /* Add animation to "page content" */
    .animate-bottom {
        position: relative;
        -webkit-animation-name: animatebottom;
        -webkit-animation-duration: 1s;
        animation-name: animatebottom;
        animation-duration: 1s
    }

    @-webkit-keyframes animatebottom {
        from {
            bottom: -100px;
            opacity: 0
        }

        to {
            bottom: 0px;
            opacity: 1
        }
    }

    @keyframes animatebottom {
        from {
            bottom: -100px;
            opacity: 0
        }

        to {
            bottom: 0;
            opacity: 1
        }
    }
</style>

<div *ngIf="requiredFields">
    <p *ngFor="let alert of alerts">
        <ngb-alert [type]="alert.type" (close)="close(alert)">{{ alert.message }}</ngb-alert>
    </p>
</div>
<button type="button" class="btn btn-danger fa fa-arrow-left poll-btn-back" (click)="goBack()"></button>
<h2 style="margin-left: 0;margin-top: 0;">Create a new {{title}} poll&nbsp;</h2>
<p style="font-size: 22px;">Fill out the form below in order to create a new poll</p>

<form [formGroup]="partnerForm" (ngSubmit)="onSubmit($event)">
    <h3 class="form-division-heading"><span class="form-step">1</span>Basic Information</h3>
    <!-- Title -->
    <div class="form-group"><label>Title</label>
        <input type="text" placeholder="Poll title" formControlName="name" [value]="pollTitle" maxlength="128"
            class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
            <div *ngIf="f.name.errors.required">Title is required</div>
        </div>
    </div>

    <!-- Description -->
    <div class="form-group"><label>Description</label>
        <!-- <textarea placeholder="Poll description" autofocus="" formControlName="description"  maxlength="1024" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea> -->
        <textarea placeholder="Poll description" autofocus="" formControlName="description" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
        <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
            <div *ngIf="f.description.errors.required">Description is required</div>
        </div>
    </div>

    <!-- Date-time Begin End--> 
    <div class="form-row">
        <!-- Start date -->
        <div class="col">
            <div class="form-group"><label>Start date</label>
                <br>
                <div style="margin-left: 300px">
                    <div style="display:flex" _ngcontent-c3="" class="ngb-tp">
                        <div style="width: 4em;" _ngcontent-c3="" class="ngb-tp-input-container ngb-tp-hour">
                            <select class="form-control" style="width:110%" (click)="validateDate()"
                                formControlName="begin_time">
                                <option>0</option><option>1</option><option>2</option><option>3</option><option>4</option>
                                <option>5</option><option>6</option><option>7</option><option>8</option><option>9</option>
                                <option>10</option><option>11</option><option>12</option><option>13</option><option>14</option>
                                <option>15</option><option>16</option><option>17</option><option>18</option><option>19</option>
                                <option>20</option><option>21</option><option>22</option><option>23</option>
                            </select>
                        </div>
                        <div style="width: 1em; text-align: center;" _ngcontent-c3="" class="ngb-tp-spacer">:

                        </div>
                        <div style="width: 4em;" _ngcontent-c3="" class="ngb-tp-input-container ngb-tp-minute">
                            <input _ngcontent-c3="" disabled aria-label="Minutes" class="form-control" maxlength="2"
                                placeholder="00" type="text">
                        </div>
                    </div>
                </div>
                <div class="poll-calendar-start">
                    <ngb-datepicker [ngClass]="{ 'poll-dataInvalida': dataInvalida==true }" (click)="validateDate()" formControlName="begin_date" [minDate]="minDate"></ngb-datepicker>
                    <div *ngIf="dataInvalida" class="poll-message-error">Begin date is required</div>
                </div>
                <div *ngIf="submitted && f.begin_date.errors" class="invalid-feedback">
                    <div *ngIf="f.begin_date.errors.required">Start Date is required</div>
                </div>
            </div>
        </div>

        <!--End date -->
        <div class="col">
            <div class="form-group"><label>End date</label>
                <br>
                <div style="margin-left: 300px">
                    <div style="display:flex" _ngcontent-c3="" class="ngb-tp">
                        <div style="width: 4em;" _ngcontent-c3="" class="ngb-tp-input-container ngb-tp-hour">
                            <select class="form-control" style="width:110%" (click)="validateDate()"
                                formControlName="end_time">
                                <option>0</option><option>1</option><option>2</option><option>3</option><option>4</option>
                                <option>5</option><option>6</option><option>7</option><option>8</option><option>9</option>
                                <option>10</option><option>11</option><option>12</option><option>13</option><option>14</option>
                                <option>15</option><option>16</option><option>17</option><option>18</option><option>19</option>
                                <option>20</option><option>21</option><option>22</option><option>23</option>
                            </select>
                        </div>
                        <div style="width: 1em; text-align: center;" _ngcontent-c3="" class="ngb-tp-spacer">:

                        </div>
                        <div style="width: 4em;" _ngcontent-c3="" class="ngb-tp-input-container ngb-tp-minute">
                            <input _ngcontent-c3="" disabled aria-label="Minutes" class="form-control" maxlength="2"
                                placeholder="00" type="text">
                        </div>
                    </div>
                </div>
                <div class="poll-calendar-end">
                    <ngb-datepicker formControlName="end_date" [minDate]="minDate" (click)="validateDate()"
                        [ngClass]="{ 'poll-dataInvalida': dataInvalidaEnd==true }"></ngb-datepicker>
                    <div *ngIf="dataInvalidaEnd" class="poll-message-error">End date is required</div>
                </div>
                <div *ngIf="submitted && f.end_date.errors" class="invalid-feedback">
                    <div *ngIf="f.end_date.errors.required">End Date is required</div>
                </div>
            </div>
        </div>
    </div>

    <!-- Email -->
    <div class="form-group"><label>E-mail message</label>
        <textarea placeholder="Write down the email message for the voters" autofocus="" formControlName="email"
            class="form-control"></textarea>
    </div>

    <!-- Candidates -->
    <h3 class="form-division-heading"><span class="form-step">2</span>Candidates Information</h3>
    <div class="row">
        <div formArrayName="candidates" class="form-group col-sm-3"
            *ngFor="let candidate of candidates.controls; let i=index">
            <div *ngIf="atLeastOneCandidate" class="poll-message-error">
                Please, insert at least one candidate to save as draft.
            </div>
            <div class="panel panel-default" [ngClass]="{'noCandidateError': atLeastOneCandidate}">
                <div class="panel-heading">
                    <span style="cursor: pointer; padding: 5px; color: #dc6900" class="fa fa-times pull-right"
                        *ngIf="partnerForm.controls.candidates.controls.length > 1" (click)="removeCandidate()"></span>
                </div>
                <div style="background-color:#f8f9fa; padding: 15px; border-radius: 10px;" class="panel-body"
                    [formGroupName]="i">
                    <div id="profile-container" style="margin-bottom: 50px;">
                        <img *ngIf="!photoPath[i]" class="img-thumbnail"
                            style="background:url('../../assets/img/user-default.png')">
                        <img *ngIf="photoPath[i]" class="img-thumbnail" src="{{photoPath[i]}}">
                        <div class="btn-group" role="group" style="margin-left: -25px">
                            <button type="button" class="btn btn-sm" style="background-color: #7d7d7d; color: #ffffff"
                                (click)="photoInput.click()">Browse photo <i class="fas fa-search"></i><input
                                    style="display: none" type="file" (change)="onPhotoChanged($event)"
                                    #photoInput></button>
                            <button type="button" style="background-color: #dc6900; color: #ffffff;" class="btn btn-sm"
                                (click)="onPhotoUpload()">Upload <i class="fas fa-upload"></i></button>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="text" required placeholder="Candidate Name" formControlName="candidatename"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                        <div *ngIf="submitted && f.candidates.errors" class="invalid-feedback">
                            <div *ngIf="f.candidates.errors.required">Candidate name is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <select class="form-control" formControlName="candidateLos">
                            <option disabled selected value>Select the LOS</option>
                            <option>Advisory</option>
                            <option>Assurance</option>
                            <option>Tax</option>
                            <option>IFS</option>
                            <option>TSP Office</option>
                            <option>-</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <textarea placeholder="Candidate description" formControlName="candidatedesc" autofocus
                            class="form-control"></textarea>

                    </div>

                    <label>Business Case</label>
                    <div class="form-group row">
                        <div class="col-md-3">
                            <div *ngIf="loading2">
                                <img src="../assets/img/img_loader.gif">
                            </div>
                            <div *ngIf="!loading2" class="btn-group" role="group">
                                <button type="button" class="btn btn-sm"
                                    style="background-color: #7d7d7d; color: #ffffff" (click)="fileInput.click()">Browse
                                    file <i class="fas fa-search"></i><input style="display: none" type="file"
                                        (change)="onFileChanged($event)" #fileInput></button>
                                <button type="button"
                                    style="background-color: #dc6900; color: #ffffff; margin-left: 5px"
                                    class="btn  btn-sm" (click)="onUpload()">Upload <i
                                        class="fas fa-upload"></i></button>
                            </div>

                            <div style="font-size: 10px">
                                <p>File name: {{fileName[i]}}</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="plusButton" (click)="addCandidate()">
        </div>
    </div>

    <!-- Voters -->
    <div class="form-group poll-title-section">
        <h3 class="form-division-heading"><span class="form-step">3</span>Voters Information</h3><span
            style="float: right;" class="badge badge-secondary countSelectedVoters"></span>
    </div>

    <div class="row">
        <div class="col-sm-5">
            <select name="from[]" id="multiselect" class="form-control" size="8" multiple="multiple">
                <option *ngFor="let eachVal of voters" [value]="eachVal.pwc_guid">
                    {{eachVal.name}}
                </option>
            </select>
        </div>

        <div class="col-sm-2">
            <button type="button" id="multiselect_rightAll" class="btn btn-block"><i
                    class="fas fa-forward"></i></button>
            <button type="button" id="multiselect_rightSelected" class="btn btn-block"><i
                    class="fas fa-chevron-right"></i></button>
            <button type="button" id="multiselect_leftSelected" class="btn btn-block"><i
                    class="fas fa-chevron-left"></i></button>
            <button type="button" id="multiselect_leftAll" class="btn btn-block"><i
                    class="fas fa-backward"></i></button>
        </div>

        <div class="col-sm-5">
            <div class="form-group">
                <select [ngClass]="{ 'is-invalid': submitted && f.selectedVoters.errors }" name="to[]"
                    id="multiselect_to" formControlName="selectedVoters" class="form-control" size="8"
                    multiple="multiple"></select>
                <div *ngIf="submitted && f.selectedVoters.errors" class="invalid-feedback">
                    <div *ngIf="f.selectedVoters.errors.required">Voters are required</div>
                </div>
            </div>
        </div>
    </div>

    <!-- Action Button -->
    <a href="#" class="float">
        <i class="fa fa-times my-float"></i>
    </a>
    <div class="label-container">
        <div class="label-text">Discard</div>
    </div>

    <a class="float" id="menu-share">
        <i style="color: #fff" class="fa fa-plus my-float"></i>
    </a>

    <ul>
        <li placement="left" ngbTooltip="Approve" *ngIf="user.role === 0 || user.role === 4"><a
                style="cursor: pointer; 	background-color:#e8a420;" class="btn btn-success btn-block" role="button"
                (click)="onSubmit($event)" name="approval" id="2" class="float3"><i id="2"
                    class="fa fa-check my-float"></i>
            </a></li>
        <li placement="left" ngbTooltip="Send for Approval" *ngIf="user.role === 1 || user.role === 4"><a
                style="cursor: pointer; 	background-color:#e8a420;" class="btn btn-success btn-block" role="button"
                (click)="onSubmit($event)" name="approval" id="1" class="float3"><i id="1"
                    class="fa fa-paper-plane my-float"></i>
            </a></li>
        <li placement="left" ngbTooltip="Save as Draft">
            <a style="cursor: pointer; 	background-color:#7c7d7c;" class="btn btn-success btn-block" role="button"
                (click)="onSubmit($event)" name="draft" id="0" class="float2"><i id="0" class="fa fa-save my-float"></i>
            </a>
        </li>
        <li placement="left" ngbTooltip="Cancel"><a (click)="cancel()" style="background-color:#a52727;"><i
                    class="fa fa-times my-float"></i>
            </a></li>
    </ul>
</form>

<!-- Loader / Alert -->
<div *ngIf="loader" class='overlay'>
    <div class="spinner">
        <div class="spinnerInner">
            <img src="..\assets\img\loader_old.gif">
        </div>
    </div>
</div>
<div *ngIf="error" class='overlay'>
    <div class="alert alert-danger alert-dismissible fade show"><span><i class="fas fa-check"></i></span>
        We are sorry, an error has occurred. Please contact the system administrators.
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>
<div *ngIf="success" class='overlay'>
    <div class="alert alert-success"><span><i class="fas fa-check"></i></span>
        The poll has been saved</div>
</div>