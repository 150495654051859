<div class="row" style="margin-top:30px; margin-left:100px; @media (max-width: 768px):">  
    <div class="col-sm-9 text-center" style="display:grid; justify-content: center">  
        <div class="row text-center" style="max-width: 18rem; justify-content: center; align-items: center; padding:2px">  
            <img width="150" height="100" src="assets/img/PricewaterhouseCoopers_Logo.png" />  
            <br>  
            <h1 style="margin-left:15px">Voting</h1>  
        </div>  
        <br>  
        <div class="row text-center">  
            <div class="card text-center" style="max-width: 18rem;">  
                <div class="card-header"><b>Product Owner</b></div>  
                <div class="card-body">  
                    <h5 class="card-title"> PwC</h5>  
                </div>  
            </div>  
            <div class="card text-center" style="max-width: 18rem;">  
                <div class="card-header"><b>Category</b></div>  
                <div class="card-body">  
                    <h5 class="card-title">Voting Plataform</h5>  
                </div>  
            </div>  
            <div class="card text-center" style="max-width: 18rem;">  
                <div class="card-header"><b>Release Date</b></div>  
                <div class="card-body">  
                    <h5 class="card-title">August, 2024</h5>  
                </div>  
            </div>  
        </div>  
        <br>  
        <div class="row text-center">  
            <div class="card text-center" style="min-width: 120px; max-width: 18rem;">  
                <div class="card-header"><b>Team</b></div>  
                <div class="card-body">  
                    <h5 class="card-title">PwC Technology</h5>  
                </div>  
            </div>  
            <div class="card text-center" style="max-width: 18rem;">  
                <div class="card-header"><b>Languages</b></div>  
                <div class="card-body">  
                    <h5 class="card-title">English</h5>  
                </div>  
            </div>  
            <div class="card text-center" style="max-width: 18rem;">  
                <div class="card-header"><b>Version</b></div>  
                <div class="card-body">  
                    <h5 class="card-title">2.0</h5>  
                </div>  
            </div>  
            <div class="card text-center" style="max-width: 18rem;">  
                <div class="card-header"><b>Last Update</b></div>  
                <div class="card-body">  
                    <h5 class="card-title">August, 2024</h5>  
                </div>  
            </div>  
        </div>  
        <br>   
    </div>  
  
    <div class="col-md-3 text-center" style="justify-content: center; align-items: center;margin-top:40px;">  
        <strong>Powered by</strong><br><br>  
        <br>  
        <img src="assets/img/AngularJS_logo.svg" width="120" height="60" />  
        <br>  
        <img src="assets/img/python-logo-master-v3-TM.png" width="125" height="40" />  
        <address style="margin-top:10%;font-size:small">  
            <b>PricewaterhouseCoopers Brasil Ltda.</b><br /> Avenida Brigadeiro Faria Lima, 3732<br /> São Paulo, Brasil<br />  
        </address>  
        <p style="font-size:small"> © 2024 PricewaterhouseCoopers Brasil Ltda. <br> Todos os direitos reservados.</p>  
    </div>  
</div>  
