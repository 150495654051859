import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, EMPTY, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('Intercepting request:', req);
    const token = this.authService.getToken();
    let clonedRequest = req;

    if (token) {
      if (token === 'dummy_access_token') {
        console.warn('Skipping interceptor for dummy access token in development.');
        return next.handle(clonedRequest); // Skip interceptor logic for dummy token        
      }

      console.log('Token in interceptor:', token); // Log the token value        
      clonedRequest = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + token)
      });

      try {
        const tokenExpirationTime = this.authService.parseJwtExpirationTime(token);
        console.log('Token expiration time:', tokenExpirationTime);
        const currentTime = Math.floor(new Date().getTime() / 1000);
        console.log('Current time:', currentTime);
        const thresholdTime = tokenExpirationTime - this.authService.TOKEN_REFRESH_MARGIN;

        if (currentTime >= thresholdTime) {
          console.log('Token is about to expire, refreshing...');
          return this.authService.refreshToken().pipe(
            switchMap((newToken: string) => {
              if (newToken) {
                console.log('New token after refresh:', newToken); // Log the new token value        
                clonedRequest = req.clone({
                  headers: req.headers.set('Authorization', 'Bearer ' + newToken)
                });
                return next.handle(clonedRequest);
              } else {
                console.error('Failed to refresh token.');
                this.authService.logout();
                return EMPTY;
              }
            }),
            catchError((error) => {
              console.error('Error during token refresh:', error);
              this.authService.logout();
              return throwError(error);
            })
          );
        }
      } catch (error) {
        console.error('Error parsing JWT expiration time:', error);
        this.authService.logout();
        return EMPTY;
      }
    }

    return next.handle(clonedRequest).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401 && err.error.error === 'token_expired') {
            console.warn('Token expired, logging out.');
            this.authService.logout();
            return EMPTY;
          } else if (err.status === 403) {
            console.warn('Access forbidden, redirecting to /forbidden.');
            this.router.navigate(['/forbidden']);
            return EMPTY;
          }
        }
        return throwError(err);
      })
    );
  }
}    
