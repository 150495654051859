<button type="button" class="btn btn-danger fa fa-arrow-left poll-btn-back" (click)="goBack()"></button>
<h2>Create a new {{title}} poll&nbsp;</h2>
<p class="poll-sub-title">Fill out the form below in order to create a new poll</p>

<form>
    <h3 class="form-division-heading"><span class="form-step">1</span>Basic Information</h3>
    <!-- Title -->
    <div class="form-group"><label>Title</label>
        <input type="text" placeholder="Poll title" maxlength="128" class="form-control"
            [(ngModel)]="partnerForm.name" name="name" [ngClass]="{ 'is-invalid': submitted && errors.name }" />
        <div *ngIf="submitted && errors.name" class="invalid-feedback"> Title is required </div>
    </div>

    <!-- Description -->
    <div class="form-group"><label>Description</label>
        <textarea placeholder="Poll description" class="form-control"
            [(ngModel)]="partnerForm.description" name="description"
            [ngClass]="{ 'is-invalid': submitted && errors.description }"></textarea>
        <div *ngIf="submitted && errors.description" class="invalid-feedback"> Description is required </div>
    </div>

    <!-- Date-time Begin End-->
    <div class="form-row" [ngClass]="{ 'is-invalid': startEarlierEnd || oneHour }">
        <!-- Start date -->
        <div class="col">
            <div class="form-group"><label>Start date</label>
                <div class="ngb-tp poll-datetime poll-datetime-position">
                    <div _ngcontent-c3="" class="ngb-tp-input-container ngb-tp-hour">
                        <select class="form-control" [(ngModel)]="partnerForm.begin_time" name="begin_time"
                            [ngClass]="{ 'is-invalid': submitted && errors.begin_time }">
                            <option *ngFor="let hour of validHours" [value]="hour">{{hour}}</option>
                        </select>
                    </div>
                    <div _ngcontent-c3="" class="ngb-tp-spacer poll-datetime-colon"> : </div>
                    <div _ngcontent-c3="" class="ngb-tp-input-container ngb-tp-minute poll-datetime-minute">
                        <input _ngcontent-c3="" disabled aria-label="Minutes" class="form-control" maxlength="2"
                            placeholder="00" type="text">
                    </div>
                </div>
                <div class="poll-calendar-start">
                    <ngb-datepicker [firstDayOfWeek]="0" [ngClass]="{ 'is-invalid': startRequired }"
                        [(ngModel)]="partnerForm.begin_date" [minDate]="minDate" name="begin_date">
                    </ngb-datepicker>
                    <div *ngIf="startRequired" class="poll-message-error">{{msgErr}}</div>
                </div>
            </div>
        </div>

        <!-- End date -->
        <div class="col">
            <div class="form-group"><label>End date</label>
                <div class="ngb-tp poll-datetime poll-datetime-position">
                    <div _ngcontent-c3="" class="ngb-tp-input-container ngb-tp-hour">
                        <select class="form-control" [(ngModel)]="partnerForm.end_time" name="end_time"
                            [ngClass]="{ 'is-invalid': submitted && errors.end_time }">
                            <option *ngFor="let hour of validHours" [value]="hour">{{hour}}</option>
                        </select>
                    </div>
                    <div _ngcontent-c3="" class="ngb-tp-spacer poll-datetime-colon"> : </div>
                    <div _ngcontent-c3="" class="ngb-tp-input-container ngb-tp-minute poll-datetime-minute">
                        <input _ngcontent-c3="" disabled aria-label="Minutes" class="form-control" maxlength="2"
                            placeholder="00" type="text">
                    </div>
                </div>
                <div class="poll-calendar-end" [ngClass]="{ 'is-invalid': submitted && errors.end_date }">
                    <ngb-datepicker [firstDayOfWeek]="0" [(ngModel)]="partnerForm.end_date" [minDate]="minDate" 
                        name="end_date" [ngClass]="{ 'is-invalid': endRequired }"></ngb-datepicker>
                    <div *ngIf="endRequired" class="poll-message-error">{{msgErr}}</div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="startEarlierEnd || oneHour" class="poll-message-error">{{msgErr}}</div>

    <!-- Email  -->
    <div class="form-group"><label>E-mail message</label>
        <textarea placeholder="Write down the email message for the voters" autofocus=""
            [(ngModel)]="partnerForm.email" class="form-control" name="email"
            [ngClass]="{ 'is-invalid': submitted && errors.email }"></textarea>
        <div *ngIf="submitted && errors.email" class="invalid-feedback"> Email Message is required </div>
    </div>

    <!-- Candidates -->
    <h3 class="form-division-heading"><span class="form-step">2</span>Candidates Information</h3>
    <div class="row">
        <div class="form-group col-sm-3" *ngFor="let candidate of partnerForm.candidates; let i=index">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <span class="fa fa-times pull-right poll-remove-candidate"
                        (click)="removeCandidate(i)"></span>
                </div>
                <div class="panel-body poll-card-candidate">
                    <div id="profile-container">
                        <img *ngIf="candidate.photo == ''" class="img-thumbnail poll-no-img-candidate">                            
                        <img *ngIf="candidate.photo != ''" class="img-thumbnail" src="{{candidate.photoBytes}}">
                    </div>
                    <br><br>
                    <input type="text" placeholder="Candidate Name"
                        [value]="candidate.candidatename" class="form-control" disabled />
                    <br><br>
                    <select class="form-control" [value]="candidate.candidateLos" disabled>
                        <option disabled selected value>Select the LOS</option>
                        <option>Advisory</option>
                        <option>Assurance</option>
                        <option>Tax</option>
                        <option>IFS</option>
                        <option>TSP Office</option>
                        <option>-</option>
                    </select>
                    <br><br>
                    <textarea placeholder="Candidate description" [value]="candidate.candidatedesc" autofocus
                        class="form-control" disabled></textarea>
                    <button type="button" *ngIf="candidate.businesscase != ''"
                        (click)="getDownload(candidate.fName,candidate.businesscase)" class="btn poll-btn-download-business-case">
                        Download Business Case <i class="fa fa-download"></i>
                    </button>
                </div>
            </div>
        </div>
        <!-- Add Candidates -->
        <div class="form-group col-sm-3">
            <div class="panel panel-default" [ngClass]="{ 'is-invalid': submitted && errors.candidates }">
                <div class="panel-body poll-card-candidate">
                    <div id="profile-container">
                        <img *ngIf="newCandidate.photo.name == 'File'" class="img-thumbnail poll-no-img-candidate">
                        <img *ngIf="newCandidate.photo.name != 'File'" class="img-thumbnail"
                            src="{{newCandidate.photoBytes}}">
                        <button type="button" class="btn btn-sm poll-btn-new-photo" (click)="photoInput.click()">
                            New photo <i class="fa fa-search"></i>
                            <input class="poll-btn-display" type="file" (change)="onPhotoChanged($event)" #photoInput>
                        </button>
                    </div>
                    <input type="text" placeholder="Candidate Name" maxlength="128"
                        [(ngModel)]="newCandidate.candidatename" class="form-control" name="candidatename" />
                    <select class="form-control" [(ngModel)]="newCandidate.candidateLos"
                        name="candidatelos">
                        <option disabled selected value>Select the LOS</option>
                        <option>Advisory</option>
                        <option>Assurance</option>
                        <option>Tax</option>
                        <option>IFS</option>
                        <option>TSP Office</option>
                        <option>-</option>
                    </select>
                    <textarea placeholder="Candidate description" maxlength="255"
                        [(ngModel)]="newCandidate.candidatedesc" autofocus class="form-control"
                        name="candidatedesc"></textarea>
                    <button type="button" *ngIf="newCandidate.businesscase != ''"
                        (click)="getDownload(newCandidate.fName, newCandidate.businesscase)" class="btn poll-btn-download-business-case">
                        Download Business Case <i class="fa fa-download"></i>
                    </button>
                    <button type="button" class="btn btn-sm poll-btn-new-photo" (click)="fileInput.click()">
                        New Business Case File <i class="fa fa-search"></i><input class="poll-btn-display" type="file"
                            (change)="onFileChanged($event)" #fileInput>
                    </button>
                    <hr>
                    <div *ngIf="newCandidate.candidatename != '' && newCandidate.candidateLos != '' &&
                                (newCandidate.uploadComplete || newCandidate.businesscase == '')">
                        <button class="btn poll-btn poll-btn__btn-confirm-candidate" type="button"
                            (click)="addCandidate()">Confirm candidate</button>
                    </div>
                    <div *ngIf="newCandidate.candidatename == '' || newCandidate.candidateLos == ''">
                        <button class="btn poll-btn-confirm-candidate" type="button">Confirm candidate</button>
                    </div>

                </div>
            </div>
            <div *ngIf="submitted && errors.candidates" class="invalid-feedback candidates"> 
                At least one Candidate is required 
            </div>
        </div>
    </div>

    <!-- Groups  -->
    <form [formGroup]="tobForm" (ngSubmit)="onSubmit($event, '')">
        <div class="form-group">
            <h3 class="form-division-heading"><span class="form-step">3</span>Select Groups</h3>
        </div>
        <div [ngClass]="{ 'poll-groupError poll-groupError__dropdown': groupError }">
            <ng-multiselect-dropdown
                [placeholder]="'Select Groups'"
                [settings]="groupSettings"
                [data]="pollDataGroups"
                [(ngModel)]="selectedGroupItems"        
                (onSelect)="onGroupItemSelect($event)"
                (onDeSelect)="onGroupItemDeSelect($event)"
                (onSelectAll)="onGroupSelectAll($event)"
                (onDeSelectAll)="onGroupDeSelectAll($event)"
                formControlName="selectedGroups">
            </ng-multiselect-dropdown>
            <div *ngIf="groupError" class="poll-message-error">
                Select Groups
            </div>
        </div>
        <br>
    </form>

    <!-- Voters -->
    <div class="form-group poll-title-section">
        <h3 class="form-division-heading"><span class="form-step">4</span>Voters Information</h3>
        <span class="badge poll-total-count">{{countVoters}} voters selected</span>
    </div>
    <div class="row">
        <div [ngClass]="{ 'poll-voterError': submitted && errors.selectedVoters }">
            <a class="btn poll-btn" (click)="loadVoters()">
                <i class="fa fa-sync"></i>Load Voters
            </a>
            <div *ngIf="submitted && errors.selectedVoters" class="poll-message-error">
                Voters are required
            </div>
        </div>

        <div class="col-sm-12" *ngIf="loadingVoter">
            <div class="spinner">
                <div class="spinnerInner">
                    <img src="..\assets\img\loader_old.gif">
                </div>
            </div>
        </div>

        <div [hidden]="!countShowVoterTable" class="col-sm-12">
            <table id="tabelaVoters" datatable class="table  table-bordered table-hover">
                <thead>
                    <tr>
                        <th>Partner Name</th>
                    </tr>
                </thead>
                <tbody class="poll-content-list">
                </tbody>
            </table>
        </div>
    </div>

    <!-- Action Button -->
    <a href="#" class="float">
        <i class="fa fa-times my-float"></i>
    </a>
    <a class="float" id="menu-share">
        <i class="fa fa-plus my-float my-float__btn-plus"></i>
    </a>
    <ul *ngIf="!saving">
        <li placement="left" ngbTooltip="Approve" *ngIf="user.role === 0 || user.role === 4">
            <a class="poll-btn-send poll-btn-send__btn-approved" role="button" (click)="onSubmit($event, 'Approved')" name="approval" id="2">
                <i id="2"class="fa fa-check my-float"></i>
            </a>
        </li>
        <li placement="left" ngbTooltip="Send for Approval" *ngIf="user.role === 1 || user.role === 4">
            <a class="poll-btn-send poll-btn-send__btn-pending-approval" role="button" (click)="onSubmit($event, 'PendingApproval')" name="approval" id="1">
                <i id="1"class="fa fa-paper-plane my-float"></i>
            </a>
        </li>
        <li placement="left" ngbTooltip="Save as Draft">
            <a class="poll-btn-send poll-btn-send__btn-draft" role="button" (click)="onSubmit($event, 'Draft')" name="draft" id="0">
                <i id="0" class="fa fa-save my-float"></i>
            </a>
        </li>
        <li placement="left" ngbTooltip="Cancel">
            <a class="poll-btn-send poll-btn-send__btn-cancel" (click)="cancel()" >
                <i class="fa fa-times my-float"></i>
            </a>
        </li>
    </ul>
</form>

<!-- Loader/ Alert-->
<div *ngIf="loader" class='overlay'>
    <div class="spinner">
        <div class="spinnerInner">
            <img src="..\assets\img\loader_old.gif">
        </div>
    </div>
</div>