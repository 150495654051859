<h2>Group settings</h2>
<div class="row form-group">
    <div class="col-lg-1">
        <label for="id">Id</label>
        <span class="form-control" id="id">{{item.id}}</span>
    </div>
    <div class="col-lg-3">
        <label for="guid">Group name</label>
        <input type="text" class="form-control" id="guid" [(ngModel)]="item.groupname" 
            [class.is-invalid]="isNameInvalid" maxlength="128"/>
        <div *ngIf="isNameInvalid" class="invalid-feedback">
            Group name is required.
        </div>
    </div>
    <div class="col-lg-1">
        <label for="active">Enabled?</label>
        <input type="checkbox" checked="checked" class="form-control enabled-field" id="active" [(ngModel)]="item.active" />
    </div>
</div>
<br/>

<div class="row form-group">
    <div class="col-lg-2 offset-8">
        <a class="btn btn-block btn-secondary btn-cancel" (click)="cancel()">Cancel</a>
    </div>
    <div class="col-lg-2">
        <button class="btn btn-block btn-save" (click)="save()">Save</button>
    </div>
</div>

<!-- Loader/ Alert-->
<div *ngIf="loader" class='overlay'>
    <div class="spinner">
        <div class="spinnerInner">
            <img src="..\assets\img\loader_old.gif">
        </div>
    </div>
</div>