export enum CodError {
    StartRequired = 1,
    EndRequired = 2,
    StartEarlierEnd = 3,
    OneHour = 4,
  }
  
  export enum MsgError {
    StartRequired = 'Start Date/Time is required',
    EndRequired = 'End Date/Time is required',
    StartEarlierEnd = 'Start Date/Time must be earlier than End Date/Time',
    OneHour = 'Poll must be at least 1 hour long',
  }
  