import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {BehaviorSubject} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BookingService {
    totalTicketCount: BehaviorSubject<string> = new BehaviorSubject<string>('');

    sendValue(val: string) {
        console.log('sending value');
        this.totalTicketCount.next(val);
    }

    getValue() {
        return this.totalTicketCount.value;
    }
}
