<div class="content" *ngIf="pollData">
    <div class="container">

        <div class="card" *ngIf="generic">
            <div class="card-body">
                <p class="text-right float-right poll-details-date">This poll will end on: {{pollData.end_date | dateTimeFormatFilter : "MMM DD, YYYY HH:mm"}}</p>
                <h4 class="card-title">{{pollData.name}}<br></h4>
                <p class="card-text">{{pollData.description}}&nbsp;<br></p>
                <p>
                    <button type="button" *ngIf="link.length > 0" class="btn btn-light float-right btn-download"
                        (click)="getDownload(fName, link)">Download <i class="fa fa-cloud-download"></i>
                    </button>
                </p><br><br>
                <p *ngIf="link.length > 0">Check the attachment and inform your vote by clicking "Yes" or "No"</p>
                <p *ngIf="link.length == 0">Inform your vote by clicking "Yes" or "No"</p>
                <div class="card-footer d-lg-flex justify-content-lg-center">
                    <div class="d-lg-flex justify-content-lg-center align-items-lg-center poll-btn-vote-generic-board-tsp">
                        <a class="btn btn-success btn-block" role="button" (click)="onSubmit('Y')" id="Y">YES</a>
                        <h5 class="d-flex float-none d-lg-flex justify-content-lg-center align-items-lg-center voting-or">
                            &nbsp;or&nbsp;
                        </h5>
                        <a class="btn btn-danger btn-block" role="button" (click)="onSubmit('N')" id="N">NO</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="card" [hidden]="!boardTsp">
            <form [formGroup]="tobVotingForm" (ngSubmit)="onSubmit($event)">
                <div class="card-body">
                    <p class="text-right float-right poll-details-date">This poll will end on: {{pollData.end_date | dateTimeFormatFilter : "MMM DD, YYYY HH:mm"}}</p>
                    <h4 class="card-title">{{pollData.name}}<br></h4>
                    <h6 class="text-muted card-subtitle mb-2">Vacancies: {{pollData.vacancy}}</h6>
                    <p class="card-text">{{pollData.description}}&nbsp;<br></p>
                    <span class="badge total-count-candidates">{{countSelectedCandidates}} candidates selected</span>
                    <p>Click on the checkbox and select the candidate of your choice. Repeat until at least one vancacie is filled and 
                        the number of candidates does not exceed the number of vancacies. At the end, click on the VOTE button</p>
                    <ng-multiselect-dropdown 
                        [placeholder]="'Select Candidates'" 
                        [settings]="settings"
                        [data]="pollDataCandidates" 
                        [(ngModel)]="selectedItems" 
                        (onSelect)="onItemSelect($event)"
                        (onDeSelect)="onItemDeSelect($event)" 
                        (onSelectAll)="onSelectAll($event)"
                        (onDeSelectAll)="onDeSelectAll($event)" 
                        formControlName="candidates">
                    </ng-multiselect-dropdown>
                </div>
                <div class="card-footer d-lg-flex justify-content-lg-center">
                    <div class="d-lg-flex justify-content-lg-center align-items-lg-center poll-btn-vote-generic-board-tsp">
                        <button class="btn btn-success btn-block btn-vote" type="button" id="voteTob"
                            [disabled]="allowVote" (click)="onSubmit($event)">Vote</button>
                    </div>
                </div>
            </form>
        </div>

        <div class="card" *ngIf="partner">
            <p class="text-right float-right poll-details-date">This poll will end on: {{pollData.end_date | dateTimeFormatFilter : "MMM DD, YYYY HH:mm"}}</p>
            <h4>{{pollData.name}}</h4>
            <p class="card-text">{{pollData.description}}</p><br>
            <h5 *ngIf="!apartner">Vote for the partner candidates below</h5>
            <h5 *ngIf="apartner">Vote for the associate partner candidates below</h5>
            <p>To make your option, click: on YES or NO button for each candidate name and then on Vote button</p>
            <form (ngSubmit)='onSubmitVotes(f)' #f="ngForm">
                <div class="card-columns-2">
                    <div class="card poll-card-candidate-width" *ngFor="let candidate of pollData.candidates; let i = index">
                        <img height="250" width="250" *ngIf="!nullPhoto[i]" class="card-img-top w-100 d-block"
                            src={{photoBytes[i]}}>
                        <div *ngIf="nullPhoto[i]"><img class="card-img-top w-100 d-block"
                                src="../../assets/img/user-default.png"></div>
                        <div class="card-body">
                            <h4 class="card-title">{{candidate.candidatename}}</h4>
                            <h6 class="card-text">{{candidate.candidateLos}}</h6>
                            <p class="card-text">{{candidate.candidatedesc}}&nbsp;
                                <br><br>
                                <button type="button" *ngIf="!nullFile[i]"
                                    class="btn btn-light float-right poll-btn-download-business-case2"
                                    (click)="getDownloadBusinessCase(candidate.attachments[1].name, candidate.attachments[1].link)">
                                    Download Business Case <i class="fa fa-cloud-download"></i>
                                </button>
                                <button type="button" *ngIf="nullFile[i]" class="disabled btn btn-light float-right poll-btn-no-business-case">
                                    No Business Case Attached <i class="fa fa-cloud-download"></i>
                                </button>
                            </p>
                            <br><br>
                            <div class="d-lg-flex justify-content-lg-center align-items-lg-center">
                                <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic">
                                    <label ngbButtonLabel class="yes btn-success">
                                        <input ngbButton type="radio" ngModel [name]="candidate.id" required value="Y">YES
                                    </label>
                                    <h5
                                        class="d-flex float-none d-lg-flex justify-content-lg-center align-items-lg-center voting-or">
                                        &nbsp;or&nbsp;</h5>
                                    <label ngbButtonLabel class="no btn-danger">
                                        <input ngbButton type="radio" ngModel [name]="candidate.id" required value="N">NO
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button [disabled]="!f.valid" class="btn btn-success btn-block btn-vote" type="submit" role="button">Vote</button>
            </form>
        </div>
        
    </div>

    <!-- Loader/ Alert-->
    <div *ngIf="success" class='overlay'>
        <div class="alert alert-success">
            <button class="btn btn-success">Do you confirm your vote?</button>
        </div>
    </div>
    <div *ngIf="saving" class='overlay'>
        <div class="spinner">
            <div class="spinnerInner">
                <img src="..\assets\img\loader_old.gif">
            </div>
        </div>
    </div>
</div>