import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Profile } from 'src/assets/profile.enum';

@Injectable({
    providedIn: 'root'
})
export class PersonService {

    private url: string = environment.apiEndpoint + 'user';

    constructor(private http: HttpClient) { }

    public getAll(): Observable<any> {
        return this.http.get(this.url);
    }

    public getProfiles(): any[] {
        return Object
            .keys(Profile)
            .filter((type) => isNaN(<any>type))
            .map(key => ({
                id: Profile[key as keyof typeof Profile],
                value: key
            }));
    }

    public get(id: any): Observable<any> {
        return this.http.get(this.url, { params: { 'id': id } });
    }

    public save(person: any): Observable<any> {
        return this.http.post(this.url, person);
    }

    public update(person: any): Observable<any> {
        return this.http.put(this.url, person);
    }

    public upload(file: File): Observable<any> {
        let requestItens = new FormData();
        requestItens.append('file', file);
        return this.http.put(this.url + '/upload', requestItens);
    }
}
