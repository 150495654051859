import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { DateUtil } from '../utils/date.util';
import { CodError } from '../utils/date.enum';
import { MsgError } from '../utils/date.enum';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import * as moment from 'moment';
import { AuthService } from '../auth.service';
import { User } from '../interfaces/user';
import { Router } from '@angular/router';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { AttachmentService } from '../services/attachment.service';
import * as _ from 'lodash';
import swal from 'sweetalert2';
declare var $: any;
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Dictionary } from 'lodash';
import { PollType } from '../utils/poll-type.enum';
import { PollSaveType } from '../utils/poll-save-type.enum';

@Component({
  selector: 'app-edit-poll-board',
  templateUrl: './edit-poll-board.component.html',
  styleUrls: ['./edit-poll-board.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(1000)),
    ]),
  ]
})


export class EditPollBoardComponent implements OnInit {

  //#region Variables
  model = { year: 2017, month: 8, day: 8 };
  public candidates: any = Object;
  public poll_detail: any =
  {
    "name": "",
    "saveType": "",
    "id": "",
    "description": "",
    "created_by_pwc_guid": "",
    "subtitle": "",
    "title": "",
    "created_date": "",
    "begin_date": "",
    "end_date": "",
    "pollType": ""
  };
  minDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
  dayTemplate = { year: 2020, month: 8, day: 1 };
  selectedCandidates: any[] = [];
  selectedVoters: any[] = [];
  countSelectedVoters = 0;
  voters: any = Object;
  pollTitle: string;
  pollType: string;
  choosePollType = true;
  editPollForm: FormGroup;
  tobForm: FormGroup;
  submitted = false;
  candidadeLos: any;
  todayDate = new Date();
  partner = false;
  tob = false;
  report = false;
  user: User;
  loader = false;
  error = false;
  success = false;
  requiredFields = false;
  dataInvalida = false;
  dataInvalidaEnd = false;
  startDate = '2020/01/01';
  filePath: any[] = [];
  fileName: any[] = [];
  filePathReport: string;
  filePathReportName: string;
  selectedFile: File;
  selectedPhoto: File;
  photoPath: any[] = [];
  null: any[] = [];
  type: string;
  candidatesTob: any;
  countCandidates = 0;
  countVoters = 0;
  errors: any = {};
  loadingVoter = false;
  voterError = false;
  loadingCandidate = false;
  countCandidate = 0;
  candidateError = false;
  candidateErrorTsp = false;
  selectedItems: any;
  settings = {};
  pollDataCandidates: Object;
  countSelectedCandidates = 0;
  saving = false;
  startRequired = false;
  endRequired = false;
  startEarlierEnd = false;
  oneHour = false;
  msgErr: string;
  groupError = false;
  selectedGroupItems: any[] = [];
  selectedGroups: any[] = [];
  groupSettings = {};
  pollDataGroups: any;
  countSelectedGroups = 0;
  countShowVoterTable = 0;
  countShowCandidateTable = 0;
  approver_message: string;
  tsp: PollType = PollType.TSP;
  board: PollType = PollType.BOARD;
  carryCandidateTsp: boolean = false;
  approved: PollSaveType = PollSaveType.Approved;
  //#endregion Variables

  constructor(private router: Router, private dataService: DataService, private dateUtil: DateUtil, private route: ActivatedRoute,
    private location: Location, private formBuilder: FormBuilder, private auth: AuthService, private calendar: NgbCalendar,
    private attachmentService: AttachmentService) {
  }

  // convenience getter for easy access to form fields
  get f() {

    if ((this.tobForm.controls.begin_date.value == 'Sat Jan 01 2000 00:00:00 GMT-0200 (Brasilia Summer Time)')
      || (this.tobForm.controls.begin_date.value == null)) {
      this.tobForm.controls['begin_date'].setValue(null);
      this.dataInvalida = true;
    } else {
      this.dataInvalida = false;
    }

    if ((this.tobForm.controls.end_date.value == 'Sat Jan 01 2000 00:00:00 GMT-0200 (Brasilia Summer Time)')
      || (this.tobForm.controls.end_date.value == null)) {
      this.tobForm.controls['end_date'].setValue(null);
      this.dataInvalidaEnd = true;
    } else {
      this.dataInvalidaEnd = false;
    }
    return this.tobForm.controls;
  }

  confirmSavedPoll() {
    this.loader = false;
    this.success = true;
    setTimeout(() => {
      this.router.navigate(['/admin']);
    }
      , 2000);
  }

  pollErrorMessage() {
    this.loader = false;
    this.error = true;
    setTimeout(() => {
      this.router.navigate(['/admin']);
    }
      , 2000);
  }

  //#region Action Buttons
  back() {
    window.history.back();
  }

  cancel() {
    swal.fire({
      title: 'Are you sure?',
      text: "All information will be lost.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dc6900',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, I am sure.'
    }).then((result: any) => {
      if (result.value) {
        this.router.navigate(['/admin']);
      }
    });
  }

  onSubmit(saveType: Event) {
    this.saving = true;
    this.selectedVoters = _.map(this.voters, 'pwc_guid');

    if (this.type != PollType[this.tsp])
      this.selectedCandidates = _.map(this.candidates, 'pwc_guid');    
    else{
      if (this.carryCandidateTsp == false)
        this.selectedItems = this.selectedItems.filter((item: any) => !('email' in item));
      
      this.selectedCandidates = this.selectedItems;
    }

    let saveTypeId: number = Number((event.target as Element).id);    
    this.tobForm.controls['selectedGroups'].clearValidators();
    this.tobForm.controls['selectedGroups'].updateValueAndValidity();

    if (saveTypeId === PollSaveType.Draft)
      this.clearValidators();
    else{
      if(!this.validateDates()) return;
      if(!this.validateGroups()) return;

      if(this.type != PollType[this.tsp]){
        if(!this.validateCandidates()) return;
      }
      else{
        if(!this.validateCandidatesTsp()) return; 
      }

      if(!this.validateVoters()) return;
      if(saveTypeId === PollSaveType.Approved) this.approver_message = PollSaveType[this.approved];      
    }

    this.tobForm.controls['saveType'].setValue(saveTypeId);
    this.tobForm.controls['selectedVoters'].setValue(this.selectedVoters);
    this.tobForm.controls['selectedCandidates'].setValue(this.selectedCandidates);
    this.tobForm.controls['selectedGroups'].setValue(this.selectedGroups);
    this.tobForm.controls['pollType'].setValue(this.poll_detail.pollType);
    this.tobForm.controls['id'].setValue(this.poll_detail.id);
    this.tobForm.controls['current_user'].setValue(this.user.guid);
    this.submitted = true;
    this.tobForm.controls['approver_message'].setValue(this.approver_message);
    
     // stop here if form is invalid
    if (this.tobForm.invalid) {
      // this.submitted = false;
      // this.requiredFields = true;
      this.saving = false;
      window.scroll(0, 0);
      return false;
    }
    if (this.tobForm.value.begin_date != '' && this.tobForm.value.begin_date != undefined) {
      this.tobForm.value.begin_date = this.dateUtil.transformToDate(this.tobForm.value.begin_date, this.tobForm.value.begin_time)
      
      if (this.tobForm.value.begin_date == 'Invalid Date') {
        this.tobForm.value.begin_date = null;
      } else {
        this.tobForm.value.begin_date.toUTCString();
        this.tobForm.controls['begin_date'].setValue(this.tobForm.value.begin_date);
      }
    } else {
      this.tobForm.value.begin_date = null;
      this.tobForm.controls['begin_date'].setValue(null);
    }

    if (this.tobForm.value.end_date != '' && this.tobForm.value.end_date != undefined) {
      this.tobForm.value.end_date = this.dateUtil.transformToDate(this.tobForm.value.end_date, this.tobForm.value.end_time)
      
      if (this.tobForm.value.end_date == 'Invalid Date') {
        this.tobForm.value.end_date = null;
      } else {
        this.tobForm.value.end_date.toUTCString();
        this.tobForm.controls['end_date'].setValue(this.tobForm.value.end_date);
      }
    } else {
      this.tobForm.value.end_date = null;
      this.tobForm.controls['end_date'].setValue(null);
    }

    this.loader = true;
    this.dataService.updatePoll(this.tobForm.value)
      .pipe(first())
      .subscribe(
        data => swal.fire({ title: 'Success!', text: 'The poll has been saved.', icon: 'success', confirmButtonColor: '#dc6900' })
          .then(() => {
            this.router.navigate(['/admin']);
          }),
          error => swal.fire({
          title: 'Oops...',
          html: '<p>We are sorry, an error has occurred. Please contact the system administrators.</p>' +
            '<br><div class="alert alert-danger" role="alert"><p> Error: </p><br>' + error.error.message + '<br><br>'
            + error.message + '</div>',
          icon: 'error', confirmButtonColor: '#dc6900'
          })
          .then(() => {
            this.router.navigate(['/admin']);
          }));
  }
  //#endregion Action Buttons


  //#region Candidates
  addCandidatesTsp(pwc_guid: string){    
    let candidate: any = Object;
    this.dataService.getPartner(pwc_guid).subscribe(data => {
      candidate = data;
      this.selectedItems.push({'id': candidate.id, 'name': candidate.name})      
    });
    return this.selectedItems    
  }

  loadCandidates() {
    if (this.countSelectedGroups == 0){      
      this.groupError = true
      return    
    }

    if(this.type == PollType[this.tsp]) {
      this.loader = true;
      this.carryCandidateTsp = true;
    }

    this.loadingCandidate = true;
    this.dataService.getPartnersGroup(this.selectedGroupItems).subscribe(data => {
      this.loadingCandidate = false;
      this.loader = false;
      this.candidates = data as Dictionary<never>;
      this.pollDataCandidates = data;
      this.selectedItems = [];
      this.candidateError = false;
      this.countCandidate = Object.keys(this.candidates).length;

      if (this.type != PollType[this.tsp]) {
        this.countSelectedCandidates = this.countCandidate
        $('#tabelaCandidates').DataTable().clear().draw();

        $.each(data,
          function (key: any, item: any) {
            $("#tabelaCandidates").DataTable().row.add([
              item.name
            ]).draw();
          });
        this.countShowCandidateTable++;
      }
      else
        this.countSelectedCandidates = 0;
    });
  }

  onDeSelectAll(items: any) {
    this.countSelectedCandidates = 0;
  }

  onItemDeSelect(item: any) {
    this.countSelectedCandidates--;
  }

  onItemSelect(item: any) {
    this.countSelectedCandidates++;
    this.candidateErrorTsp = false;
  }  

  onSelectAll(items: any) {
    this.countSelectedCandidates = items.length
    this.candidateErrorTsp = false;
  }

  selectCandidate(){
    this.settings = {
            singleSelection: false,
            itemsShowLimit: 5,
            enableSearchFilter: false,
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            classes: "myclass custom-class-example",
            idField: 'id',
            textField: 'name',
            labelKey: 'name',
        };
  }
  //#endregion Candidates


  //#region Clear Fields
  clearValidators(){
    this.tobForm.controls['name'].clearValidators();
    this.tobForm.controls['name'].updateValueAndValidity();

    this.tobForm.controls['description'].clearValidators();
    this.tobForm.controls['description'].updateValueAndValidity();

    this.tobForm.controls['begin_date'].clearValidators();
    this.tobForm.controls['begin_date'].updateValueAndValidity();

    this.tobForm.controls['begin_time'].clearValidators();
    this.tobForm.controls['begin_time'].updateValueAndValidity();

    this.tobForm.controls['end_date'].clearValidators();
    this.tobForm.controls['end_date'].updateValueAndValidity();

    this.tobForm.controls['end_time'].clearValidators();
    this.tobForm.controls['end_time'].updateValueAndValidity();

    this.tobForm.controls['email'].clearValidators();
    this.tobForm.controls['email'].updateValueAndValidity();

    this.tobForm.controls['selectedVoters'].clearValidators();
    this.tobForm.controls['selectedVoters'].updateValueAndValidity();

    this.tobForm.controls['selectedCandidates'].clearValidators();
    this.tobForm.controls['selectedCandidates'].updateValueAndValidity();

    this.tobForm.controls['vacancy'].clearValidators();
    this.tobForm.controls['vacancy'].updateValueAndValidity();
  }
  //#endregion Clear Fields


  //#region Groups
  onGroupItemSelect(item: any) {
    this.countSelectedGroups++;
    this.groupError = false;
  }

  onGroupItemDeSelect(item: any) {
    this.countSelectedGroups--;
  }

  onGroupSelectAll(items: any) {
    this.countSelectedGroups = items.length;    
    this.groupError = false;
  }

  onGroupDeSelectAll(items: any) {
    this.countSelectedGroups = 0;
  }

  selectGroup() {
    this.dataService.getGroups().subscribe(data => {
      this.pollDataGroups = data;
    });

    this.groupSettings = {
      singleSelection: false,
      itemsShowLimit: 5,
      enableSearchFilter: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      classes: "myclass custom-class-example",
      idField: 'id',
      textField: 'groupname',
      labelKey: 'groupname',
    };
  }
  //#endregion Groups


  //#region OnInit
  public ngOnInit() {
    this.selectCandidate();
    this.selectGroup();
    this.user = this.auth.getUserInfo();
    this.getDetail();
    this.validateForm();
  }
  //#endregion OnInit


  //#region Load
  getDetail() {
    const id = this.route.snapshot.paramMap.get('id');
    this.dataService.getPoll(id).subscribe(data => {
      this.poll_detail = data;
      this.poll_detail.begin_time = moment(this.poll_detail.begin_date).format('HH');

      if (this.poll_detail.begin_time == 'Invalid date')
        this.poll_detail.begin_time = '03';      

      this.poll_detail.end_time = moment(this.poll_detail.end_date).format('HH');
      
      if (this.poll_detail.end_time == 'Invalid date')
        this.poll_detail.end_time = '03';      

      this.poll_detail.begin_date = moment(this.poll_detail.begin_date).format('YYYY-MM-DD');
      this.poll_detail.end_date = moment(this.poll_detail.end_date).format('YYYY-MM-DD');
      this.countCandidates = this.poll_detail.candidates.length;
      this.countSelectedCandidates = this.countCandidates;
      this.countVoters = this.poll_detail.voters.length;
      this.voters = _.map(this.poll_detail.voters, function (item) {
        return {
          id: item.id, name: item.name, email: item.email, active: item.active, created_at: item.created_at,
          password: item.password, profile: item.profile,
          pwc_guid: item.guid, pwc_number: item.pwc_number, updated_at: item.updated_at
        };
      });
      $('#tabelaVoters').DataTable().clear().draw();

      $.each(this.voters,
        function (key: any, item: any) {
          $("#tabelaVoters").DataTable().row.add([
            item.name
          ]).draw();
        });
      this.countShowVoterTable++;

      if (this.poll_detail.pollType == 1) {
        this.type = PollType[this.tsp];
        this.candidatesTob = this.poll_detail.candidates;
        this.candidatesTob = _.map(this.candidatesTob, function (item) {
          return {
            id: item.id, name: item.candidatename, email: item.email, active: item.active, created_at: item.created_at,
            password: item.password, profile: item.profile,
            pwc_guid: item.user_pwc_guid, pwc_number: item.pwc_number, updated_at: item.updated_at
          };
        });

        for(let candidate of this.candidatesTob)
          this.selectedItems = this.addCandidatesTsp(candidate.pwc_guid)
        
        this.selectedItems = this.candidatesTob;
        this.countCandidate = this.candidatesTob.length;
      } 
      else {
        this.type = PollType[this.board];
        this.candidates = this.poll_detail.candidates;                
        this.candidates = _.map(this.candidates, function (item) {
          return {
            id: item.id, name: item.candidatename, email: item.email, active: item.active, created_at: item.created_at,
            password: item.password, profile: item.profile,
            pwc_guid: item.user_pwc_guid, pwc_number: item.pwc_number, updated_at: item.updated_at
          };
        });

        $('#tabelaCandidates').DataTable().clear().draw();
        $.each(this.candidates,
          function (key: any, item: any) {
            $("#tabelaCandidates").DataTable().row.add([
              item.name
            ]).draw();
          });
        this.countShowCandidateTable++;
      }

      this.tob = true;
      this.tobForm.controls['name'].setValue(this.poll_detail.name);
      this.tobForm.controls['description'].setValue(this.poll_detail.description);

      if (this.poll_detail.begin_date != null && this.poll_detail.begin_date != 'Invalid date')
        this.tobForm.controls['begin_date'].setValue(this.dateUtil.convertDateTime(this.poll_detail.begin_date.concat("T", this.poll_detail.begin_time, ":00:00"), false));
      else
        this.tobForm.controls['begin_date'].setValue(null);
      
      if (this.poll_detail.end_date != null && this.poll_detail.end_date != 'Invalid date')      
        this.tobForm.controls['end_date'].setValue(this.dateUtil.convertDateTime(this.poll_detail.end_date.concat("T", this.poll_detail.end_time, ":00:00"), false));
      else
        this.tobForm.controls['end_date'].setValue(null);

      this.tobForm.controls['selectedVoters'].setValue(this.poll_detail.voters);
      this.tobForm.controls['selectedCandidates'].setValue(this.poll_detail.candidates);
      this.tobForm.controls['pollType'].setValue(this.poll_detail.pollType);
      this.tobForm.controls['email'].setValue(this.poll_detail.email);
      this.tobForm.controls['vacancy'].setValue(this.poll_detail.vacancy);
      this.tobForm.controls['begin_time'].setValue(this.poll_detail.begin_date == 'Invalid date' ? 0 : 
                                                    this.dateUtil.convertDateTime(this.poll_detail.begin_date.concat("T", this.poll_detail.begin_time, ":00:00"), true));
      this.tobForm.controls['end_time'].setValue(this.poll_detail.end_date == 'Invalid date' ? 0 : 
                                                  this.dateUtil.convertDateTime(this.poll_detail.end_date.concat("T", this.poll_detail.end_time, ":00:00"), true));      
      this.tobForm.controls['current_user'].setValue(this.poll_detail.created_by_pwc_guid);
    });    
  }
  //#endregion Load


  //#region Validations
  validateDates(){
    this.startRequired = false;
    this.endRequired = false;
    this.startEarlierEnd = false;
    this.oneHour = false;
      let model = {
      beginDate: this.tobForm.value.begin_date, endDate: this.tobForm.value.end_date
      , beginTime: this.tobForm.value.begin_time, endTime: this.tobForm.value.end_time, codError: 0, msgError: ""
    };

    if (!this.dateUtil.validateDateTime(model)) {
      this.startRequired = model.codError == CodError.StartRequired ? true : false;
      this.endRequired = model.codError == CodError.EndRequired ? true : false;
      this.startEarlierEnd = model.codError == CodError.StartEarlierEnd ? true : false;
      this.oneHour = model.codError == CodError.OneHour ? true : false;
      if (this.startRequired) {
        this.msgErr = MsgError.StartRequired;
      }
      else if (this.endRequired) {
        this.msgErr = MsgError.EndRequired;
      }
      else if (this.startEarlierEnd) {
        this.msgErr = MsgError.StartEarlierEnd;
      }
      else if (this.oneHour) {
        this.msgErr = MsgError.OneHour;
      }
      this.dataInvalidaEnd = true;
      this.saving = false;
      return false;
    }

    return true;
  }

  validateCandidates(){
    if (this.selectedCandidates.length <= 0) {
      this.candidateError = true;
      this.saving = false;
      return false;
    }    
    
    return true;
  }

  validateCandidatesTsp(){
    if(this.selectedCandidates.length <= 0 && this.selectedGroupItems.length <= 0){
      this.candidateError = true;
      this.saving = false;
      return false;
    }
    else if(this.selectedCandidates.length <= 0 && this.selectedGroupItems.length >= 1){
      this.candidateErrorTsp = true;
      this.saving = false;
      return false;
    }

    return true;
  }

  validateForm(){
    this.tobForm = this.formBuilder.group({
      id: [],
      name: ['', Validators.required],
      description: ['', Validators.required],
      begin_date: ['', Validators.required],
      end_date: ['', Validators.required],
      begin_time: ['0', Validators.required],
      end_time: ['0', Validators.required],
      email: [''],
      pollType: ['', Validators.required],
      saveType: ['', Validators.required],
      selectedCandidates: ['', Validators.required],
      selectedGroups: ['', Validators.required],
      vacancy: ['', Validators.required],
      selectedVoters: ['', Validators.required],
      current_user: [''],
      approver_message: ['']
    });
  }

  validateGroups(){
    if (this.selectedGroups == undefined) {
      this.groupError = true;
      this.saving = false;
      return false;
    }
    
    return true;
  }

  validateVoters(){
    if (this.selectedVoters.length <= 0) {
      this.voterError = true;
      this.saving = false;
      return false;
    }
    
    return true;
  }
  //#endregion Validations


  //#region Voters
  loadVoters() {
    if (this.countSelectedGroups == 0){
      this.groupError = true;
      return
    }
    this.loadingVoter = true;
    this.dataService.getPartnersGroup(this.selectedGroupItems).subscribe(data => {
      this.loadingVoter = false;
      this.voters = data;
      this.voterError = false;
      this.countVoters = Object.keys(this.voters).length;
      $('#tabelaVoters').DataTable().clear().draw();
      $.each(data,
        function (key: any, item: any) {
          $("#tabelaVoters").DataTable().row.add([
            item.name
          ]).draw();
        });
      this.countShowVoterTable++;
    });
  }
  //#endregion Voters
}