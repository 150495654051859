import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../data.service';
import { DateUtil } from '../utils/date.util';
import { CodError } from '../utils/date.enum';
import { MsgError } from '../utils/date.enum';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router'; 
import { AuthService } from '../auth.service';
import { User } from '../interfaces/user';
import { NgbTimepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import swal from 'sweetalert2';
import { trigger, state, style, animate, transition } from '@angular/animations';
import * as _ from 'lodash';
import { Dictionary } from 'lodash';
import { PollSaveType } from '../utils/poll-save-type.enum';
import { PollType } from '../utils/poll-type.enum';

declare var $: any;

interface Alert {
    type: string;
    message: string;
}

const ALERTS: Alert[] = [{
    type: 'danger',
    message: 'Please, fill all the fields',
}];

@Component({
    selector: 'app-poll-creation-board',
    templateUrl: './poll-creation-board.component.html',
    styleUrls: ['./poll-creation-board.component.scss'],
    animations: [
        trigger('fadeInOut', [
            state('void', style({
                opacity: 0
            })),
            transition('void <=> *', animate(1000)),
        ]),
    ]
})

export class PollCreationBoardComponent implements OnInit {

    //#region Variables
    myTime: Date = new Date();
    minDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
    selectedVoters: any[] = [];
    selectedCandidates: any[] = [];
    countSelectedVoters = 0;
    public voters: Dictionary<never> = {};
    public candidates: Dictionary<never> = {};
    tob = false;
    vacancy = 1;
    pollTitle!: string;
    polltype!: Number;
    choosePollType = true;
    tobForm!: FormGroup;
    submitted = false;
    todayDate = new Date();
    title: string;
    requiredFields = false;
    alerts!: Alert[];
    loader = false;
    error = false;
    success = false;
    user!: User;
    loading = false;
    loading2 = false;
    dataInvalidaEnd = false;
    horaInvalidaEnd = false;
    horaInvalida = false;
    validators = true;
    type: string;
    errors: any = {};
    loadingVoter = false;
    countVoters = 0;
    voterError = false;
    loadingCandidate = false;
    countCandidate = 0;
    candidateError = false;
    candidateErrorTsp = false;
    groupError = false;
    selectedItems: any[] = [];
    selectedGroupItems: any[] = [];
    selectedGroups: any[] = [];
    settings = {};
    groupSettings = {};
    pollDataCandidates: any;
    pollDataGroups: any;
    countSelectedCandidates = 0;
    countSelectedGroups = 0;
    saving = false;
    vacancyError = false;
    startRequired = false;
    endRequired = false;
    startEarlierEnd = false;
    oneHour = false;
    msgErr!: string;
    countShowVoterTable = 0;
    countShowCandidateTable = 0;
    value: any;
    approver_message: string;
    approved: PollSaveType = PollSaveType.Approved;
    board: PollType = PollType.BOARD;
    tsp: PollType = PollType.TSP;
    //#endregion Variables

    constructor(private router: Router, private formBuilder: FormBuilder,
        private dataService: DataService, private dateUtil: DateUtil,
        private auth: AuthService, config: NgbTimepickerConfig, private route: ActivatedRoute, private location: Location) {
        console.log('PollCreationBoardComponent constructor');
        config.spinners = false;
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.tobForm.controls;
    }

    confirmSavedPoll() {
        console.log('confirm save poll');
        this.loader = false;
        this.success = true;
        setTimeout(() => {
            this.router.navigate(['/admin']);
        }
            , 2000);
    }

    pollErrorMessage() {
        console.log('save error poll');
        this.loader = false;
        this.error = true;
        setTimeout(() => {
            //this.router.navigate(['/admin']);
        }
            , 2000);
    }

    //#region Action Buttons
    cancel() {
        swal.fire({
            title: 'Are you sure?',
            text: "All information will be lost.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#dc6900',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, I am sure.'
        }).then((result: any) => {
            if (result.value) {
                this.router.navigate(['/admin']);
            }
        });
    }

    goBack() {
        this.location.back();
        console.log('goBack()...');
    }
    
    onSubmit(saveType: Event) {
        this.saving = true;
        this.selectedVoters = _.map(this.voters, 'pwc_guid');

        if (this.type != PollType[this.tsp])
            this.selectedCandidates = _.map(this.candidates, 'pwc_guid');        
        else
            this.selectedCandidates = this.selectedItems;

        let saveTypeId: number = Number((event.target as Element).id);
        if (saveTypeId === PollSaveType.Draft) {
            console.log("Element ID is 0, clearing validators");        
            this.clearValidators();
        }
        else{
            console.log("Element ID is not 0, performing validation");            
            if(!this.validateDates()) return;            
            //if(!this.validateVancacy()) return;
            if(!this.validateGroups()) return;

            if(this.type != PollType[this.tsp]){
                if(!this.validateCandidates()) return;            
            }
            else {
                if(!this.validateCandidatesTsp()) return;
            }

            if(!this.validateVoters()) return;
            if(saveTypeId === PollSaveType.Approved) this.approver_message = PollSaveType[this.approved];
        }

        console.log("Setting form control values");
        this.tobForm.controls['saveType'].setValue(saveTypeId);
        this.tobForm.controls['selectedVoters'].setValue(this.selectedVoters);
        this.tobForm.controls['selectedCandidates'].setValue(this.selectedCandidates);
        this.tobForm.controls['selectedGroups'].setValue(this.selectedGroups);
        this.tobForm.controls['pollType'].setValue(this.polltype);
        this.tobForm.controls['current_user'].setValue(this.user.guid);
        this.submitted = true;
        this.tobForm.controls['approver_message'].setValue(this.approver_message);
        console.log("Checking form validity");

        if(!this.validateForm()) return;
        
        if (this.tobForm.value.begin_date.day != '' && this.tobForm.value.begin_date.day != undefined) {
            this.tobForm.value.begin_date = this.dateUtil.transformToDate(this.tobForm.value.begin_date, this.tobForm.value.begin_time)

            if (this.tobForm.value.begin_date == 'Invalid Date') {
                this.tobForm.value.begin_date = '';
            } else {
                this.tobForm.value.begin_date.toString();
                this.tobForm.controls['begin_date'].setValue(this.tobForm.value.begin_date);
            }
        } else {
            this.tobForm.value.begin_date = '';
            this.tobForm.controls['begin_date'].setValue('');
        }

        if (this.tobForm.value.end_date.day != '' && this.tobForm.value.end_date.day != undefined) {
            this.tobForm.value.end_date = this.dateUtil.transformToDate(this.tobForm.value.end_date, this.tobForm.value.end_time)
                
            if (this.tobForm.value.end_date == 'Invalid Date') {
                this.tobForm.value.end_date = '';
            } else {
                this.tobForm.value.end_date.toString();
                this.tobForm.controls['end_date'].setValue(this.tobForm.value.end_date);
            }
        }

        console.log("Saving form");            
        this.loader = true;
        this.dataService.createPoll(this.tobForm.value)  
            .pipe(first())  
            .subscribe(  
                data => {  
                    console.log("API response data:", data);  
                    swal.fire({   
                        title: 'Success!',   
                        text: 'The poll has been saved.',   
                        icon: 'success',   
                        confirmButtonColor: '#dc6900'   
                    }).then(() => {  
                        console.log("Poll saved successfully, navigating to admin");  
                        this.router.navigate(['/admin']);  
                    });  
                },  
                error => {  
                    console.error("API error response:", error);  
                    swal.fire({  
                        title: 'Oops...',  
                        html: '<p>We are sorry, an error has occurred. Please contact the system administrators.</p>' +  
                            '<br><div class="alert alert-danger" role="alert"><p> Error: </p><br>' + error.error.message + '<br><br>'  
                            + error.message + '</div>',  
                        icon: 'error',   
                        confirmButtonColor: '#dc6900'  
                    }).then(() => {  
                        console.log("Error occurred, navigating to admin");  
                        this.router.navigate(['/admin']);  
                    });  
                }  
            );        
            
    }
    //#endregion Action Buttons


    //#region Candidates
    loadCandidates() {
        if (this.countSelectedGroups == 0) {
            this.groupError = true;
            return
        }

        if(this.type == PollType[this.tsp])
            this.loader = true;

        this.loadingCandidate = true;
        this.selectedGroups = _.map(this.selectedGroupItems, 'id');
        this.dataService.getPartnersGroup(this.selectedGroupItems).subscribe(data => {
            this.loadingCandidate = false;
            this.loader = false;
            this.candidates = data as Dictionary<never>;
            this.pollDataCandidates = data;
            this.selectedItems = [];
            this.candidateError = false;
            this.countCandidate = Object.keys(this.candidates).length;
            if (this.type != PollType[this.tsp]) {
                this.countSelectedCandidates = this.countCandidate;
                $('#tabelaCandidates').DataTable().clear().draw();
                $.each(data,
                    function (key: any, item: { name: any; }) {
                        $("#tabelaCandidates").DataTable().row.add([
                            item.name
                        ]).draw();
                    });
                this.countShowCandidateTable++;
            };
        });
    }    

    onDeSelectAll(items: any) {
        this.countSelectedCandidates = items.length;
    }

    onItemDeSelect(item: any) {
        this.countSelectedCandidates--;        
    }

    onItemSelect(item: any) {
        this.countSelectedCandidates++;
        this.candidateErrorTsp = false;
    }

    onSelectAll(items: any) {
        this.countSelectedCandidates = items.length;
        this.candidateErrorTsp = false;
    }

    selectCandidates(){
        this.settings = {
            singleSelection: false,
            itemsShowLimit: 5,
            enableSearchFilter: false,
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            classes: "myclass custom-class-example",
            idField: 'id',
            textField: 'name',
            labelKey: 'name',
        };
    }
    //#endregion Candidates


    //#region Clear Fields
    clearValidators(){
        this.tobForm.controls['name'].clearValidators();
        this.tobForm.controls['name'].updateValueAndValidity();

        this.tobForm.controls['description'].clearValidators();
        this.tobForm.controls['description'].updateValueAndValidity();

        this.tobForm.controls['begin_date'].clearValidators();
        this.tobForm.controls['begin_date'].updateValueAndValidity();

        this.tobForm.controls['begin_time'].clearValidators();
        this.tobForm.controls['begin_time'].updateValueAndValidity();

        this.tobForm.controls['end_date'].clearValidators();
        this.tobForm.controls['end_date'].updateValueAndValidity();

        this.tobForm.controls['end_time'].clearValidators();
        this.tobForm.controls['end_time'].updateValueAndValidity();

        this.tobForm.controls['email'].clearValidators();
        this.tobForm.controls['email'].updateValueAndValidity();

        this.tobForm.controls['selectedVoters'].clearValidators();
        this.tobForm.controls['selectedVoters'].updateValueAndValidity();

        this.tobForm.controls['selectedCandidates'].clearValidators();
        this.tobForm.controls['selectedCandidates'].updateValueAndValidity();

        this.tobForm.controls['selectedGroups'].clearValidators();
        this.tobForm.controls['selectedGroups'].updateValueAndValidity();

        this.tobForm.controls['vacancy'].clearValidators();
        this.tobForm.controls['vacancy'].updateValueAndValidity();
    }
    //#endregion Clear Fields


    //#region Groups
    onGroupItemDeSelect(item: any) {
        this.countSelectedGroups--;
    }
    
    onGroupItemSelect(item: any) {
        this.countSelectedGroups++;
        this.groupError = false;
    }
    
    onGroupDeSelectAll(items: any) {
        this.countSelectedGroups = 0;
    }

    onGroupSelectAll(items: any) {
        this.countSelectedGroups = items.length;
        this.groupError = false;
    }    

    selectGroup() {
        this.dataService.getGroups().subscribe(data => {
            this.pollDataGroups = data;
        });

        this.groupSettings = {
            singleSelection: false,
            itemsShowLimit: 5,
            enableSearchFilter: false,
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            classes: "myclass custom-class-example",
            idField: 'id',
            textField: 'groupname',
            labelKey: 'groupname',
        };
    }
    //#endregion Groups


    //#region OnInit
    ngOnInit() {
        this.buildForms();
        this.type = (this.route.snapshot.paramMap.get('type') as string).toUpperCase();
        this.type = this.type == PollType[this.tsp] ? this.type : PollType[this.board];        
        this.selectCandidates();
        this.selectGroup();

        if (this.type == PollType[this.tsp]) {
            this.title = PollType[this.tsp];
            this.polltype = PollType.TSP;
            this.choosePollType = false;
            this.pollTitle = 'New TSP poll - ' +
                this.todayDate.toLocaleString('en-us', { month: 'long' }) + ' ' + this.todayDate.getFullYear();
            this.tob = true;
            this.vacancy = 1;
            this.tobForm.controls['name'].setValue(this.pollTitle);
            this.tobForm.controls['vacancy'].setValue(this.vacancy);
        } else {
            this.title = PollType[this.board];
            this.polltype = PollType.BOARD;
            this.choosePollType = false;
            this.tob = true;
            this.pollTitle = 'New Board poll - ' +
                this.todayDate.toLocaleString('en-us', { month: 'long' }) + ' ' + this.todayDate.getFullYear();
            this.tobForm.controls['name'].setValue(this.pollTitle);
        }

        this.user = this.auth.getUserInfo();
        this.alerts = Array.from(ALERTS);
        $("#profileImage").click(function (e: any) {
            $("#imageUpload").click();
        });

        function fasterPreview(uploader: { files: (Blob | MediaSource)[]; }) {
            if (uploader.files && uploader.files[0]) {
                $('#profileImage').attr('src',
                    window.URL.createObjectURL(uploader.files[0]));
            }
        }

        $("#imageUpload").change(function () {
            fasterPreview(this);
        });

        //MULTISELECT
        $(document).ready(() => {
            // make code pretty
            // hack for iPhone 7.0.3 multiselects bug
            if (navigator.userAgent.match(/iPhone/i)) {
                $('select[multiple]').each(() => {
                    var select = $(this).on({
                        "focusout": function () {
                            var values = select.val() || [];
                            setTimeout(function () {
                                select.val(values.length ? values : ['']).change();
                            }, 1000);
                        }
                    });
                    var firstOption = '<option value="" disabled="disabled"';
                    firstOption += (select.val() || []).length > 0 ? '' : ' selected="selected"';
                    firstOption += '>Select ' + (select.attr('title') || 'Options') + '';
                    firstOption += '</option>';
                    select.prepend(firstOption);
                });
            }

            // DUAL LIST TOB SELECT GROUPS
            $('#multiselectTobGroup').multiselect({
            });

            $('.countSelectedGroupsTob').html(0 + ' selected');
            $('#multiselectTobGroup_rightAll').on('click', function (e: any, opt: any) {
                var x = $('#multiselectTobGroup_to option').length;
                $('.countSelectedGroupsTob').html(x + ' selected');
            });

            $('#multiselectTobGroup_rightSelected').on('click', function (e: any, opt: any) {
                var x = $('#multiselectTobGroup_to option').length;
                $('.countSelectedGroupsTob').html(x + ' selected');
            });

            $('#multiselectTobGroup_leftSelected').on('click', function (e: any, opt: any) {
                var x = $('#multiselectTobGroup_to option').length;
                $('.countSelectedGroupsTob').html(x + ' selected');
            });

            $('#multiselectTobGroup_leftAll').on('click', function (e: any, opt: any) {
                var x = $('#multiselectTobGroup_to option').length;
                $('.countSelectedGroupsTob').html(x + ' selected');
            });

            // DUAL LIST TOB SELECT CANDIDATES
            $('#multiselectTob').multiselect({
            });

            $('.countSelectedCandidates').html(0 + ' selected');
            $('#multiselectTob_rightAll').on('click', function (e: any, opt: any) {
                var y = $('#multiselectTob_to option').length;
                $('.countSelectedCandidates').html(y + ' selected');
            });

            $('#multiselectTob_rightSelected').on('click', function (e: any, opt: any) {
                var y = $('#multiselectTob_to option').length;
                $('.countSelectedCandidates').html(y + ' selected');
            });

            $('#multiselectTob_leftSelected').on('click', function (e: any, opt: any) {
                var y = $('#multiselectTob_to option').length;
                $('.countSelectedCandidates').html(y + ' selected');
            });

            $('#multiselectTob_leftAll').on('click', function (e: any, opt: any) {
                var y = $('#multiselectTob_to option').length;
                $('.countSelectedCandidates').html(y + ' selected');
            });

            // DUAL LIST TOB SELECT VOTERS
            $('#multiselectTobVoter').multiselect({
            });

            $('.countSelectedVotersTob').html(0 + ' selected');
            $('#multiselectTobVoter_rightAll').on('click', function (e: any, opt: any) {
                var x = $('#multiselectTobVoter_to option').length;
                $('.countSelectedVotersTob').html(x + ' selected');
            });

            $('#multiselectTobVoter_rightSelected').on('click', function (e: any, opt: any) {
                var x = $('#multiselectTobVoter_to option').length;
                $('.countSelectedVotersTob').html(x + ' selected');
            });

            $('#multiselectTobVoter_leftSelected').on('click', function (e: any, opt: any) {
                var x = $('#multiselectTobVoter_to option').length;
                $('.countSelectedVotersTob').html(x + ' selected');
            });

            $('#multiselectTobVoter_leftAll').on('click', function (e: any, opt: any) {
                var x = $('#multiselectTobVoter_to option').length;
                $('.countSelectedVotersTob').html(x + ' selected');
            });

            var countries = $('#multiselectTob_to').find('option').map(function () { return this.value }).get().join(", ");
        });
    }
    //#endregion OnInit


    //#region Vacancies
    verifyVacancies(item: any) {
        this.vacancyError = false;
        return item.charCode >= 49;
    }
    //#endregion Vacancies


    //#region Validations
    buildForms() {
        console.log('Building forms');
        this.tobForm = this.formBuilder.group({
            name: ['', Validators.required],
            description: ['', Validators.required],
            begin_date: ['', Validators.required],
            end_date: ['', Validators.required],
            email: [''],
            pollType: ['', Validators.required],
            saveType: ['', Validators.required],
            selectedCandidates: ['', Validators.required],
            selectedGroups: ['', Validators.required],
            vacancy: ['1', Validators.required],
            selectedVoters: ['', Validators.required],
            end_time: ['0', Validators.required],
            begin_time: ['0', Validators.required],
            current_user: [''],
            approver_message: ['']
        });
    }

    validateCandidates(){
        if (this.selectedCandidates.length <= 0) {
            console.log("Candidate error: No candidates selected");
            this.candidateError = true;
            this.saving = false;
            return false;
        }

        return true;
    }

    validateCandidatesTsp(){
        if (this.selectedGroups.length <= 0 && this.selectedCandidates.length <= 0){
            this.candidateError = true;
            this.saving = false;
            return false;
        }
        else if(this.selectedCandidates.length <= 0){
            this.candidateErrorTsp = true;
            this.saving = false;
            return false;
        }
        return true;
    }

    validateDates(){
        this.msgErr = '';
        this.startRequired = false;
        this.endRequired = false;
        this.startEarlierEnd = false;
        this.oneHour = false;
        let model = {
            beginDate: this.tobForm.value.begin_date,
            endDate: this.tobForm.value.end_date,
            beginTime: this.tobForm.value.begin_time,
            endTime: this.tobForm.value.end_time,
            codError: 0,
            msgError: ""
        };
        console.log("Model for date validation:", model);

        if (!this.dateUtil.validateDateTime(model)) {
            this.startRequired = model.codError == CodError.StartRequired ? true : false;
            this.endRequired = model.codError == CodError.EndRequired ? true : false;
            this.startEarlierEnd = model.codError == CodError.StartEarlierEnd ? true : false;
            this.oneHour = model.codError == CodError.OneHour ? true : false;
            
            if (this.startRequired) {
                this.msgErr = MsgError.StartRequired;
            } else if (this.endRequired) {
                this.msgErr = MsgError.EndRequired;
            } else if (this.startEarlierEnd) {
                this.msgErr = MsgError.StartEarlierEnd;
            } else if (this.oneHour) {
                this.msgErr = MsgError.OneHour;
            }
            
            console.log("Validation errors:", this.msgErr);
            this.dataInvalidaEnd = true;
            this.saving = false;
            return false;
        }

        return true;
    }

    validateForm(){
        if (this.tobForm.invalid) {
            console.log("Form is invalid");
            window.scroll(0, 0);
            this.saving = false;
            return false;
        }

        return true;
    }

    validateGroups(){        
        if (this.selectedGroups == undefined) {
            console.log("Group error: No groups selected");
            this.groupError = true;
            this.saving = false;
            return false;
        }
    
        return true;
    }

    validateVancacy(){
    // if (saveTypeId !== '0') {
    //     if ((this.type == PollType[this.tsp] && this.tobForm.controls['vacancy'].value != this.countSelectedCandidates) ||
    //         (this.type != PollType[this.tsp] && this.tobForm.controls['vacancy'].value > this.countCandidate)) {
    //         console.log("Vacancy error: Mismatch in vacancy and selected candidates");
    //         this.vacancyError = true;
    //         this.saving = false;
    //         return false;
    //     } else {
    //         this.vacancyError = false;
    //         return true;
    //     }
    // }
    }

    validateVoters(){
        if (this.voters == undefined || this.selectedVoters.length == 0) {
            console.log("Voter error: No voters selected");
            this.voterError = true;
            this.saving = false;
            return false;
        }          
        
        return true;
    }
    //#endregion Validations


    //#region Voters
    loadVoters() {
        if (this.countSelectedGroups == 0) {
            this.groupError = true;
            return
        }

        this.loadingVoter = true;
        this.dataService.getPartnersGroup(this.selectedGroupItems).subscribe(data => {
            this.loadingVoter = false;
            this.voters = data as Dictionary<never>;
            this.voterError = false;
            this.countVoters = Object.keys(this.voters).length;
            $('#tabelaVoters').DataTable().clear().draw();
            $.each(data,
                function (key: any, item: { name: any; }) {
                    $("#tabelaVoters").DataTable().row.add([
                        item.name
                    ]).draw();
                });
            this.countShowVoterTable++;
        });
    }
    //#endregion Voters
}
