import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class NotificationService {

  constructor(private http: HttpClient) { }

  sendEmail(data: { to_address: string; message_content: string; poll_id: string; subject: string; user_pwc_guid: string; }) {
    return this.http.post(environment.apiEndpoint + 'email', data);
  }
}
