import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DataService } from '../data.service';
import { first } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { AttachmentService } from '../services/attachment.service';
import { AuthService } from '../auth.service';
import { User } from '../interfaces/user';
import {NgbTimepickerConfig} from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import swal from 'sweetalert2';
import { animate, state, style, transition, trigger } from '@angular/animations';
import * as _ from 'lodash';

declare var $: any;

interface Alert {
  type: string;
  message: string;
}

const ALERTS: Alert[] = [{
  type: 'danger',
  message: 'Please, fill all the fields',
}];

@Component({
  selector: 'app-poll-creation-partner',
  templateUrl: './poll-creation-partner.component.html',
  styleUrls: ['./poll-creation-partner.component.scss'],
  animations: [
    trigger('fadeInOut', [
        state('void', style({
            opacity: 0
        })),
        transition('void <=> *', animate(1000)),
    ]),
]
})

export class PollCreationPartnerComponent implements OnInit {

  myTime: Date = new Date();
  showMin = false;

  minDate = {year: new Date().getFullYear() , month: new Date().getMonth()+1, day: new Date().getDate()};


  selectedVoters: any[] = [];
  selectedCandidates: any[] = [];
  countSelectedVoters = 0;
  voters: Object;
  partner = false;
  apartner = false;
  vacancy = 1;
  errors: any={};
  pollTitle: string;
  polltype: Number;
  choosePollType = true;
  partnerForm: FormGroup;
  tobForm: FormGroup;
  submitted = false;
  bsModalRef: BsModalRef;
  candidateLos: any[];
  todayDate = new Date();
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  title: string;
  requiredFields = false;
  alerts: Alert[];
  loader = false;
  error = false;
  success = false;
  selectedFile: File;
  filePath: any[] = [];
  fileName: any[] = [];
  filePathReport: string;
  filePathReportName: string;
  user: User;
  selectedPhoto: File;
  photoPath: any[] = [];
  loading = false;
  loading2 = false;
  dataInvalida = false;
  dataInvalidaEnd = false;
  percentDone: number;
  uploadSuccess: boolean;
  loaderAtt = false;
  validators = true;
  atLeastOneCandidate: boolean;
  type: string;
candidates: any;


  constructor(private router: Router, private http: HttpClient, private formBuilder: FormBuilder,
    private modalService: BsModalService, private dataService: DataService,
    private attachmentService: AttachmentService,  private auth: AuthService, config: NgbTimepickerConfig,
    private route: ActivatedRoute, private location: Location) {

      console.log('Router:', !!router);
      console.log('HttpClient:', !!http);
      console.log('FormBuilder:', !!formBuilder);
      console.log('BsModalService:', !!modalService);
      console.log('DataService:', !!dataService);
      console.log('AttachmentService:', !!attachmentService);
      console.log('AuthService:', !!auth);
      console.log('NgbTimepickerConfig:', !!config);
      console.log('ActivatedRoute:', !!route);
      console.log('Location:', !!location);

    config.spinners = false;
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }

  // convenience getter for easy access to form fields
  get f() {
    console.log('Accessing form controls');
      if ((this.partnerForm.controls.begin_date.value == 'Sat Jan 01 2000 00:00:00 GMT-0200 (Brasilia Summer Time)')
            || (this.partnerForm.controls.begin_date.value == null)) {
                this.partnerForm.controls['begin_date'].setValue(null);
                this.dataInvalida = true;
            } else {
                this.dataInvalida = false;
            }

    if ((this.partnerForm.controls.end_date.value == 'Sat Jan 01 2000 00:00:00 GMT-0200 (Brasilia Summer Time)')
        || (this.partnerForm.controls.end_date.value == null)) {
                this.partnerForm.controls['end_date'].setValue(null);
                this.dataInvalidaEnd = true;
            } else {
                this.dataInvalidaEnd = false;
            }
    if (this.partnerForm.controls.candidates.status == 'INVALID') {
        this.atLeastOneCandidate = true;
    }

    if (!this.validateDate()){
      this.dataInvalidaEnd = true;
    }

    return this.partnerForm.controls;
  }

validateDate() {
    if (this.partnerForm.value.begin_date != null &&
        this.partnerForm.value.end_date != null){
      var data_1 = this.partnerForm.value.begin_date.day;
      var data_2 = this.partnerForm.value.end_date.day;
      var month_1 = this.partnerForm.value.begin_date.month;
      var month_2 = this.partnerForm.value.end_date.month;
      if(month_1 == month_2) {
        this.errors['dates'] = (data_1 + 1 >= data_2)
      return (data_1 + 1 >= data_2)
      } else if(month_1 < month_2) {
        this.errors['dates'] = false
      return false
      } else if(month_1 < month_2) {
        this.errors['dates'] = true
      return true
      }
    } else {
      this.errors['dates'] = false
      return false
    }
  }

  onSubmit(saveType: Event) {
    if (this.polltype == 2 || this.polltype == 3) {
      this.selectedVoters = $('#multiselect_to option').map( () => { return $(this).val(); }).get();
      let elementId: string = (event.target as Element).id;

      if (elementId === '0') {
          this.validators = false;
        this.partnerForm.controls['name'].clearValidators();
        this.partnerForm.controls['name'].updateValueAndValidity();

        this.partnerForm.controls['description'].clearValidators();
        this.partnerForm.controls['description'].updateValueAndValidity();

        this.partnerForm.controls['begin_date'].clearValidators();
        this.partnerForm.controls['begin_date'].updateValueAndValidity();

        this.partnerForm.controls['begin_time'].clearValidators();
        this.partnerForm.controls['begin_time'].updateValueAndValidity();

        this.partnerForm.controls['end_date'].clearValidators();
        this.partnerForm.controls['end_date'].updateValueAndValidity();

        this.partnerForm.controls['end_time'].clearValidators();
        this.partnerForm.controls['end_time'].updateValueAndValidity();

        this.partnerForm.controls['selectedVoters'].clearValidators();
        this.partnerForm.controls['selectedVoters'].updateValueAndValidity();

        this.partnerForm.controls['candidates'].clearValidators();
        this.partnerForm.controls['candidates'].updateValueAndValidity();
      }

      this.partnerForm.controls['saveType'].setValue(elementId);
      this.partnerForm.controls['selectedVoters'].setValue(this.selectedVoters);
      this.partnerForm.controls['pollType'].setValue(this.polltype);
      this.partnerForm.controls['current_user'].setValue(this.user.guid);



    let day_begin = this.partnerForm.value.begin_date.day;
    let month_begin = this.partnerForm.value.begin_date.month;
    let year_begin = this.partnerForm.value.begin_date.year;
    this.partnerForm.value.begin_date =  month_begin + '/' + day_begin + '/' + year_begin;
    this.partnerForm.value.begin_time = this.partnerForm.value.begin_time + ':00';
    this.partnerForm.value.begin_date = this.partnerForm.value.begin_date + ' ' + this.partnerForm.value.begin_time;

      this.partnerForm.value.begin_date = new Date(this.partnerForm.value.begin_date);
      if (this.partnerForm.value.begin_date == 'Invalid Date') {
        this.partnerForm.value.begin_date = '';
      } else {
      this.partnerForm.value.begin_date.toString();
      this.partnerForm.controls['begin_date'].setValue(this.partnerForm.value.begin_date);
      }
      let day_end = this.partnerForm.value.end_date.day;
      let month_end = this.partnerForm.value.end_date.month;
      let year_end = this.partnerForm.value.end_date.year;
      this.partnerForm.value.end_date =  month_end + '/' + day_end + '/' + year_end;
      this.partnerForm.value.end_time = this.partnerForm.value.end_time + ':00';
      this.partnerForm.value.end_date = this.partnerForm.value.end_date + ' ' + this.partnerForm.value.end_time;

      this.partnerForm.value.end_date = new Date(this.partnerForm.value.end_date);

      if (this.partnerForm.value.end_date == 'Invalid Date') {
        this.partnerForm.value.end_date = '';
      } else {
      this.partnerForm.value.end_date.toString();
      this.partnerForm.controls['end_date'].setValue(this.partnerForm.value.end_date);
      }
      this.partnerForm.controls.candidates.value.businesscase = this.filePath;
      for (let i = 0; i < this.partnerForm.controls.candidates.value.length ; i++) {
          this.partnerForm.controls.candidates.value[i].photo = this.photoPath[i];
         }
      for (let i = 0; i < this.partnerForm.controls.candidates.value.length ; i++) {
          this.partnerForm.controls.candidates.value[i].businesscase = this.filePath[i];
         }
      this.submitted = true;
      // stop here if form is invalid
      if (this.partnerForm.invalid) {


        //DESCONVERTER O TIMESTAMP PRA YEAR - MONTH - DAY

        this.requiredFields = true;
        window.scroll(0, 0);
        return;
      }

      this.loader = true;
      this.dataService.createPoll(this.partnerForm.value)
        .pipe(first())
        .subscribe(
            data =>  swal.fire({title: 'Success!', text: 'The poll has been saved.', icon: 'success', confirmButtonColor: '#dc6900'})
            .then(() => {
                this.router.navigate(['/admin']);
            }),
            error => swal.fire({title: 'Oops...',
            html: '<p>We are sorry, an error has occurred. Please contact the system administrators.</p>' +
                '<br><div class="alert alert-danger" role="alert"><p> Error: </p><br>' + error.error.message + '<br><br>'
                 + error.message + '</div>',
            icon: 'error', confirmButtonColor: '#dc6900'})
            .then(() => {
                this.router.navigate(['/admin']);
            }));
    }
  }

  //   //event handler for the select element's change event
    selectChangeHandler(event: any) {
      //update the ui
      this.candidateLos = event.target.value;
    }

  initCandidates() {
    return this.formBuilder.group({
      candidatename: [],
      candidatedesc: [],
      candidateLos: [],
      businesscase: [],
      photo: []
    });
  }

  confirmSavedPoll() {
    this.loader = false;
    this.success = true;
    setTimeout(() => {
      this.router.navigate(['/admin']);
    }
      , 2000);
  }

  cancel() {

    swal.fire({
        title: 'Are you sure?',
        text: "All information will be lost.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#dc6900',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, I am sure.'
      }).then((result: any) => {
        if (result.value) {
          this.router.navigate(['/admin']);
        }
      });

    // if (confirm('Do you really want to cancel the poll creation? All information will be lost.')) {
    //     setTimeout(() => {
    //         this.router.navigate(['/admin']);
    //     });
  }

  pollErrorMessage() {
    this.loader = false;
    this.error = true;
    setTimeout(() => {
      this.router.navigate(['/admin']);
    }
      , 2000);
  }

  addCandidate() {
    const control = <FormArray>this.partnerForm.controls['candidates'];
    control.push(this.initCandidates());
  }

  removeCandidate(i: number) {
    const control = <FormArray>this.partnerForm.controls['candidates'];
    control.removeAt(i);
    this.photoPath.splice(i, 1);
  }

  buildForms() {
    this.partnerForm = this.formBuilder.group({
        name: ['', Validators.required],
        description: ['', Validators.required],
        begin_date: ['', Validators.required],
        end_date: ['', Validators.required],
        email: [''],
        pollType: ['', Validators.required],
        saveType: ['', Validators.required],
        end_time: ['0', Validators.required],
        begin_time: ['0', Validators.required],
        candidates: this.formBuilder.array([
          this.initCandidates()
        ]),
        selectedVoters: ['', Validators.required],
        current_user: ['']
      });
  }

  ngOnInit() {
    console.log('PollCreationPartnerComponent initialized');
    this.buildForms();
    this.type = this.route.snapshot.paramMap.get('type') as string;

    console.log(this.pollTitle);
    if (this.type == 'partner') {
        this.title = 'Partner';
        this.polltype = 2;
        this.choosePollType = false;
        this.partner = true;
        this.pollTitle = 'New Partner poll - ' +
         this.todayDate.toLocaleString('en-us', { month: 'long' }) + ' ' + this.todayDate.getFullYear();
        this.partnerForm.controls['name'].setValue(this.pollTitle);
      } else {
        this.title = 'Associate Partner';
        this.polltype = 3;
        this.choosePollType = false;
        this.pollTitle = 'New Associate Partner poll - ' +
         this.todayDate.toLocaleString('en-us', { month: 'long' }) + ' ' + this.todayDate.getFullYear();
        this.partner = true;
        this.partnerForm.controls['name'].setValue(this.pollTitle);
      }
      console.log(this.pollTitle);

    this.user = this.auth.getUserInfo();
    this.alerts = Array.from(ALERTS);

    this.dataService.getPartners().subscribe(data => {
      this.voters = data;
    });


    $("#profileImage").click(function (e: any) {
      $("#imageUpload").click();
    });

    function fasterPreview(uploader: any) {
      if (uploader.files && uploader.files[0]) {
        $('#profileImage').attr('src',
          window.URL.createObjectURL(uploader.files[0]));
      }
    }

    $("#imageUpload").change( () => {
      fasterPreview(this);
    });


    //MULTISELECT
    $(document).ready( () => {
      // make code pretty

      // hack for iPhone 7.0.3 multiselects bug
      if (navigator.userAgent.match(/iPhone/i)) {
        $('select[multiple]').each( ()=> {
          var select = $(this).on({
            "focusout":  () =>{
              var values = select.val() || [];
              setTimeout( ()=> {
                select.val(values.length ? values : ['']).change();
              }, 1000);
            }
          });
          var firstOption = '<option value="" disabled="disabled"';
          firstOption += (select.val() || []).length > 0 ? '' : ' selected="selected"';
          firstOption += '>Select ' + (select.attr('title') || 'Options') + '';
          firstOption += '</option>';
          select.prepend(firstOption);
        });
      }

      // MULTISELECT AP/PARTNER
      $('#multiselect').multiselect({

      });
      $('.countSelectedVoters').html(0 + ' selected');
      $('#multiselect_rightAll').on('click', (e: any, opt: any) => {
        var x = $('#multiselect_to option').length;
        $('.countSelectedVoters').html(x + ' selected');
      });
      $('#multiselect_rightSelected').on('click', (e: any, opt: any) => {
        var x = $('#multiselect_to option').length;
        $('.countSelectedVoters').html(x + ' selected');
      });
      $('#multiselect_leftSelected').on('click', (e: any, opt: any) => {
        var x = $('#multiselect_to option').length;
        $('.countSelectedVoters').html(x + ' selected');
      });
      $('#multiselect_leftAll').on('click', (e: any, opt: any) => {
        var x = $('#multiselect_to option').length;
        $('.countSelectedVoters').html(x + ' selected');
      });
    });
  }


  onPhotoChanged(event: any) {
    this.selectedPhoto = event.target.files[0];
  }

  onPhotoUpload(i: any) {
    const uploadData = new FormData();
    uploadData.append('myFile', this.selectedPhoto, this.selectedPhoto.name);
    uploadData.append('fileType', 'PHOTO')
    this.loader = true;
    this.attachmentService.sendAttachment(uploadData)
      .subscribe(
        data => {
          this.photoPath[i] = data.toString();
          this.selectedPhoto = null as any;
        },
        error => {
            swal.fire({title: 'Oops...',
            html: '<p>We are sorry, an error has occurred. Please contact the system administrators.</p>' +
                '<br><div class="alert alert-danger" role="alert"><p> Error: </p><br>' + error.error.message + '<br><br>'
                 + error.message + '</div>',
            icon: 'error', confirmButtonColor: '#dc6900'})
            .then(() => {
                this.router.navigate(['/admin']);
            });
        });
        this.loader = false;
  }

  onFileChanged(event: any) {
    this.selectedFile = event.target.files[0];
    console.log(this.selectedFile);
  }

  onUpload(i: any) {
    const uploadData = new FormData();
    uploadData.append('myFile', this.selectedFile, this.selectedFile.name);
    uploadData.append('fileType', 'BUSINESSCASE')
    this.loader = true;
    this.attachmentService.sendAttachment(uploadData)
      .subscribe(
        data => {
          this.filePath[i] = data.toString();
          this.fileName[i] = this.selectedFile.name;
          this.selectedFile = null as any;
          this.loader = false;
        },
        error => {
            swal.fire({title: 'Oops...',
            html: '<p>We are sorry, an error has occurred. Please contact the system administrators.</p>' +
                '<br><div class="alert alert-danger" role="alert"><p> Error: </p><br>' + error.error.message + '<br><br>'
                 + error.message + '</div>',
            icon: 'error', confirmButtonColor: '#dc6900'})
            .then(() => {
                this.router.navigate(['/admin']);
            });
            this.loader = false;
        });

  }

  onUploadReport() {
    const uploadData = new FormData();
    uploadData.append('myFile', this.selectedFile, this.selectedFile.name);
    uploadData.append('fileType', 'BUSINESSCASE')
    this.loader = true;
    this.attachmentService.sendAttachment(uploadData)
      .subscribe(
        data => {
          console.log("sucesso", data.toString());
          this.filePathReport = data.toString();
          this.filePathReportName = this.selectedFile.name;
          this.loader = false;
        },
        error => {
            swal.fire({title: 'Oops...',
            html: '<p>We are sorry, an error has occurred. Please contact the system administrators.</p>' +
                '<br><div class="alert alert-danger" role="alert"><p> Error: </p><br>' + error.error.message + '<br><br>'
                 + error.message + '</div>',
            icon: 'error', confirmButtonColor: '#dc6900'})
            .then(() => {
                this.router.navigate(['/admin']);
            });
            this.loader = false;
        });
  }

  goBack() {
    window.history.back();
    this.location.back();

    console.log( 'goBack()...' );
  }

}
