import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-access-denied',
    templateUrl: './access-denied.component.html',
    styles: []
})
export class AccessDeniedComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
