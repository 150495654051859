import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-admin-history',
  templateUrl: './admin-history.component.html',
  styles: []
})
export class AdminHistoryComponent implements OnInit {

  //#region Variables
  polls: any;
  //#endregion Variables

  constructor(private data: DataService) { }

  //#region OnInit
  ngOnInit() {
      this.getAllPolls();
  }
  //#endregion OnInit


  //#region Load
  public getAllPolls() {
    this.data.getPollsHistory().subscribe(data => {
        this.polls = data;
        },
        error => {
        //   console.log(error)
        });
  }
  //#endregion Load
}
