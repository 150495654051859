import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth.service';
import { User } from './../interfaces/user';

@Injectable({
    providedIn: 'root'
})
export class GroupService {

    user: User;

    constructor(private http: HttpClient, private auth: AuthService) {
        this.user = auth.getUserInfo();
    }

    private url: string = environment.apiEndpoint + 'group';

    public getActive(): Observable<any> {
        return this.http.get(this.url, { params: { active: 'true' } }).pipe(
            catchError(this.handleError)
        );
    }

    public getAll(): Observable<any> {
        return this.http.get(this.url).pipe(
            catchError(this.handleError)
        );
    }

    public get(id: any): Observable<any> {
        return this.http.get(this.url, { params: { 'id': id } }).pipe(
            catchError(this.handleError)
        );
    }

    public getByName(name: string): Observable<any> {
        return this.http.get(this.url, { params: { 'name': name } }).pipe(
            catchError(this.handleError)
        );
    }

    public save(group: any): Observable<any> {
        return this.http.post(this.url, group, { params: { current_user: this.user.guid } }).pipe(
            catchError(this.handleError)
        );
    }

    public update(group: any): Observable<any> {
        return this.http.put(this.url, group, { params: { current_user: this.user.guid } }).pipe(
            catchError(this.handleError)
        );
    }

    private handleError(error: HttpErrorResponse) {
        let errorMessage = 'An unknown error occurred!';
        if (error.error instanceof ErrorEvent) {
            // Client-side error
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // Server-side error
            errorMessage = error.error.message || `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
    }
}
