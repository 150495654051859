import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DataService } from '../data.service';
import { DateUtil } from '../utils/date.util';
import { CodError } from '../utils/date.enum';
import { MsgError } from '../utils/date.enum';
import { first } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { AttachmentService } from '../services/attachment.service';
import { AuthService } from '../auth.service';
import { User } from '../interfaces/user';
import { NgbTimepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import swal from 'sweetalert2';
import { trigger, state, style, animate, transition } from '@angular/animations';
import * as _ from 'lodash';
import * as fileSaver from 'file-saver-es';
import { PollSaveType } from '../utils/poll-save-type.enum';
import { PollType } from '../utils/poll-type.enum';

declare var $: any;

@Component({
  selector: 'app-create-poll-partner',
  templateUrl: './create-poll-partner.component.html',
  styleUrls: ['./create-poll-partner.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(1000)),
    ]),
  ]
})

export class CreatePollPartnerComponent implements OnInit {

  //#region Variables
  user: User;
  validHours = Array.from({ length: 24 }, (v, k) => k + 0);
  minDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
  partnerForm: any = {
    name: '',
    description: '',
    begin_date: '',
    begin_time: '0',
    end_date: '',
    end_time: '0',
    email: '',
    candidates: [],
    pollType: '',
    selectedVoters: [],
    approver_message: ' '
  };
  newCandidate: any = {
    photo: File,
    candidatename: '',
    candidateLos: '',
    candidatedesc: '',
    businesscase: '',
    fName: '',
    photoBytes: '',
    uploadComplete: true
  }
  voters: any = Object;
  errors: any = {}
  submitted = false
  loader = false
  pollTitle: string;
  polltype: Number;
  todayDate = new Date();
  type: string;
  title: string;
  choosePollType = true;
  loadingVoter = false;
  countVoters = 0;
  voterError = false;
  saving = false;
  startRequired = false;
  endRequired = false;
  startEarlierEnd = false;
  oneHour = false;
  msgErr: string;
  groupError = false;
  selectedGroupItems: any[] = [];
  selectedGroups: any[] = [];
  groupSettings = {};
  pollDataGroups: any;
  countSelectedGroups = 0;
  countShowVoterTable = 0;
  countShowCandidateTable = 0;
  tobForm: FormGroup;
  draft: PollSaveType = PollSaveType.Draft;
  pendingApproval: PollSaveType = PollSaveType.PendingApproval;
  approved: PollSaveType = PollSaveType.Approved;
  partner: PollType = PollType.PARTNER;
  //#endregion Variables


  constructor(private router: Router, private http: HttpClient, private formBuilder: FormBuilder,
    private modalService: BsModalService, private dataService: DataService, private dateUtil: DateUtil,
    private attachmentService: AttachmentService, private auth: AuthService, config: NgbTimepickerConfig,
    private route: ActivatedRoute, private location: Location) {

    config.spinners = false;
  }

  multiselectInit() {
    $(document).ready(() => {

      if (navigator.userAgent.match(/iPhone/i)) {
        $('select[multiple]').each(() => {
          var select = $(this).on({
            "focusout": function () {
              var values = select.val() || [];
              setTimeout(function () {
                select.val(values.length ? values : ['']).change();
              }, 1000);
            }
          });
          var firstOption = '<option value="" disabled="disabled"';
          firstOption += (select.val() || []).length > 0 ? '' : ' selected="selected"';
          firstOption += '>Select ' + (select.attr('title') || 'Options') + '';
          firstOption += '</option>';
          select.prepend(firstOption);
        });
      }

      $('#multiselect').multiselect({

      });
      $('.countSelectedVoters').html(0 + ' selected');
      $('#multiselect_rightAll').on('click', (e: any, opt: any) => {
        var x = $('#multiselect_to option').length;
        $('.countSelectedVoters').html(x + ' selected');
      });
      $('#multiselect_rightSelected').on('click', (e: any, opt: any) => {
        var x = $('#multiselect_to option').length;
        $('.countSelectedVoters').html(x + ' selected');
      });
      $('#multiselect_leftSelected').on('click', (e: any, opt: any) => {
        var x = $('#multiselect_to option').length;
        $('.countSelectedVoters').html(x + ' selected');
      });
      $('#multiselect_leftAll').on('click', (e: any, opt: any) => {
        var x = $('#multiselect_to option').length;
        $('.countSelectedVoters').html(x + ' selected');
      });

    });
  }
  
  removeInvalidClass() {
    $("input").click(() => { $(this).removeClass("is-invalid") })
    $("select").click(() => { $(this).removeClass("is-invalid") })
    $("textarea").click(() => { $(this).removeClass("is-invalid") })
  }


  //#region Action Buttons
  goBack() {
    this.location.back();
  }

  cancel() {
    swal.fire({
      title: 'Are you sure?',
      text: "All information will be lost.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dc6900',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, I am sure.'
    }).then((result: any) => {
      if (result.value) {
        this.router.navigate(['/admin']);
      }
    });
  }

  onSubmit(event: any, type: any) {
    this.saving = true;
    this.submitted = true;
    this.partnerForm.selectedVoters = _.map(this.voters, 'pwc_guid');
    this.partnerForm['saveType'] = PollSaveType.Draft;
    this.partnerForm['pollType'] = this.polltype;
    this.partnerForm.email = this.partnerForm.email == '' ? ' ' : this.partnerForm.email
    this.partnerForm.approver_message = type == PollSaveType[this.approved] ? PollSaveType[this.approved] : this.partnerForm.approver_message;

    if (type !== PollSaveType[this.draft]) {
      this.validateEmptyFields(this.partnerForm);
      if(!this.validateDates()) return;
      if(!this.validateFields()) return;

      if (type == PollSaveType[this.pendingApproval])
        this.partnerForm['saveType'] = PollSaveType.PendingApproval;      
      else if (type == PollSaveType[this.approved])
        this.partnerForm['saveType'] = PollSaveType.Approved;
    }

    this.partnerForm.begin_date = this.partnerForm.begin_date != '' ? this.dateUtil.transformToDate(this.partnerForm.begin_date, this.partnerForm.begin_time) : ''
    this.partnerForm.end_date = this.partnerForm.end_date != '' ? this.dateUtil.transformToDate(this.partnerForm.end_date, this.partnerForm.end_time) : ''
    this.partnerForm['current_user'] = this.user.guid;
    this.loader = true;
    this.dataService.createPoll(this.partnerForm)
      .pipe(first())
      .subscribe(
        data => swal.fire({ title: 'Success!', text: 'The poll has been saved.', icon: 'success', confirmButtonColor: '#dc6900' })
          .then(() => {
            this.router.navigate(['/admin']);
          }),
          error => swal.fire({
          title: 'Oops...',
          html: '<p>We are sorry, an error has occurred. Please contact the system administrators.</p>' +
            '<br><div class="alert alert-danger" role="alert"><p> Error: </p><br>' + error.error.message + '<br><br>'
            + error.message + '</div>',
            icon: 'error', confirmButtonColor: '#dc6900'
          })
          .then(() => {
            this.router.navigate(['/admin']);
          }));
  }
  //#endregion Action Buttons


  //#region Candidates
  addCandidate() {
    this.partnerForm.candidates.push(this.newCandidate)
    this.newCandidate = {
      photo: File,
      candidatename: '',
      candidateLos: '',
      candidatedesc: '',
      businesscase: '',
      fName: '',
      photoBytes: null,
      uploadComplete: true
    }

  }

  getDownload(fileName: string, link: string) {
    this.dataService.getDownload(fileName, link).subscribe((data: any) => {
      let blob: any = new Blob([data], { type: 'text/json; charset=utf-8' });
      const url = window.URL.createObjectURL(blob);
      fileSaver.saveAs(blob, fileName)
    },
      error => {
        console.log("erro", error.toString());
      });
  }

  onPhotoChanged(event: any) {
    this.newCandidate.photo = event.target.files[0];
    const uploadData = new FormData();
    uploadData.append('myFile', this.newCandidate.photo, this.newCandidate.photo.name);
    uploadData.append('fileType', 'PHOTO')
    this.loader = true;

    this.attachmentService.sendAttachment(uploadData)
      .subscribe(
        data => {
          this.newCandidate.photo = data.toString();
          var reader = new FileReader();
          reader.onload = (event: any) => {
            this.newCandidate.photoBytes = event.target.result;
          }
          reader.readAsDataURL(event.target.files[0]);
          this.loader = false;
        },
        error => {
          swal.fire({
            title: 'Oops...',
            html: '<p>We are sorry, an error has occurred. Please contact the system administrators.</p>' +
              '<br><div class="alert alert-danger" role="alert"><p> Error: </p><br>' + error.error.message + '<br><br>'
              + error.message + '</div>',
            icon: 'error', confirmButtonColor: '#dc6900'
          })
          this.loader = false;
          this.newCandidate.photo = File
        });
  }

  onFileChanged(event: any) {
    if (event.target.files.length > 0) {
      this.newCandidate.uploadComplete = false;
    }
    else {
      return;
    }

    this.loader = true;
    this.newCandidate.businesscase = event.target.files[0];
    this.newCandidate.fName = this.newCandidate.businesscase.name;
    const uploadData = new FormData();
    uploadData.append('myFile', this.newCandidate.businesscase, this.newCandidate.businesscase.name);
    uploadData.append('fileType', 'BUSINESSCASE')
    this.loader = true;

    this.attachmentService.sendAttachment(uploadData)
      .subscribe(
        data => {
          this.newCandidate.businesscase = data.toString();
          this.newCandidate.uploadComplete = true;
          this.loader = false;
        },
        error => {
          this.loader = false;
          swal.fire({
            title: 'Oops...',
            html: '<p>We are sorry, an error has occurred. Please contact the system administrators.</p>' +
              '<br><div class="alert alert-danger" role="alert"><p> Error: </p><br>' + error.error.message + '<br><br>'
              + error.message + '</div>',
            icon: 'error', confirmButtonColor: '#dc6900'
          })
          this.newCandidate.businesscase = '';
          this.newCandidate.fName = '';
        });
  }

  removeCandidate(i: number) {
    this.partnerForm.candidates.splice(i, 1)
  }
  //#endregion Candidates


  //#region Clear
  clearMsgErrDate(){
    this.startRequired = false;
    this.endRequired = false;
    this.startEarlierEnd = false;
    this.oneHour = false;
    this.msgErr = '';
  }
  //#endregion Clear


  //#region Groups
  onGroupItemDeSelect(item: any) {
    this.countSelectedGroups--;    
  }

  onGroupItemSelect(item: any) {
    this.countSelectedGroups++;
    this.groupError = false;
  }

  onGroupDeSelectAll(items: any) {
    this.countSelectedGroups = items.length;
  }  

  onGroupSelectAll(items: any) {
    this.countSelectedGroups = items.length;
    this.groupError = false;
  }

  selectGroup() {

    this.dataService.getGroups().subscribe(data => {
      this.pollDataGroups = data;
    });

    this.groupSettings = {
      singleSelection: false,
      itemsShowLimit: 5,
      enableSearchFilter: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      classes: "myclass custom-class-example",
      idField: 'id',
      textField: 'groupname',
      labelKey: 'groupname',
    };
  }
  //#endregion Groups


  //#region OnInit
  ngOnInit() {
    this.buildForms();
    this.multiselectInit();
    this.user = this.auth.getUserInfo();
    this.type = this.route.snapshot.paramMap.get('type');

    if (this.type.toUpperCase() == PollType[this.partner]) {
      this.title = 'Partner';
      this.polltype = 2;
      this.partnerForm.name = 'New Partner poll - ' +
        this.todayDate.toLocaleString('en-us', { month: 'long' }) + ' ' + this.todayDate.getFullYear();
    } else {
      this.title = 'Associate Partner';
      this.polltype = 3;
      this.partnerForm.name = 'New Associate Partner poll - ' +
        this.todayDate.toLocaleString('en-us', { month: 'long' }) + ' ' + this.todayDate.getFullYear();
    }

    this.selectGroup();
  }
  //#endregion OnInit


  //#region Validations
  buildForms() {
    this.tobForm = this.formBuilder.group({
      selectedGroups: ['', Validators.required]
    });
  }

  filterError(value: any) {
    return (value == true)
  }

  validateDates() {
    this.msgErr = '';
    let model = {
      beginDate: this.partnerForm.begin_date, endDate: this.partnerForm.end_date
      , beginTime: this.partnerForm.begin_time, endTime: this.partnerForm.end_time, codError: 0, msgError: ""
    };

    if (!this.dateUtil.validateDateTime(model)) {
      this.startRequired = model.codError == CodError.StartRequired ? true : false;
      this.endRequired = model.codError == CodError.EndRequired ? true : false;
      this.startEarlierEnd = model.codError == CodError.StartEarlierEnd ? true : false;
      this.oneHour = model.codError == CodError.OneHour ? true : false;

      if (this.startRequired) {
        this.msgErr = MsgError.StartRequired;
      }
      else if (this.endRequired) {
        this.msgErr = MsgError.EndRequired;
      }
      else if (this.startEarlierEnd) {
        this.msgErr = MsgError.StartEarlierEnd;
      }
      else if (this.oneHour) {
        this.msgErr = MsgError.OneHour;
      }

      if (this.msgErr != '') {
        this.saving = false;
        return false;
      }      
    }
    else{
      this.clearMsgErrDate();
      return true;
    }
  }

  validateEmptyFields(obj: any) {
    for (let value of Object.entries(obj)) {
      if (value[0] != undefined) {
        //value 1 valor email
        this.errors[value[0]] = (value[1].toString() == "" || value[1] == null)
      }
    }
  }

  validateFields(){
    if (Object.values(this.errors).length != 0) {
      if (Object.values(this.errors).filter(this.filterError).length > 0) {
        this.saving = false;
        return false;
      }
    }
    
    return true;
  }
  //#endregion Validations


  //#region Voters
  loadVoters() {
    this.selectedGroups = _.map(this.selectedGroupItems, 'id');

    if (this.countSelectedGroups == 0) {
      this.groupError = true;
      return
    }

    this.loadingVoter = true;
    this.dataService.getPartnersGroup(this.selectedGroupItems).subscribe(data => {
      this.loadingVoter = false;
      this.voters = data;
      this.voterError = false;
      this.errors.selectedVoters = false;
      this.countVoters = Object.keys(this.voters).length;
      $('#tabelaVoters').DataTable().clear().draw();

      $.each(data,
        function (key: any, item: any) {
          $("#tabelaVoters").DataTable().row.add([
            item.name
          ]).draw();
        });
      this.countShowVoterTable++;
    });
  }
  //#endregion Voters
}