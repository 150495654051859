export const environment = {
      apiEndpoint: 'https://back.voting-stg.apps.pwc.com.br/api/',

    envho: 'LOCAL|1.00',
    envhocolor: 'dev-color',
    production: true,
    sign_out_url: 'https://login-stg.pwc.com/openam/UI/Logout',
//    sign_out_url: 'https://fedsvc-stage.pwc.com/ofiss/public/SignOut.aspx?wa=wsignout1.0&wreply=https%3a%2f%2fstg.voting.pwcinternal.com',
//    sign_out_url: 'https://fedsvc-stage.pwc.com/ofiss/public/SignOut.aspx',


    idam: {
      authorization_endpoint: 'https://login-stg.pwc.com/openam/oauth2/authorize',
      // old authorization_endpoint: 'https://fedsvc-stage.pwc.com/ofiss/',
      //old app_url: 'https://stg.voting.pwcinternal.com/',
      //old client_app_id: 'https://stg.voting.pwcinternal.com/'
      app_url: 'http://voting-stg.apps.pwc.com.br/',
      client_app_id: 'http://voting-stg.apps.pwc.com.br/'
    }
  };
