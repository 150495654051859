import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-result-dashboard',
  templateUrl: './result-dashboard.component.html',
  styleUrls: ['./result-dashboard.component.scss']
})
export class ResultDashboardComponent implements OnInit {

  //#region Variables
  polls: any = [{
    'name': '',
    'end_date': '',
    'begin_date': '',
    'saveType': '',
  }];

  accountedPolls: any = [{
    'name': '',
    'begin_date': '',
    'end_date': '',    
    'saveType': '',
  }];

  countPolls = 0;
  //#endregion Variables

  constructor(private data: DataService) { }

  //#region OnInit
  ngOnInit() {
    this.data.getCompletedPolls().subscribe(data => {
      this.polls = data;
      this.countPolls = Object.keys(this.polls).length;      

      this.accountedPolls = this.polls.filter(function(e: { saveType: string; }) {
        return e.saveType == '6';
      });

      this.polls = this.polls.filter(function(e: { saveType: string; }) {
        return e.saveType == '5';
      });
    },
    error => {
      console.log(error);
    });
  }
  //#endregion OnInit
}
