import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userProfile'
})
export class UserProfilePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value === 0) {
        return 'Sponsor';
      } else if (value === 1) {
        return 'Admin';
      } else if (value === 2) {
        return 'Partner';
      } else if (value === 3) {
        return 'Lawyer';
      } else {
          return 'Super User';
      }
  }

}
