<link rel="stylesheet" href="https://pwcappkit-static.azureedge.net/cdn/release/3.5.0/vendor.min.css">
<link rel="stylesheet" href="https://pwcappkit-static.azureedge.net/cdn/release/3.5.0/appkit.min.css">
<script src="https://pwcappkit-static.azureedge.net/cdn/release/3.5.0/vendor.min.js"></script>
<script src="https://pwcappkit-static.azureedge.net/cdn/release/3.5.0/appkit.min.js"></script>

<div class="wrapper">
    <!-- Sidebar  -->
    <nav id="sidebar" class="active">
        <div class="sidebar-header">
            <strong>
                <div class="logo">
                    <img width="50" height="40" src="assets/img/PricewaterhouseCoopers_Logo.png">
                    <p class="app-name">Voting</p>
                </div>
            </strong>
        </div>

        <ul class="list-unstyled components">
            <img
                src="https://ui-avatars.com/api/?size=40&background=eb8c00&color=ffffff&rounded=true&name={{ user.given_name }}">
            <li class="user-name"><i>{{ user.given_name }}</i></li>
            <hr class="line-menu">
            <li *ngIf="(user.role <= 1 || user.role == 4)" [routerLinkActive]="['active']">
                <a class="side-icon" routerLink="/create-poll">
                    <i class="fa fa-plus"></i>
                    New Poll
                </a>
            </li>

            <li [routerLinkActive]="['active']">
                <a class="side-icon" *ngIf="user.role == 0 || user.role == 2 || user.role == 4" routerLink="/polls">
                    <span class="badge badge-light count-poll">{{ count }}</span>
                    <i class="fa fa-th-large"></i>
                    My Polls
                </a>
            </li>

            <li [routerLinkActive]="['active']">
                <a class="side-icon" *ngIf='(user.role <= 1 || user.role == 4)' routerLink="/admin">
                    <i class="fa fa-list-alt"></i>
                    Workspace
                </a>
            </li>
            <li [routerLinkActive]="['active']">
                <a class="side-icon" *ngIf='(user.role <= 1 || user.role == 4)' routerLink="/history">
                    <i class="fa fa-history"></i>
                    History
                </a>
            </li>
            <li [routerLinkActive]="['active']">
                <a class="side-icon" *ngIf='user.role == 3 || user.role == 4' routerLink="/results">
                    <i class="fa fa-pie-chart"></i>
                    Results
                </a>
            </li>
            <li *ngIf="(user.role <= 1 || user.role == 4)" [routerLinkActive]="['active']">
                <a class="side-icon" routerLink="/settings">
                    <i class="fa fa-gear"></i>
                    Settings
                </a>
            </li>
            <li [routerLinkActive]="['active']">
                <a class="side-icon" routerLink="/about">
                    <i class="fa fa-info-circle"></i>
                    About
                </a>
            </li>
        </ul>
    </nav>

    <!-- Page Content -->
    <div id="content" class="content-page">
        <div class="btn-sign-out">
            <button type="button" id="logoff" class="btn btn-sm" (click)="logoff()">
                <i class="fas fa-power-off"></i> Sign out
            </button>
        </div>
        <nav class="navbar navbar-expand-lg navbar-light bg-white">
            <div class="container-fluid">
                <button type="button" id="sidebarCollapse" class="btn btn-danger btn-hidden-menu"
                    (click)="toggleNavbar()">
                    <i class="fas fa-bars"></i>
                </button>
            </div>
        </nav>
        <router-outlet></router-outlet>
    </div>

    <div class="wrapper">
        <div id="inactivity-popup-container"></div>
    </div>
</div>