<div>
    <h3 class="title">New Poll</h3>
    <h5 class="title">Choose the poll type</h5>
    <div class="card-columns cards-poll">
        <div class="card card-body btn-color-new-partner cursor-pointer" [routerLink]="[ '/poll/create/partner','partner']">            
            <h4 class="card-title label-btn">New Partner</h4>            
        </div>
        <div class="card card-body btn-color-new-associate-partner cursor-pointer" [routerLink]="[ '/poll/create/partner','apartner']">            
            <h4 class="card-title label-btn">New Associate Partner</h4>        
        </div>
        <div class="card card-body btn-color-board cursor-pointer" [routerLink]="[ '/create-poll/board','tob']">        
            <h4 class="card-title label-btn">Board</h4>            
        </div>
        <div class="card card-body btn-color-tsp cursor-pointer" [routerLink]="[ '/create-poll/board','tsp']">            
            <h4 class="card-title label-btn">TSP</h4>            
        </div>
        <div class="card card-body btn-color-generic cursor-pointer" [routerLink]="[ '/create-poll/generic']">            
            <h4 class="card-title label-btn">Others</h4>
            <p class="label-generic">(Yes or No)</p>            
        </div>
    </div>
</div>
