<button type="button"  class="btn btn-danger fa fa-arrow-left poll-btn-back" (click)="back()"></button>
<p class="float-right details-date">
    This poll started on: {{poll_detail.begin_date | dateTimeFormatFilter : "MMM DD, YYYY HH:mm"}} <br> 
    This poll finished on: {{poll_detail.end_date | dateTimeFormatFilter : "MMM DD, YYYY HH:mm"}}</p>
<h4>{{poll_detail.name}}<br></h4>
<h6>{{poll_detail.description}}<br></h6>
Poll Type: <span class="badge badge-dark">{{poll_detail.pollType | PollTypePipe}}</span><br>
Poll Status: <span class="badge" [ngClass]="{'badge-closed': poll_detail.saveType==5,'badge-accounted': poll_detail.saveType==6}">{{poll_detail.saveType | PollstatusPipe}}</span><br><br>

<!-- Voter Turnout -->
<div class="col-md-3 float-right card-turnout" >
    <div class="card" [ngClass]="{'card-turnout__top': poll_detail.pollType != 4}">
        <span class="header-line gradient-color-1"></span>
        <div class="card-header">
            Voter Turnout
        </div>
        <div class="card-body">
            <p class="card-text"><b>{{totalVoters}}</b> invited voters</p>
            <p class="card-text"><b>{{partialTurnout}}</b> ({{voterTurnout | number:'2.2-2'}}%) voter turnout</p>
            <p *ngIf="poll_detail.pollType <= 1"class="card-text"><b>Vacancies: </b> {{poll_detail.vacancy}}</p>
        </div>
    </div>
</div>

<!-- Voter Result -->
<div class="card results-card" *ngIf="poll_detail.pollType == 4"><span class="header-line gradient-color-1"></span>
    <div class="card-header">
            Results
    </div>
    <div class="card-body" *ngFor="let candidate of poll_detail.candidates">
        <p class="card-text"><b>Yes:</b> {{candidate.vote_yes}} ({{(candidate.vote_yes/totalVoters)*100 | number:'2.2-2'}}%)</p>
        <p class="card-text"><b>No:</b> {{candidate.vote_no}} ({{(candidate.vote_no/totalVoters)*100 | number:'2.2-2'}}%)</p>
    </div>
</div>

<ngb-accordion activeIds='static-1, static-2'>
    
    <!-- Candidates -->
    <ngb-panel id="static-1" *ngIf="poll_detail.pollType != 4">
        <ng-template ngbPanelTitle >
            <i class="fas fa-angle-down"></i><span> List of results </span>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="row">
                <div class="col" [ngClass]="{'tables-result': poll_detail.pollType != 4}">
                    <div  class="table-responsive">
                        <table class="table  table-bordered table-hover " >
                            <thead>
                                <tr>
                                    <th class="content-table">Candidate Name</th>
                                    <th *ngIf="poll_detail.pollType >= 2" class="content-table">LOS</th>
                                    <th class="content-table">Positive Votes</th>
                                    <th *ngIf="poll_detail.pollType >= 2" class="content-table">Negative Votes</th>
                                </tr>
                            </thead>
                            <tbody class="poll-content-list">
                                <tr *ngFor="let candidate of poll_detail.candidates">
                                    <td>{{candidate.name}}</td>
                                    <td *ngIf="poll_detail.pollType >= 2" class="voted">{{candidate.candidateLos}}</td>
                                    <td class="voted">{{candidate.vote_yes}} ({{(candidate.vote_yes / totalVoters)*100 | number:'2.2-2'}}%)</td>
                                    <td *ngIf="poll_detail.pollType >= 2" class="not-voted">{{candidate.vote_no}} ({{(candidate.vote_no / totalVoters)*100 | number:'2.2-2'}}%)</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </ng-template>
    </ngb-panel>

    <!-- Voters -->
    <ngb-panel id="static-2">
        <ng-template ngbPanelTitle>
            <i class="fas fa-angle-down"></i><span> Voters List </span>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="row">
                <div class="col">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th class="content-table">Voter Name</th>
                                    <th class="content-table-voted">Status</th>
                                </tr>
                            </thead>
                            <tbody class="poll-content-list">
                                <tr *ngFor="let voter of poll_detail.voters ">
                                    <td>{{voter.name}}</td>
                                    <td><span class="badge" [ngClass]="{'badge-danger': poll_detail.saveType >= 5 && voter.voted == false,
                                                    'badge-warning': voter.voted == false, 'badge-success': voter.voted == true}">
                                                    {{voter.voted ? 'VOTED' : poll_detail.saveType >= 5 ? 'NOT VOTED' : 'PENDING'}}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>

<br><br>

<div *ngIf="poll_detail.saveType == 6" class="alert alert-secondary">
    <strong>Approver message: </strong>{{poll_detail.lawyer_message}}
</div>
<div *ngIf="poll_detail.saveType == 5">
    <form (ngSubmit)='auditPoll(f)' #f="ngForm">
        <div class="form-group" class="field-message" >
            <textarea  placeholder="Please write down a comment regarding this poll" autofocus="" name="lawyer_message"  class="form-control" ngModel [ngClass]="{ 'is-invalid': f.invalid && f.submitted  }"></textarea>
            <div *ngIf="f.invalid && f.submitted" class="invalid-feedback">
                <div>Message is required</div>
            </div>
        </div>
        <button  class="btn btn-block btn-success" type="submit">Approve Poll&nbsp;<i class="fa fa-check"></i></button>
    </form>
</div>

<!-- Loader/ Alert-->
<div *ngIf="loader" class='overlay'>
    <div class="spinner">
        <div class="spinnerInner">
            <img src="..\assets\img\loader_old.gif">
        </div>
    </div>
</div>
<div *ngIf="error" class='overlay'>
    <div class="alert alert-danger alert-dismissible fade show"><span><i class="fas fa-check"></i></span>
        We are sorry, an error has occurred. Please contact the system administrators.
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>
<div *ngIf="success" class='overlay'>
    <div class="alert alert-success"><span><i class="fas fa-check"></i></span>
        The poll has been completed</div>
</div>
